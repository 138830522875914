import React, { useState, useEffect, useRef } from 'react';
import { Table, Dropdown, Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomButton } from "../form";
import { useDispatch, useSelector } from 'react-redux';
import { getStages } from '../../store/slices/moduleStageSlice';
import { create, getByName } from "../../store/actions/userPrefrenceActions";
import Swal from "sweetalert2";

const Stages = ({ reloadList, module, userPrefrence }) => {
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const st = useSelector((state) => state.userPrefrence.userPrefrenceDetail.preference_value);
    const [stageIds, setStageIds] = useState(st?.filterStageIds ?? []);

    const stagesList = useSelector((state) => state.moduleStage.moduleStages.data);

    useEffect(() => {
        dispatch(getByName(userPrefrence));
        dispatch(getStages(module));

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch, userPrefrence, module]);

    useEffect(() => {
        setStageIds(st?.filterStageIds ?? []);
    }, [st]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setStageIds(stagesList.map(stage => stage.stageId));
        } else {
            setStageIds([]);
        }
    };

    const handleCheckboxChange = (id) => {
        setStageIds(prevSelectedItems => {
            if (prevSelectedItems.includes(id)) {
                return prevSelectedItems.filter(itemId => itemId !== id);
            } else {
                return [...prevSelectedItems, id];
            }
        });
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000
        });
    };

    const saveStagePreference = () => {
        if (stageIds.length === 0) {
            sweetAlert("warning", "Warning!", "Please select at least one stage.");
            return;
        }

        var data = {
            preference_value: {
                filterStageIds: stageIds
            },
            preference_name: userPrefrence,
            module_code: module,
        };

        dispatch(create(data))
            .then((res) => {
                setDropdownOpen(false);
                reloadList(true);
                sweetAlert("success", "Success!", `${res.payload.message}!`);
            })
            .catch((error) => {
                reloadList(true);
                sweetAlert("error", "Error!", "Something went wrong!");
            });

        reloadList(false);
    };

    const restoreDefault = () => {
        let ids = stagesList.map(stage => stage.stageId)
        setStageIds(ids);

        var data = {
            preference_value: {
                filterStageIds: ids
            },
            preference_name: userPrefrence,
            module_code: module,
        };

        dispatch(create(data))
            .then((res) => {
                if (res.payload.message) {
                    setDropdownOpen(false);
                    reloadList(true);
                    sweetAlert("success", "Success!", `${res.payload.message}!`);
                }
            })
            .catch((error) => {
                reloadList(true);
                sweetAlert("error", "Error!", "Something went wrong!");
            });

        reloadList(false);
    };

    return (
        <Dropdown show={dropdownOpen} ref={dropdownRef}>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="tooltip-quick-action">
                        Status Filter
                    </Tooltip>
                }
            >
                <Dropdown.Toggle className="header-btn mr-10" onClick={() => setDropdownOpen(!dropdownOpen)}>
                    <i className="fa fa-filter fa-fw"></i>
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='stages-menu'>
                <Form.Check
                    type="checkbox"
                    label="Select All"
                    onChange={handleSelectAllChange}
                    checked={stagesList?.length > 0 && stageIds.length === stagesList.length}
                />
                {stagesList &&
                    stagesList.map((stage, index) => (
                        <Form.Check
                            key={index}
                            type="checkbox"
                            label={stage.stageName}
                            checked={stageIds.includes(stage.stageId)}
                            onChange={() => handleCheckboxChange(stage.stageId)}
                        />
                    ))}
                <Dropdown.Divider />
                <div className="d-flex">
                    <CustomButton text="Save" className="custom-btn-primary" onClick={saveStagePreference} />
                    <CustomButton style={{ whiteSpace: "nowrap" }} text="Restore Default" className="mr-10 ml-10 custom-btn-outline" onClick={restoreDefault} />
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default Stages;
