
import {Row,Col ,Offcanvas,Container, Form,Nav} from 'react-bootstrap';
import { downloadarrowblow,crossicon, addlinkIcon } from '../../helper/Icons/Icon';
import { Label, SelectField,CustomBtnIcon,CustomButton, InputField } from '../form';




const Addlink=({handleClose})=>{
   

    return(<>
       <Offcanvas show={true} onHide={handleClose}  placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "50%" }}>
   
              <Offcanvas.Header className="model-header bg-white actionlist-header ">
            <Offcanvas.Title><span className="svg  svgblue ">{addlinkIcon}</span>  Add Link</Offcanvas.Title> 
           <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div> 
          </Offcanvas.Header>
          <Container fluid className=" pad-10 ">
          <Row className="row-pad-0 bg-white border-radius-5 pad-10  ">
          <Col md={12}  className='mb-10'>
            <Label text="Choose Template" className="label-input pad-0"/>
            <InputField type="text" placeholder="Add display text" />
            </Col>
            <Col md={4}  className='mb-10'>
                <Label text="Protocol"/>
                  <SelectField options={[
                {vale:1,label:"https://"},
                {vale:2,label:"http://"},
              
            ]}/>
            </Col>
            <Col md={8}  className='mb-10'>
                <Label text="URL" />
                <InputField type="text" placeholder="Add display text"/>
            </Col>
       
            <Col md={12} className="template-wrap-nav mt-20  addlinkopen-wrap ">
            <Nav>
      <Nav.Link  activeClassName="active">
        Same Tab
      </Nav.Link>
      <Nav.Link   activeClassName="">
       New Tab
      </Nav.Link>
      <Nav.Link activeClassName="">
       New Window
      </Nav.Link>
    </Nav>
    
          </Col>
     
            
            <Col md={12} className='text-end mt-10 pad-0'>
            <CustomButton className="custom-btn-outline mr-10" text="Cancel" onClick={handleClose}  />
            <CustomButton className="custom-btn-primary " text="Save"/>
            </Col>
          </Row>
    
   
          </Container>
          </Offcanvas>
  
    
    </>)
}





export default Addlink;