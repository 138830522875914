import Candidates from './Candidates.jsx'

import {
  Col,

  Row,

  Tab,
  Nav,
  Button,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { CustomButton } from "../form/index";
import users from "../../assets/images/users.png";
import { useDispatch, useSelector } from "react-redux";
import EditCandidate from "../candidate/EditCandidate";
import { getAll, deleteCandidateInvitation } from "../../store/actions/candidateInvitationActions";
import { getByName } from "../../store/actions/userPrefrenceActions";
import Paginate from "../common/Paginate";
import { CandidatesICon } from "../../helper/Icons/Icon";


const defaultCurrentPage = 1
const navlink = [
  { key: 0, text: "All" },
  { key: 2, text: "Invited " },
  { key: 8, text: "Interview Sent " },
  { key: 4, text: "Link Expired " },
  { key: 7, text: "Interview Underway" },
  { key: 9, text: "Interview Passed " },
  { key: 5, text: "Interview Failed " },
  { key: 3, text: "Live Interview Scheduled" },
  { key: 10, text: "Live Interview Conducted" },
  { key: 15, text: "Hired" },
];

const NavTabel = ({ jobId, update }) => {
  const [deletej, setDeletej] = useState(false);

  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(10);
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(0);
  const [searchField, setSearchField] = useState({})
  const prefrenceName = "users";
  const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);





  const handleTabSelect = (key) => {
    setActiveKey(key);
    setSearchField({});
  };

  const { candidateInvitation, loading, error } = useSelector((state) => state.candidateInvitations);
  let id = activeKey == 0 ? "" : activeKey;
  const invitations = candidateInvitation?.data;
  const countStages = candidateInvitation?.count_stages;
  let pages = candidateInvitation?.pages;
  let totalPages = candidateInvitation?.total_page;
  let totalRecords = candidateInvitation?.total_record;

  useEffect(() => {
    dispatch(getByName(prefrenceName));
  }, [dispatch, update])

  useEffect(() => {
    if (userPrefrenceDetail?.preference_value?.record_per_page) {
      setPerPage(userPrefrenceDetail.preference_value.record_per_page);
    }
  }, [userPrefrenceDetail]);

  useEffect(() => {
    if (perPage) {
      let data = {
        limit: perPage,
        page: currentPage,
        job_id: jobId,
        stage_id: id,
        search: searchField

      };
      dispatch(getAll(data));
    }

  }, [dispatch, activeKey, currentPage, perPage, searchField, deletej, jobId, update]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  }; const handlePerPage = (perPage) => {
    setPerPage(perPage);
  };
  const handleFieldSearch = (e) => {
    const { name, value } = e.target;
    searchField[name] = value;
    setSearchField({ ...searchField })

  };

  return (
    <>
      <Row className="row-pad-0 mt-10">
        <Col md={1} className="custom-md-1 mt-1 mt-10">
          <div className="profile">
            <span>{CandidatesICon}</span>
          </div>
        </Col>
        <Col md={11} className="pad-0 mt-1 min-sub-heading  custom-md-11 mt-10">
          <label>Candidates</label>
        </Col>
      </Row>

      <Row className="row-pad-0 mt-1 detail-tab-wrap">
        <Tab.Container
          id="detailTabWrap"
          className=""
          activeKey={activeKey}
          onSelect={handleTabSelect}
        >
          <div className="box-wrap">
            <Nav variant="pills" className=" d-flex  custom-nav ">
              {navlink.map((link) => {
                const stage = countStages?.stages.find((stage) => stage.stage_id === link.key);
                const count = link.key === 0
                  ? countStages?.all_count
                  : stage?.count || 0;

                return (
                  <Nav.Item key={link.key}>
                    <Nav.Link eventKey={link.key}>
                      {link.text} ({count})
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>

          <Tab.Content className="detail-Tab-Pane ">
            <Tab.Pane eventKey={activeKey}>
              <div className=" mt-20 x-scroll">
                <Candidates deletej={deletej} setDeletej={setDeletej} searchField={searchField} invitationsLoading={loading} invitations={invitations} onChange={handleFieldSearch} />
                <Paginate
                  pages={pages}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  perPage={perPage}
                  totalRecords={totalRecords}
                  handlePageChange={handlePageChange}
                  handlePerPage={handlePerPage}
                />
              </div>
            </Tab.Pane>

          </Tab.Content>
        </Tab.Container>
      </Row>
    </>
  );
};

export default NavTabel;
