// BasicInfo component

import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Spinner } from "react-bootstrap";
import { edit } from '../../helper/Icons/Icon';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { profileActivityLog } from '../../store/actions/activityLogsActions';
import * as moment from 'moment'
const TimeLine = ({ id }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);

  const dispatch = useDispatch();
  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };

  const { profileLogs, loading, error } = useSelector(
    (state) => state.activityLogs
  );
  useEffect(() => {
    dispatch(profileActivityLog(id));
  }, [dispatch]);



  return (
    <>



      <Accordion className='pad-0 bg-white'>
        <Row md={12} className='pad-5 mt-1 m-1 bg-white row-pad-0'>
          <Col md={6}><h5 className='min-sub-heading'><label>Timeline </label></h5></Col>
          <Col className="d-flex justify-content-end align-items-center" md={6}>
            <a href="#" className='ml-10 ' onClick={toggleAccordion}>
              <i className={`fa ${isAccordionOpen ? 'fa-angle-down' : 'fa-angle-up'} clr-light-black`} style={{ fontSize: "24px" }}></i>
            </a>
          </Col>
        </Row>
        <span className='single-border d-block'></span>

        <Accordion.Collapse in={isAccordionOpen} style={{ height: 'calc(840px - 200px)', overflowY: 'auto' }}>
          <Row className='row-pad-0'>
            {loading && <Spinner />}
            <Col md={12} className="activity-feedHeader" style={{ height: '500px', overflowY: 'auto' }}>
              {profileLogs && Array.isArray(profileLogs) && profileLogs.length > 0 ? (

                profileLogs.map((label, index) => (
                  <div className="activity-feed" key={index}>
                    <div className="feed-item mb-10">
                      <div className="date">
                        <i>{edit}</i> | {label.created_by_name}<span className="pull-right">{moment(label.created_date).format('DD-MM-YYYY h:m A')}</span>
                      </div>
                      <div className="text mt-1" dangerouslySetInnerHTML={{ __html: label.activity }} />
                      <span className='single-border d-block mt-1'></span>
                    </div>
                  </div>
                ))
              ) : (

                <p>No activity logs found.</p>
              )}
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
}

export default TimeLine;
