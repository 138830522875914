import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { Video, sky, timer, InterviewMediaicon } from "../../helper/Icons/Icon";
import interview from "../../assets/images/interview.jpg";
import { updateAnswer, updateTimeline } from "../../store/actions/VideoRecordingAction";
import { CustomButton } from "../form";
import Swal from 'sweetalert2';

const RecordVideo = ({ question, answer, activeStep }) => {

    const dispatch = useDispatch();
    const videotag = useRef(null);
    const mediaRecorder = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState(null);

    const [retakeVideo, setRetakeVideo] = useState(false);
    const [retakeCount, setRetakeCount] = useState(answer?.retakes ?? 0);
    const [videoChunks, setVideoChunks] = useState([]);
    const [startStreem, setStartStreem] = useState(null);
    const [thinkTime, setThinkTime] = useState(0);
    const [responseTime, setResponseTime] = useState(answer?.response_time_insec ?? 0);
    const th = useRef();
    const rs = useRef();

    

    useEffect(() => {
        if (!isRecording && !answer?.interview_answer) {
            th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000)
        }

        if (isRecording) {
            rs.current = setInterval(() => setResponseTime(prevSeconds => prevSeconds + 1), 1000)
        }

    }, [isRecording]);


    const startRecording = async (retacke) => {
        if (!answer?.interview_answer) {
            clearInterval(th.current);
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            videotag.current.srcObject = stream;
            mediaRecorder.current = new MediaRecorder(stream);

            setStartStreem(stream)
            setIsRecording(true);
            if (retacke) {
                setRetakeCount(retakeCount + 1)
            }

            mediaRecorder.current.start();
            const chunks = [];

            mediaRecorder.current.ondataavailable = (event) => {
                chunks.push(event.data);
            };

            setVideoChunks(chunks);

        } catch (error) {
            let errorMessage = "Error: Could not start video source";
            if (error.name === "NotReadableError") {
                errorMessage += ". This could be due to permission issues, browser support, or device availability.";

            } else if (error.name === "NotAllowedError") {
                errorMessage += ". Please grant permission to access the camera and microphone.";
            }

            Swal.fire({
                icon: "error",
                title: "Error!",
                text: errorMessage,
                showConfirmButton: false,
                timer: 2000
            });

        }
    };

    const stopRecording = (retacke) => {
        clearInterval(rs.current);

        if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
            mediaRecorder.current.stop();
        }

        mediaRecorder.current.onstop = () => {
            const blob = new Blob(videoChunks, { type: 'video/webm' });
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;

                setRecordedBlob(blob);

                const videoUrl = window.URL.createObjectURL(blob);
                const payolad = {
                    interview_template_question_id: question.interview_template_question_id,
                    retakes: retakeCount,
                    response_time: Math.ceil(responseTime / 60),
                    response_time_insec: responseTime,
                    think_time: Math.ceil(thinkTime / 60),
                    interview_answer: videoUrl,
                    interview_answer_encoded: base64data,
                    question_text: question.question_text,
                    answer_media: question.question_type_name,
                    
                };

                dispatch(updateAnswer(activeStep, payolad));

                let qNumber = activeStep + 1;
                const log = {
                    key: `question_${qNumber}`,
                    log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
                    date_time: new Date()

                };
                dispatch(updateTimeline(qNumber + 1, log));

                videotag.current.srcObject = null;
                startStreem.getTracks().forEach(track => track.stop());

                setIsRecording(false);
                setRetakeVideo(false);
                setVideoChunks([]);
                setStartStreem(null)
            }
        };
    };

    const handleDownload = () => {
        if (recordedBlob) {
            const url = URL.createObjectURL(recordedBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'recorded-video.webm';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };


    return (

        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray mt-15">
            <Col md={12} className="RecordVideo-wrap">
                <h4 className="sub-heading">
                    <span>{Video}</span>Record Video
                </h4>

                <div className="video-section mt-20">

                    {(!answer?.interview_answer || retakeVideo) &&
                        <video width="100%" height="100%" autoPlay poster={interview} ref={videotag} controls />
                    }
                    {(!retakeVideo && answer?.interview_answer) && (
                        <video width="100%" height="100%" controls src={answer?.interview_answer} />
                    )}

                    <div className="">

                        <div className="">
                            {(!answer?.interview_answer || retakeVideo) && (
                                <>
                                    {retakeVideo ?
                                        <>
                                            <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-20 mr-10 " onClick={() => startRecording(true)} />
                                            <CustomButton text="save and continue" disabled={!isRecording} className="custom-btn-outline mr-10 mt-20 mr-10" onClick={() => stopRecording(true)} />
                                            <CustomButton disabled={isRecording} className="custom-btn-outline mr-10 mt-20" onClick={() => setRetakeVideo(false)} text="Cancel Retake" />
                                        </>
                                        :
                                        <>
                                            <CustomButton text="Record Now" disabled={isRecording} className=" custom-btn-primary mt-20 mr-10" onClick={() => startRecording(false)} />
                                            <CustomButton text="save and continue" disabled={!isRecording} className="custom-btn-outline mr-10  mt-20" onClick={() => stopRecording(false)} />
                                        </>
                                    }

                                    <span className="mt-1" style={{ display: isRecording ? 'block' : 'none' }}>Responding time: {responseTime}</span>

                                    {(!isRecording && !answer?.interview_answer) &&

                                        <div className="mt-1"><span>Thinking time: {thinkTime}</span></div>
                                    }
                                </>
                            )}
                            {(!retakeVideo && answer?.interview_answer) && (
                                <>
                                    <CustomButton className="custom-btn-outline mr-10 mt-20" onClick={handleDownload} text="Download Video" />
                                    {question.retakes !== answer?.retakes &&
                                        <CustomButton className="custom-btn-outline mt-20" onClick={() => setRetakeVideo(true)} text="Retake" />
                                    }

                                </>
                            )}
                        </div>

                    </div>
                </div>

            </Col>
        </Row>


    );
};

export default RecordVideo;
