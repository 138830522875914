import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Tabelcolumn = (props) => {
 
    const renderTooltip = (tooltipText) => (
        <Tooltip id="button-tooltip">{tooltipText}</Tooltip>
    );
    if(props.span){
        return (
            <OverlayTrigger
                placement="top"
                overlay={renderTooltip(props.Tooltip)}
            >
                <span {...props}>
                    { props.icon}
                </span>
                </OverlayTrigger>
        );
    }
    else{
        return (
            <OverlayTrigger
            placement="top"
            overlay={renderTooltip(props.Tooltip)}
        >
                <td {...props}>
                    {props.icon}
                </td>
                </OverlayTrigger>
        
        );
    }

};

export default Tabelcolumn;
