import React, { useState, useEffect } from 'react';
import { Container, Offcanvas, Row, Col, Form, Nav, Tab, Dropdown } from "react-bootstrap";
import { Video, isNotCorrect, correct, responsestime, Maxword, ChoiceQuestion, crossicon, newtemplate, faminusicon, deletclrred, faplusicon, timer, retake, thingtime, ringcopy, BoldIcon, italicIcon, underlinecIcon, addlinkIcon, option, textquestion, attachment, fileupploed, mic } from "../../helper/Icons/Icon";
import CriclrDiv from "../common/CriclrDiv";
import { useDispatch, useSelector } from 'react-redux';
import { Label, InputField, Checkbox, CustomButton } from "../form/index";
import { getAll } from '../../store/slices/questionTypeSlice';
import QuestionsLibrary from "./QuestionsLibrary";
import UploadMedia from "./UploadMedia";
import CustomSelect from '../common/CustomSelect';
const icons = { 1: Video, 2: mic, 3: ChoiceQuestion, 4: textquestion, 5: fileupploed }

let defaultQuestion = { question_type_id: 1, title: null, is_optional: false, media: null, retake: 0, response_time: 1, think_time: 1 };

const Questions = ({ temlateQuestins, requiredValue }) => {
    const dispatch = useDispatch()
    const [selectQuestion, setSelectionQuestion] = useState();
    const [question, setQuestion] = useState(defaultQuestion);
    const [questions, setQuestions] = useState([]);
    const [options, setOptions] = useState([]);
    const [optionsobj, setOptionsobj] = useState();
    const [option, setOption] = useState('');
    const [showDropdown, setShowDropdown] = useState(Array(questions.length).fill(false));
    const [showQuestion, setShowQuestion] = useState(false);
    const [showQuestionsLibrary, setQuestionsLibrary] = useState(false);
    const [showUploadMedia, setUploadMedia] = useState(false);
    const questionTypes = useSelector(state => state.questionType.questionTypes.data);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (requiredValue) {
            setQuestions(requiredValue)
        }

    }, [requiredValue])

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === "is_optional") {
            question[name] = checked ? true : false;
        } else {
            question[name] = value;
        }

        setQuestion({ ...question })

    };

    const CustomSelecthandleChange = (selected) => {

        setSelectionQuestion(selected);
        setQuestion(prev => ({ ...prev, title: selected.value }));

        setErrors({ title: selected.value ? " " : "Required" });
    };

    const handleTypeSelect = (value) => {
        let updatedQuestion = { ...question, question_type_id: value };

        switch (value) {
            case 1:
            case 2:
                updatedQuestion.retake = 0;
                updatedQuestion.response_time = 1;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = null; // Remove if previously set
                break;
            case 3:
                updatedQuestion.retake = null;
                updatedQuestion.response_time = null;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = null;
                break;
            case 4:
                updatedQuestion.retake = null;
                updatedQuestion.response_time = null;
                updatedQuestion.think_time = null;
                updatedQuestion.max_words = 200; // Default max words for type 4
                break;
            case 5:
                updatedQuestion.retake = null;
                updatedQuestion.response_time = null;
                updatedQuestion.think_time = 1;
                updatedQuestion.max_words = null;
                break;
            default:
                break;
        }

        setQuestion(updatedQuestion);
        setSelectionQuestion(null);
        setErrors({ title: " " });
        setOptions([]);
        setOption('');
        setOptionsobj([]);
    };

    const addQuestion = (e) => {
        setQuestion(defaultQuestion);
        setShowQuestion(!showQuestion);
    };

    const SaveQuestion = () => {
        if (!question.title) {
            setErrors({ title: "Required" });
            return;
        }

        if (question.question_type_id === 3) {
            if (options.length === 0) {
                alert("Please enter an option.");
                return;
            }

            if (optionsobj && optionsobj.length > 0 && optionsobj.some(option => option.is_correct === 1)) {
                question['options'] = optionsobj;
            } else {
                alert("Please select which option is the correct answer.");
                return;
            }
        }

        setQuestions(prevQuestions => [...prevQuestions, question]);
        temlateQuestins([...questions, question]);

        setQuestion({});
        setOptions([]);
        setSelectionQuestion(null);
        setOptionsobj([]);
        setShowQuestion(false);
    };
    const handleDropdownToggle = (questionIndex) => {
        setShowDropdown(prevState => {
            const updatedDropdowns = [...prevState];
            updatedDropdowns[questionIndex] = true;
            return updatedDropdowns;
        });

    };

    const handleDropdownItemClick = (questionIndex) => {

        setQuestions(prev => {
            const updatedQuestions = prev.filter((question, index) => index !== questionIndex);
            temlateQuestins(updatedQuestions);
            return updatedQuestions;
        });

        setShowDropdown(prevState => {
            const updatedDropdowns = [...prevState];
            updatedDropdowns[questionIndex] = false;
            return updatedDropdowns;
        });

    };

    const handleAddOption = () => {
        if (option) {
            options.push(option)
            setOptions([...options]);

            setOption('')
        }
        else {

            alert("Please enter an option.");
        }

    };

    const handleOptionRadio = (index) => {

        const updatedOptionsObj = options.map((value, i) => ({
            is_correct: index === i ? 1 : 0,
            value: value
        }));

        setOptionsobj(updatedOptionsObj);
    }

    const handleRemoveFields = (index) => {
        const updatedFields = [...options];
        const updatedFieldsOptionsobj = [...optionsobj];
        updatedFields.splice(index, 1);
        updatedFieldsOptionsobj.splice(index, 1);

        setOptions(updatedFields);
        setOptionsobj(updatedFieldsOptionsobj)

    };

    const handleModel = (props) => {
        if (props === "UploadMedia") {
            setUploadMedia(!showUploadMedia);
        }

        if (props === "QuestionsLibrary") {
            setQuestionsLibrary(!showQuestionsLibrary);
            if (selectQuestion) {

                question.title = selectQuestion.value;
                setQuestion({ ...question });
            }
        }
    };
    const handleUploadMedia = (media) => {

        question['media'] = media;
        setQuestion({ ...question });
        setUploadMedia(false);
    };

    const FileMediaDisplay = ({ fileMedia }) => {
        if (!Array.isArray(fileMedia)) return null;

        if (fileMedia.length === 0) {
            return null;
        }

        return (
            <div className="save-question-box mt-1 template-question-box">
                <label>Media:</label>
                {fileMedia.map((file, index) => {
                    const fileName = file.name;
                    const extension = file.type;

                    let icon;
                    if (['mp4', 'mov', 'avi'].includes(extension)) {
                        icon = Video;
                    } else if (['mp3', 'wav', 'aac'].includes(extension)) {
                        icon = mic;
                    } else if (['jpeg', 'png', 'gif', 'jpg'].includes(extension)) {
                        icon = ringcopy;
                    }
                    else if (['docx', 'pdf', 'doc'].includes(extension)) {
                        icon = attachment;
                    }
                    else {
                        icon = fileupploed;
                    }

                    return (
                        <div key={index} className="file-item">
                            {icon && <span className="file-icon">{icon}</span>}
                            <span className="file-name">{fileName}</span>
                        </div>
                    );
                })}
            </div>
        );
    };
    console.log(question);

    return (

        <Row className="row-pad-0 mt-20 addQuestion-wrap ">
            <Col md={12} className=" ">
                <h5 className="heading-5 mt-10 ">Questions</h5>

                {questions &&
                    questions.map((q, index) => {
                        return (

                            < Col md={12} className="addQuestion-wrap mt-1 mb-2 pad-10">
                                <Row className=" row-pad-0" key={index}>
                                    <div className="save-question-wrap ">
                                        <div className="d-flex">
                                            <CriclrDiv
                                                icon={index + 1}
                                                clrName="save-question-circle"
                                            />

                                            <div className="d-flex flex-column flex-wrap ms-3 mt-1">
                                                <h6><span className='mr-1 svg-blue' style={{ marginRight: "5px" }}>{icons[q.question_type_id]}</span>
                                                    {q.title} {q.is_optional ? " (is optional)" : ""}
                                                </h6>
                                                <div>
                                                    <div className="d-flex  flex-wrap">

                                                        {(q.question_type_id === 3) ?
                                                            <>
                                                                <div className="save-question-box mb-10" >
                                                                    <label>Details: </label>
                                                                    {q.options.map((value, index) => (<>

                                                                        <span key={index}>
                                                                            {
                                                                                value.is_correct === 1 ? <>

                                                                                    {correct} {value.value}
                                                                                </> : <>

                                                                                    {isNotCorrect} {value.value}
                                                                                </>
                                                                            }</span>
                                                                    </>





                                                                    ))


                                                                    }
                                                                </div>

                                                            </> :
                                                            <>

                                                                <div className="save-question-box mb-10">

                                                                    <label>Details: </label>
                                                                    {q.retake > 0 && <span>{timer} {q.retake} Retake(s)</span>}
                                                                    {q.response_time > 0 && <span>{responsestime} {q.response_time} Min(s) Response Time</span>}
                                                                    {q.think_time > 0 && <span>{thingtime} {q.think_time} Min(s) Think Time</span>}
                                                                    {q.max_words > 0 && <span>{Maxword} Max words {q.max_words}</span>}
                                                                </div>




                                                            </>}


                                                        {q.media && <FileMediaDisplay fileMedia={q.media} />
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <Dropdown
                                                show={showDropdown[index]}
                                                onToggle={() => handleDropdownToggle(index)}
                                            >
                                                <Dropdown.Toggle>
                                                    <i class="fa fa-ellipsis-v"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {/* <Dropdown.Item
                                                        eventKey="1"
                                                        onClick={() => handleDropdownItemClick(index)}
                                                    >
                                                        Edit
                                                    </Dropdown.Item> */}

                                                    <Dropdown.Item
                                                        eventKey="3"
                                                        onClick={() => handleDropdownItemClick(index)}
                                                    >
                                                        Delete
                                                    </Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                        )
                    })
                }

                {!showQuestion && (
                    <Row className="row-pad-0  ">
                        <Col md={12} className="pad-0 mt-10 mb-10">
                            <CustomButton
                                text="+ Add Question"
                                className="custom-btn-primary"
                                onClick={addQuestion}
                            />
                        </Col>
                    </Row>
                )}{" "}
            </Col>
            {
                showQuestion && (
                    <>

                        <Row className="row-pad-0  mt-20 mb-10">

                            <Row className="row-pad-0 ">
                                <Col md={8} className="d-flex pad-0">
                                    <Nav variant="pills" className=" ">
                                        {questionTypes &&
                                            questionTypes.map(questionType => {
                                                let typeId = questionType.questionTypeId;
                                                return (
                                                    <Nav.Item className="mb-10">
                                                        <Nav.Link
                                                            className={`question-box ${question.question_type_id === typeId ? "active" : ""}`}
                                                            onClick={() => handleTypeSelect(typeId)}
                                                        >
                                                            <div>
                                                                <CriclrDiv icon={icons[typeId]} />
                                                            </div>
                                                            <div className="crl-qestion-tab">
                                                                {questionType.questionTypeName}
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )
                                            })
                                        }

                                    </Nav>
                                </Col>

                                <Col md={4} className="text-end">
                                    <CustomButton
                                        text="+ Add Media"
                                        className="ml-10 custom-btn-outline"
                                        onClick={() => handleModel("UploadMedia")}
                                    />
                                    <CustomButton
                                        text="Browse Questions"
                                        className="ml-10 custom-btn-outline"
                                        onClick={() => handleModel("QuestionsLibrary")}
                                    />
                                </Col>
                                <Col md={12} className="pad-0">

                                    <Col md={12} className="mt-20 mb-10 custom-select-wrap">
                                        <CustomSelect
                                            selectQuestion={selectQuestion}
                                            onchange={CustomSelecthandleChange} />


                                        {errors.title && <span className="text-danger">{errors.title}</span>}

                                    </Col>
                                    <Col md={12} className="mb-10 optional-checkbox">
                                        <Checkbox label="Mark Question as Optional" name="is_optional" id="is_optional" onChange={handleChange} />
                                    </Col>

                                    <Row className="row-pad-0 pad-0">

                                        {(question.question_type_id === 1 || question.question_type_id === 2) && <Col md={3} className="pad-0  mr-10 ">
                                            <Label text="Retakes" />
                                            <Form.Select
                                                onChange={handleChange}
                                                id="retake"
                                                name="retake"
                                            >
                                                <option value='0'>0 Retake</option>
                                                <option value='1'>1 Retake</option>
                                                <option value='3'>3 Retake</option>
                                                <option value='5'>5 Retake</option>
                                                {/* <option value='5'>Unlimited Retakes</option> */}
                                            </Form.Select>
                                        </Col>
                                        }

                                        {question.question_type_id === 3 && (
                                            <Col md={3} className="pad-0 mr-10">
                                                <div className="d-flex flex-column radius-5">
                                                    <Label text="Answer Options" style={{ marginTop: "3px" }} />
                                                    <Col md={12} className="d-flex InviteCandidate-addinput mb-10 ">
                                                        <InputField
                                                            type="text"
                                                            placeholder="Enter option"
                                                            name="option"
                                                            value={option}
                                                            onChange={(e) => setOption(e.target.value)}
                                                        />
                                                        <CustomButton
                                                            style={{ marginTop: "3px" }}
                                                            icon={faplusicon}
                                                            className="custom-btn-primary btn-add-option ml-10"
                                                            onClick={handleAddOption}
                                                        />
                                                    </Col>
                                                    <div className=' mt-10'>
                                                        {options && options.map((field, index) => (
                                                            <div key={index}>
                                                                <Col md={12} className="d-flex InviteCandidate-addinput mb-10 ">
                                                                    {/* <InputField
                                                                        type="text"
                                                                        placeholder="Enter option"
                                                                        name="option"
                                                                        id={`option-${index}`}
                                                                        value={field}
                                                                        onChange={(e) => handleAddOption(e.target.value, index)}
                                                                    /> */}
                                                                    <div className="options-wrap" key={index}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label={field}
                                                                            name="radio"
                                                                            id={`option-${index}`}
                                                                            value={field}

                                                                            onChange={(e) => handleOptionRadio(index)} />
                                                                    </div>
                                                                    <CustomButton
                                                                        style={{ marginTop: "3px" }}
                                                                        icon={deletclrred}
                                                                        className="bg-white gray-btn btn-add-option ml-10"
                                                                        onClick={() => handleRemoveFields(index)}
                                                                    />
                                                                </Col>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Col>
                                        )}


                                        {question.question_type_id === 4 && <Col md={3} className="pad-0 mr-10">
                                            <Label text="Max Words" />
                                            <InputField
                                                type="text"
                                                onChange={handleChange}
                                                id="max_words"
                                                name="max_words"
                                                placeholder="Max Words"
                                            />
                                        </Col>
                                        }
                                        {/* {(question.question_type_id !== 4) &&  */}
                                        {(question.question_type_id === 1 || question.question_type_id === 2) && <Col md={3} className="pad-0 mr-10">
                                            <Label text="Answer Duration" />
                                            <Form.Select
                                                onChange={handleChange}
                                                id="response_time"
                                                name="response_time"
                                            >
                                                <option value='1'>1 Mintes</option>
                                                <option value='2'>2 Mintes</option>
                                                <option value='3'>3 Mintes</option>
                                                <option value='4'>4 Mintes</option>
                                                <option value='5'>5 Mintes</option>
                                            </Form.Select>
                                        </Col>
                                        }
                                        {/* } */}

                                        <Col md={3} className="pad-0 mr-1">

                                            <Label text="Think Time" />
                                            <Form.Select
                                                onChange={handleChange}
                                                id="think_time"
                                                name="think_time"
                                            >
                                                <option value='1'>1 Mintes</option>
                                                <option value='2'>2 Mintes</option>
                                                <option value='3'>3 Mintes</option>
                                                <option value='4'>4 Mintes</option>
                                                <option value='5'>5 Mintes</option>
                                            </Form.Select>

                                        </Col>

                                    </Row>


                                    <Col md={12} className="mt-20 text-end mb-10">
                                        <CustomButton
                                            text="Cancel"
                                            className="custom-btn-outline mr-10"
                                            onClick={addQuestion}
                                        />
                                        <CustomButton
                                            text="Save"
                                            className="custom-btn-primary"
                                            onClick={SaveQuestion}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Row>

                    </>
                )
            }
            {showQuestionsLibrary && (
                <QuestionsLibrary
                    handleClose={() => handleModel("QuestionsLibrary")}
                    setSelectionQuestion={setSelectionQuestion}
                    selectQuestion={selectQuestion}
                />
            )}
            {showUploadMedia && (
                <UploadMedia fileMedia={question.media} handleClose={() => handleModel("UploadMedia")} SavehandleModel={handleUploadMedia} />
            )}

        </Row >
    );
};

export default Questions;
