import { Offcanvas, Row,Container, Col, Button } from "react-bootstrap";
import { Checkbox, CustomButton, InputField, Label, SelectField, Textarea } from "../form";
import { crossicon } from "../../helper/Icons/Icon";
import '../../css/actionlist.css';

const CommentModel = (props) => {
    const {handleClose, headertext ,icon,handelComment,handelSubmit} = props;
  

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "40%" }}>
   <Container fluid className="row-pad-0 pad-0  pad-20">
              <Offcanvas.Header className="model-header bg-white actionlist-header ">
            <Offcanvas.Title>{icon ? <span className="svg ">{icon}</span> :" " } {headertext}</Offcanvas.Title> 
           <div className="text-end"><CustomButton onChange onClick={handleClose} className="text-end close-btn " text={crossicon} /></div> 
          </Offcanvas.Header>
          <Row className="border-radius-5 m-2  row-pad-0 bg-white ">
            <Col md={12} className=" mt-20 ">
                <Label text="Comments" />
                <Textarea
                onChange ={handelComment}
                as="textarea"
                row={30}
                name="comment"
                id="comment"
               placeholder="Enter Comments"
                />
            </Col>
                   
              <Col md={12} className=" mt-10 mb-20 mt-20 text-end">
                 
                 <CustomButton className="ml-10 mr-10 custom-btn-outline"  onClick={handleClose}  text="Cancel" />
                 <CustomButton text="Save" className="custom-btn-primary"  onClick={handelSubmit} />
 
                 
              </Col>
            </Row>
                </Container>
      
        </Offcanvas>
    );
}




export default CommentModel;