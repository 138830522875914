import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { getAll } from '../../store/slices/InterviewNatureTypesSlice';
import { Label } from '../form';

const InterviewNatureTypes = ({ Changehandle }) => {
    const dispatch = useDispatch();
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const interviewnature = useSelector(state => state.InterviewNatureTypes?.interviewNaturetypes);

    useEffect(() => {
        dispatch(getAll());
      
    }, [dispatch]);
   const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            Changehandle('interview_nature', selectedOption.value);
        } else {
            Changehandle('interview_nature', '');
        }
    };

    if (!interviewnature || !Array.isArray(interviewnature) || interviewnature.length === 0) {
        return (
            <>
                <Label text="Interview Type" htmlFor="interview_nature" />
                <Form.Select name='interview_nature' id='interview_nature' className="input-select" onChange={Changehandle}>
                    <option value="">Something went wrong, check your internet connection</option>
                </Form.Select>
            </>
        );
    }

    const options = interviewnature.map(option => ({
        value: option.interview_nature_id,
        label: option.interview_nature
    }));

    return (
        <>
            <Label text="Interview Type" htmlFor="interview_nature" />
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                value={selectedOption}
                placeholder="Select Interview Type"
            />
        </>
    );
};

export default InterviewNatureTypes;
