import { Col, Row, Tab, Nav, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import FileUploadView from "../components/common/FileUploadView";
import StarRating from 'react-rating-stars-component';
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInterviewEvaluation, EvaluationFinalRemarks } from "../store/actions/interviewEvaluatorAction";
import { thingtime, responsestime, retake, word, shareleftarrow, videobox, ringcopy, mcqQuestion, textquestion, uploadfile } from '../helper/Icons/Icon';
import Swal from 'sweetalert2';
import { Checkbox, CustomButton, Label, Textarea } from "../components/form";
import Message from "../components/common/Message";
import EvaluateComment from "./EvaluateComment";

const config = require('../../src/config/config.json')

const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'Multiple Choice': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile };

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const EvaluateCandidate = () => {
    const [answerId, setAnswerId] = useState(0);
    const [formData, setFormData] = useState({ final_remarks: '', final_rating: 0 });
    const [showMessage, setMessage] = useState(false);
    const { token } = useParams();
    const { InterviewEvaluation, loading, error } = useSelector((state) => state.evaluator);
    const dispatch = useDispatch();
    const Evaluation = InterviewEvaluation?.data;
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const checkbox = <span>✔️</span>;
    const crossbox = <span>❌</span>;
    useEffect(() => {
        dispatch(getInterviewEvaluation(token));
    }, [dispatch, token]);

    useEffect(() => {
        if (Evaluation && Evaluation?.question_answer.length > 0) {
            setSelectedAnswer(Evaluation?.question_answer[answerId]);
        }
    }, [Evaluation, answerId]);

    const handleTabSelect = (index) => {
        setAnswerId(index);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleRating = (value) => {


        setFormData(prevState => ({
            ...prevState,
            final_rating: value
        }));

    };
    const SubmitEvaluation = (e) => {
        e.preventDefault();

        const payload = {
            final_remarks: formData.final_remarks,
            final_rating: formData.final_rating
        };

        if (token) {
            dispatch(EvaluationFinalRemarks({ token, payload }))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            res.payload.error
                        );
                    } else {
                        sweetAlert(
                            "success",
                            "Success!",
                            res.message
                        );
                        setMessage(true)
                    }
                })
                .catch(() => {
                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    };

    return (
        <>
            <Col className="pad-0">
                <Row className="row-pad-0 bg-white mb-10 pad-5 mt-10">
                    <Col md={12}>
                        {Evaluation?.company_name && <h5>{Evaluation?.company_name}</h5>}
                    </Col>
                </Row>
                <Col className="pad-20   y-scroll" style={{ backgroundColor: "#F5F5F5 " }} >
                    {loading && <Spinner />}
                    {!showMessage ? (
                        <>
                            <Row className="row-pad-0    recordedinterview-wrap pad-10">
                                <h6 className='mt-1 mb-10'>Evaluate Candidate(s)</h6>


                                <Col md={8} sm={8} className="pad-10 bg-white border-radius-5 ">
                                    <Row className='row-pad-0 pad-0'>
                                        <h6 >Candidate’s Responses</h6>
                                        <Tab.Container id="left-tabs-example" className="">
                                            <div className='responses-wrap pad-0 mt-10'>
                                                <Nav variant="pills" className="responses-wrap-nav">
                                                    {Evaluation && Evaluation.question_answer.map((answer, index) => (
                                                        <Nav.Item key={index + 1}>
                                                            <Nav.Link className={`box cursor question-nav-link ${index === answerId ? 'active' : ''}`} onClick={() => handleTabSelect(index)}>
                                                                <span>{icons[answer.answer_media]}</span>
                                                                <span>Question {index + 1} - {answer.answer_media}</span>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                                </Nav>
                                                <div className='Response-left-side'>
                                                    <Row className='row-pad-0'>
                                                        {selectedAnswer && (
                                                            <>
                                                                <div className='Response-div question row-pad-0'>
                                                                    <p>{selectedAnswer.question_text}</p>
                                                                </div>
                                                                <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10'>
                                                                    <div className='thingtime'>
                                                                        <small>{thingtime}Think Time:</small><span> {selectedAnswer.answer_think_time}mins</span>
                                                                    </div>
                                                                    <div className='responestime'>
                                                                        <small>{responsestime}Response Time:</small><span>{selectedAnswer.answer_response_time}mins</span>
                                                                    </div>
                                                                    <div className='retakes'>
                                                                        <small>{retake}Retakes:</small><span>{selectedAnswer.answer_retake}</span>
                                                                    </div>
                                                                </div>

                                                                {selectedAnswer.question_type_id === 1 && (
                                                                    <>
                                                                        <div className='mt-20 Response-wrap pad-10'>
                                                                            <video width="100%" height="auto" controls>
                                                                                <source src={`${config.API_BASE_URL_PUBLIC}/${selectedAnswer.answer}`} type="video/mp4" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </div>
                                                                        <EvaluateComment selectedAnswer={selectedAnswer} interviewId={Evaluation?.interview_id} token={token} />
                                                                    </>
                                                                )}

                                                                {selectedAnswer.question_type_id === 2 && (
                                                                    <>
                                                                        <div className='mt-20 Response-wrap pad-10'>
                                                                            <audio width="100%" height="auto" controls>
                                                                                <source src={`${config.API_BASE_URL_PUBLIC}/${selectedAnswer.answer}`} type="audio/mp4" />
                                                                                Your browser does not support the audio tag.
                                                                            </audio>
                                                                        </div>
                                                                        <EvaluateComment selectedAnswer={selectedAnswer} interviewId={Evaluation?.interview_id} token={token} />
                                                                    </>
                                                                )}

                                                                {selectedAnswer.question_type_id === 3 && (
                                                                    <>
                                                                        <div className='Response-wrap pad-10 mt-20'>
                                                                            {selectedAnswer.question_options.map((option, index) => (
                                                                                <div className='Response-MCQResponse' key={index}>
                                                                                    <Checkbox
                                                                                        label={option.value}
                                                                                        type="radio"
                                                                                        id={`option${index + 1}`}
                                                                                        name="options"
                                                                                        checked={option.value === selectedAnswer.answer}
                                                                                    />



                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <EvaluateComment selectedAnswer={selectedAnswer} interviewId={Evaluation?.interview_id} token={token} />
                                                                    </>
                                                                )}

                                                                {selectedAnswer.question_type_id === 4 && (
                                                                    <>
                                                                        <div className='Response-wrap mt-20 pad-10'>
                                                                            <p className='text-p'>{selectedAnswer.answer}</p>
                                                                        </div>
                                                                        <EvaluateComment selectedAnswer={selectedAnswer} interviewId={Evaluation?.interview_id} token={token} />
                                                                    </>
                                                                )}

                                                                {selectedAnswer.question_type_id === 5 && (
                                                                    <>
                                                                        <div className='Response-wrap pad-10 mt-20'>
                                                                            <FileUploadView icon={word} name={`${config.API_BASE_URL_PUBLIC}/${selectedAnswer.answer}`} />
                                                                        </div>
                                                                        <EvaluateComment selectedAnswer={selectedAnswer} interviewId={Evaluation?.interview_id} token={token} />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </Row>
                                                </div>
                                            </div>
                                        </Tab.Container>
                                    </Row>
                                </Col>

                                <Col md={4} sm={4} className="border-radius-5 bg-white  ">



                                    <h5 className="mb-10">Final Remarks</h5>
                                    <Label text="Final Rating:" />

                                    <div className='feedback pad-10 mb-10'>
                                        <StarRating
                                            value={formData.final_rating}
                                            count={5}
                                            size={20}
                                            name="evaluator_rating"
                                            onChange={handleRating}
                                            activeColor="#FFD700"
                                        />

                                    </div>
                                    <Textarea
                                        onChange={handleChange}
                                        name="final_remarks"
                                        as="textarea"
                                        rows={15}
                                        placeholder="Enter your final remarks about this candidate here"
                                        value={formData.final_remarks}
                                    />
                                </Col>
                            </Row>
                            <Row className="row-pad-0 mt-20 bg-white border-radius-5 pad-10">
                                <Col md={12}>
                                    <h5>Note</h5>
                                    {Evaluation?.message && <p>{Evaluation?.message}</p>}
                                </Col>
                            </Row>
                            <Col md={12} className="text-end mt-20 mb-20">
                                <CustomButton text="Cancel" className="custom-btn-outline mr-10" />
                                <CustomButton text="Submit Evaluation" className="custom-btn-primary" onClick={SubmitEvaluation} />
                            </Col>
                        </>
                    ) : <Message />}
                </Col>

            </Col>
        </>
    );
}

export default EvaluateCandidate;
