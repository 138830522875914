import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJobs } from '../../store/actions/jobActions';
import Select from 'react-select'
const CopyJob = ({ Changehandle, copyJob }) => {
    const dispatch = useDispatch();
    const { allGetJobs, loading, error } = useSelector((state) => state.job);
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {

        dispatch(getJobs())

    }, [dispatch]);
    const handleChange = (selectedOption) => {

        if (selectedOption) {

            Changehandle(selectedOption.value);
        }
        else {
            Changehandle();
        }
    };

    const options = allGetJobs.map(job => ({
        value: job.id,
        label: job.job_title
    }));

    return (
        <>
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                placeholder="Select job to copy"
                defaultValue={options && options.filter((data) => data.value === copyJob)}
            />

        </>
    );
};

export default CopyJob;
