import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import HeaderCompanName from "../components/company/HeaderCompanName";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { companytoken } from "../store/actions/InterviewproceedAction";

const config = require('../config/config.json');


const LayoutInterview = () => {
    const { token } = useParams();
    const dispatch = useDispatch();

    const { companyDetail, jobDetail } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        if (token) {
            dispatch(companytoken(token));

        }
    }, [dispatch]);

    const { company_logo, header_footer_colour_code, name } = companyDetail;
    const backgroundColor = header_footer_colour_code || "#fff";
    const companyName = name || "Vidbpm";

    return (
        <>
            <Col className="pad-0" md={12} sm={12}>
                <HeaderCompanName
                    logo={<img src={`${config.API_BASE_URL_PUBLIC}/${company_logo}`} style={{ width: "100%", height: "100%" }} alt="company logo" />}
                    bgclr={backgroundColor}
                    name={companyName}
                />

                <Col md={12} className="pad-20 bg-ligte-gray y-scroll">
                    <Col md={12} className=" ml-20 mb-10 pl-20"> <label ><span className="main-heading">{jobDetail?.job_title}-</span> <span>{jobDetail?.company_name}</span></label>   </Col>
                    <Row className="row-pad-0">
                        <Outlet />
                    </Row>
                </Col>
            </Col>
        </>
    );
};

export default LayoutInterview;
