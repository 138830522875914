import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteQuestion } from '../actions/questionActions'

const initialState = {
    loading: false,
    questions: [],
    questionDetail: null,
    error: null,
}

const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                // state.companies.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
                // state.questions = action.payload
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.questions = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.questionDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteQuestion.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteQuestion.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteQuestion.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })

    },
})


export default questionSlice.reducer