import React, { useState } from 'react';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import { Ats,ComingSoon,OtherIntegrations } from '../helper/Icons/Icon';
import recruitbpm from "../assets/images/recruitbpm.jpg";

function IntegrationsSetting() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row className="row-pad-0 settings-wrap">

    <Col md={12} className='pad-0 IntegrationsSetting-tab '>
    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
      <Row>
        <Col sm={3} className='template-wrap-nav   '>
          <Nav variant="pills" className="flex-column pad-20 bg-white">
            <Nav.Item>
              <Nav.Link eventKey="tab1">{Ats} ATS Integrations</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab2">{OtherIntegrations} Other Integrations</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="tab3">{ComingSoon} Coming Soon</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9} className=' bg-ligte-gray'>
          <Tab.Content className=''>
            <Tab.Pane eventKey="tab1">
             <Row className="row-pad-0">
                <Col md={4} className="mb-10">
                    <div className='card-warp'>
                    <div class="card-head">
                        <img src={recruitbpm} alt='recruitbpm' />
                        
                    </div>
                    <div class="card-foot">
                        <div>
                        <span>RecruitBPM</span>
                        </div>
                        <div>
                        <a href="#">Deactivate</a>
                        </div>
                      
     </div>
                    </div>
                </Col>
                <Col md={4} className="mb-10">
                    <div className='card-warp'>
                    <div class="card-head">
                        <img src={recruitbpm} alt='recruitbpm' />
                        
                    </div>
                    <div class="card-foot">
                        <div>
                        <span>RecruitBPM</span>
                        </div>
                        <div>
                        <a href="#">Deactivate</a>
                        </div>
                      
     </div>
                    </div>
                </Col>
                <Col md={4} className="mb-10">
                    <div className='card-warp'>
                    <div class="card-head">
                        <img src={recruitbpm} alt='recruitbpm' />
                        
                    </div>
                    <div class="card-foot">
                        <div>
                        <span>RecruitBPM</span>
                        </div>
                        <div>
                        <a href="#">Deactivate</a>
                        </div>
                      
     </div>
                    </div>
                </Col>
                
             </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="tab2">
              <h2> Coming Soon</h2>
            
            </Tab.Pane>
            <Tab.Pane eventKey="tab3">
              <h2> Coming Soon</h2>
        
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </Col>
    </Row>
  
   
  );
}



export default IntegrationsSetting;