import React, { useState, useEffect } from "react";
import { Table, DropdownButton, Dropdown, Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { share, Video, checkbox, copyicon, adduser, eye, pencil, deletclrred, JobActive, exporticon, deletegray } from "../../helper/Icons/Icon";
import Statusbtn from "../common/Statusbtn";
import { actionlistfields, headertimeLine, redordercolumnfields } from "./fields";
import ActionList from '../common/ActionList';
import ShareCandidate from "../common/ShareCandidate";
import { CustomButton, CustomRating, } from "../form/index";
import Liveterview from "../RecordedInterviewDetails/Liveterview";
import ReorderColumns from "../common/ReorderColumns";
import RecordedInterview from "./RecordedInterview";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getAll, deleteInterview, deleteInterviews, exportFile, downloadinterview } from '../../store/actions/interviewActions'
import { useDispatch, useSelector } from 'react-redux';
import Paginate from "../common/Paginate";
import Swal from 'sweetalert2';
import Stages from "../common/Stages";
import InviteLiveInterview from "../common/InviteLiveInterview";
import Tabelcolumn from "../common/Tabelcolumn";
import { getByName } from "../../store/actions/userPrefrenceActions";
const defaultCurrentPage = 1

const InterviewList = () => {
    const dispatch = useDispatch();
    const [updateList, setUpdateList] = useState(true)
    const [showRecordedInterview, setShowRecordedInterview] = useState(false);
    const [showactionlist, setShowActionList] = useState(false);
    const [showcandidateEvaluation, setShowCandidateEvaluation] = useState(false);
    const [showLiveterview, setShowLiveterview] = useState(false);
    const [showReorderColumns, setShowReorderColumns] = useState(false);
    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(25);
    const [candidateId, setCandidateId] = useState(null);
    const [reload, setReload] = useState(false);
    const [interviewIds, setInterviewIds] = useState([]);
    const [searchField, setSearchField] = useState({});
    const [showSelectJob, setSelectJob] = useState(false);
    const prefrenceName = "users";
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const { interviews, loading, error } = useSelector((state) => state.interview);

    let interviewList = interviews.data;
    let headers = interviews.headers;
    let pages = interviews.pages;
    let totalPages = interviews.total_page;
    let totalRecords = interviews.total_record;

    const [sortConfig, setSortConfig] = useState({});
    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(data));
        }


    }, [dispatch, reload, currentPage, perPage, searchField, showReorderColumns, sortConfig]);

    const reloadList = (bol) => {
        setReload(bol)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const handleModel = (props, id) => {
        setCandidateId(id);
        switch (props) {
            case "actionlist":
                setShowActionList(true);
                break;

            case "Liveterview":
                setShowLiveterview(true);
                break;
            case "ReorderColumns":
                setShowReorderColumns(true);
                break;
            case "candidateEvaluation":
                setShowCandidateEvaluation(true);
                break;
            case "SelectJob":
                setSelectJob(true);
                break;
            case "Delete":
                handleDelete(id);
                setShowActionList(true);
                break;
            case "ViewDetail":
                setShowRecordedInterview(true);
                break;
            default:
                break;
        }
    };

    const closeModel = (props, id) => {
        setCandidateId(null);
        switch (props) {
            case "actionlist":
                setShowActionList(false);
                break;
            case "ViewDetail":
                setShowRecordedInterview(false);
                break;
            case "Liveterview":
                setShowLiveterview(false);
                break;
            case "ReorderColumns":
                setShowReorderColumns(false);
                break;

            case "candidateEvaluation":
                setShowCandidateEvaluation(false);
                break;
            case "SelectJob":
                setSelectJob(false);
                break;
            case "Delete":
                setShowActionList(false);
                break;

            default:
                break;
        }
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setInterviewIds(interviewList.map(interview => interview.id));
        } else {
            setInterviewIds([]);
        }
    };

    const handleCheckboxChange = (id) => {
        setInterviewIds(prevSelectedItems => {
            if (prevSelectedItems.includes(id)) {
                return prevSelectedItems.filter(itemId => itemId !== id);
            } else {
                return [...prevSelectedItems, id];
            }
        });
    };

    const deleteMsg = () => Swal.fire({
        icon: 'success',
        title: 'Deleted',
        text: 'Item(s) has been deleted.',
        showConfirmButton: false,
        timer: 3000
    });

    const handleDelete = (interviewId = false) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                if (interviewId) {
                    dispatch(deleteInterview(interviewId))
                        .then(() => {
                            reloadList(true)
                            deleteMsg();

                        });
                } else {
                    var FormData = require('form-data');
                    var data = new FormData();
                    interviewIds.forEach((value, index) => {
                        data.append(`ids[${index}]`, value);
                    });

                    dispatch(deleteInterviews(data))
                        .then(() => {
                            reloadList(true)
                            setInterviewIds([]);
                            deleteMsg();
                        });
                }
            }
        });

        reloadList(false)
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        searchField[name] = value;
        setSearchField({ ...searchField })
    };

    const refreshPage = (page) => {
        setSearchField({});
        setCurrentPage(defaultCurrentPage);
        setPerPage(perPage);
        setSortConfig({})
        setUpdateList(!updateList)
        let data = {
            'limit': perPage, 'page': currentPage, search: searchField
        }
        dispatch(getAll(data));
    };

    const exportInterviews = () => {
        let payload = {
            'export_file': 1
        }
        dispatch(exportFile(payload));
    }
    const InterviewsDownload = (interviewId = false) => {

        dispatch(downloadinterview(176))
            .then((res) => {
                const blob = new Blob([res.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "ii");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                Swal.fire({
                    icon: 'success',
                    title: 'Invoice Downloaded',
                    text: 'Invoice downloaded successfully.',
                    showConfirmButton: false,
                    timer: 2000
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: `Failed to download invoice: ${error.message}`,
                });
            });
    }

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }
            <Row className="listing-section row-pad-0" >
                <Col md={12} className="text-end d-flex justify-content-end mt-10 mb-10" >
                    <Dropdown className="linsting-dropdown-arrowNone mr-10" drop="down-centered start" >
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-quick-action">
                                    More Items
                                </Tooltip>
                            }
                        >
                            <Dropdown.Toggle id="dropdown-autoclose-true" className="header-btn y-center" variant="none">
                                <i class="fa fa-ellipsis-v" ></i>

                            </Dropdown.Toggle>
                        </OverlayTrigger>
                        <Dropdown.Menu>

                            <Dropdown.Item onClick={() => handleDelete()} disabled={interviewIds.length === 0}>
                                {deletegray} Delete
                            </Dropdown.Item>
                            <Dropdown.Item onClick={exportInterviews} disabled={interviewIds.length === 0}>
                                {exporticon} Export
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => InterviewsDownload()} disabled={interviewIds.length === 0}>
                                {adduser} Download Interviews
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Stages reloadList={reloadList} module={'interviews'} userPrefrence={'interview_listing_stage_filter'} />


                    <CustomButton className="header-btn mr-10" Tooltip="Select column" onClick={() => handleModel("ReorderColumns")} text={<i class="fa fa-columns fa-fw"></i>} />
                    <CustomButton Tooltip="Refresh" onClick={refreshPage} className="header-btn mr-10" text={<i class="fa fa-refresh"></i>} />
                </Col>

                <div className="oveflow-auto">
                    <Table className="table" >
                        <thead>
                            <tr>
                                <th style={{ width: "15px" }} className="th-checbox">
                                    <Form.Check type="checkbox" onChange={handleSelectAllChange} />
                                </th>

                                <th style={{ width: "15px" }} className="th-icon" ><i className="fa fa-ellipsis-v" title=""></i></th>
                                <th style={{ minWidth: "100px", width: "150px" }} className="th-text">Quick Action</th>
                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;
                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                <Form.Control name={header.key} value={fieldValue} type="text" placeholder={header.title} onChange={handleFieldSearch} />
                                            </th>
                                        )
                                    })
                                }

                            </tr>
                        </thead>

                        <tbody>
                            {interviewList && interviewList.length > 0 ? (
                                interviewList.map((interview) => (
                                    <tr key={interview.id}>
                                        <td>
                                            <Form.Check
                                                key={interview.id}
                                                type="checkbox"
                                                checked={interviewIds.includes(interview.id)}
                                                onChange={() => handleCheckboxChange(interview.id)}
                                            />
                                        </td>
                                        <Tabelcolumn className="cursor cursor td-icon" onClick={() => handleModel("actionlist", interview.id)} icon={<i className="fa fa-ellipsis-v" title="Quick Action"></i>} Tooltip="Quick Action" />


                                        <td className="cursor td-icon">
                                            <div className="quick-action">
                                                {interview.interview_type_id == 2 ?

                                                    <Tabelcolumn className="cursor cursor td-icon" span="span" icon={Video} Tooltip="Live Interview" />
                                                    : <Tabelcolumn className="cursor cursor td-icon" span="span" icon={Video} onClick={() => handleModel("ViewDetail", interview.id)} Tooltip="Video Interview" />}
                                                <Tabelcolumn className="cursor cursor td-icon" span="span" icon={share} onClick={() => handleModel("candidateEvaluation", interview.job_id)} Tooltip="Share  for Evaluation" />
                                                {/* <span>{copyicon}</span> */}

                                            </div>
                                        </td>
                                        {Object.keys(interview).map(function (key) {
                                            if (key == "job_title")
                                                return <td className="td-text"><a href="#">{interview.job_title}</a></td>
                                            else if (key == "candidate_name")
                                                return <td className="td-text">{interview?.candidate_name}</td>;
                                            else if (key == "stage_name")
                                                return <td className="td-text"><Statusbtn className={` status status-${interview?.stage_name}`} text={interview?.stage_name} /></td>;
                                            else if (key == "ratings")
                                                return <td className="td-text"> {<CustomRating value={interview.interview_rating} />}</td>;
                                            else if (key.includes("_date") || key.includes("_time"))
                                                return <td className="td-text">{moment(interview[key]).format('DD-MM-YYYY h:m A')}</td>;
                                            else if (key != "id" && key != "interview_type_id" && key != "candidate_id" && key != "job_id")
                                                return <td className="td-text">{interview[key]}</td>


                                        })}

                                    </tr>
                                ))) : (
                                <tr>
                                    <td colSpan={10} className="text-center">No records found.</td>
                                </tr>
                            )

                            }

                        </tbody>
                    </Table>

                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                    />

                </div>
            </Row>

            {showactionlist && <ActionList ID={candidateId} onclick={handleModel} headertext="Action List" handleClose={() => closeModel("actionlist")} list={actionlistfields} />}
            {showcandidateEvaluation && <ShareCandidate jobId={candidateId} handleClose={() => closeModel("candidateEvaluation")} />}
            {showLiveterview && <Liveterview timeLineheader={headertimeLine} handleClose={() => closeModel("LiveterviewClose")} />}
            {showRecordedInterview && <RecordedInterview id={candidateId} timeLineheader={headertimeLine} handleClose={() => closeModel("ViewDetail")} />}
            {showSelectJob && <InviteLiveInterview handleClose={() => closeModel("SelectJob")} text="Select Job" modelopnetext="ShareCandidate" />}

            {
                showReorderColumns && <ReorderColumns module="interviews" headertext="Reorder Columns " handleClose={() => closeModel("ReorderColumns")} />
            }
        </>
    );
};



export default InterviewList;
// /interviews/{id}