import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, InputField, Label } from '../form';
import Countriesflage from '../common/Countriesflage';

const SigninSecurity = ({ security }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(security);
  const [showChecked, setChecked] = useState(false);
  const [isVerificationSent, setVerificationSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const toggleAccordion = () => {
    setAccordionOpen(prevState => !prevState);
  };

  const handleSwitch = (e) => {
    const { checked } = e.target;
    setChecked(checked);
    if (checked) {
      setVerificationSent(false); // Reset verification status when switch is turned on
    }
  };

  const handleSendVerification = () => {
    const phonePattern = /^\+?[0-9]+$/;
    if (phoneNumber === '') {
      setPhoneError('Phone number is required');
      return;
    }
    if (!phonePattern.test(phoneNumber)) {
      setPhoneError('Phone number must contain only numbers');
      return;
    }
    setPhoneError(''); // Clear error if validation passes
    // Simulate sending verification code (replace with actual implementation)
    setVerificationSent(true);
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    const phonePattern = /^\+?[0-9]*$/;

    if (phonePattern.test(value)) {
      setPhoneNumber(value);
      setPhoneError(''); // Clear error if the value is valid
    } else {
      setPhoneError('Phone number must contain only numbers');
    }
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  return (
    <>
      <Accordion className='pad-0 bg-white'>
        <Row className='pad-5 mt-1 mb-1 bg-white row-pad-0'>
          <Col md={6} className='d-flex justify-content-start align-items-center'>
            <h5 className='sub-heading mt-2'>Signin & Security</h5>
          </Col>
          <Col md={6} className="d-flex justify-content-end align-items-center">
            <a href="#" className='ml-10 mt-1 mr-10' onClick={(e) => { e.preventDefault(); toggleAccordion(); }}>
              <i className={`fa ${isAccordionOpen ? 'fa-angle-down' : 'fa-angle-up'} clr-light-black`} style={{ fontSize: "25px" }}></i>
            </a>
          </Col>
        </Row>
        <span className='single-border d-block'></span>

        <Accordion.Collapse in={isAccordionOpen}>
          <Row className='row-pad-0 mt-10 pb-3'>
            <Col md={12}>
              <Row className='row-pad-0'>
                <Col md={12}>
                  <p>Add your active mobile number for security purposes, such as sending you SMS to recover your account if you forget your password.</p>
                </Col>
                <Col md={12}>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="security"
                      checked={showChecked}
                      onChange={handleSwitch}
                    />
                    <span className="slider round"></span>
                  </label>
                </Col>

                <Col md={12} className='mt-10'>
                  {showChecked && (
                    <>
                      <Label text="Phone " />
                      <div className='d-flex'>
                        <InputField
                          type="text"
                          name="number"
                          placeholder="Enter number"
                          style={{ width: "60%" }}
                          value={phoneNumber}
                          onChange={handlePhoneNumberChange}
                        />
                        <CustomButton
                          text="Send Verification Code"
                          type="button"
                          className="custom-btn-primary mr-10 ml-10"
                          onClick={handleSendVerification}
                        />
                      </div>
                      {phoneError && <span style={{ color: 'red',  }}>{phoneError}</span>}
                    </>
                  )}
                </Col>

                <Col md={12} className='mt-10'>
                  {isVerificationSent && (
                    <>
                      <Label text="Verification Code " />
                      <div className='d-flex'>
                        <InputField
                          type="text"
                          name="code"
                          placeholder="Enter code"
                          style={{ width: "60%" }}
                          value={verificationCode}
                          onChange={handleVerificationCodeChange}
                        />
                        <CustomButton
                          text="Activate"
                          type="submit"
                          className="custom-btn-primary mr-10 ml-10"
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
}

export default SigninSecurity;
