import React, { useState, useEffect } from "react";
import { Table, Col, Row, Form, Button } from "react-bootstrap"; // Assuming you are using react-bootstrap
import { eye, pencil, deletclrred, Isdefault } from "../../helper/Icons/Icon";

import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getAll, getById, deleteSTemplate } from '../../store/actions/smsTemplateActions'
import { getByName } from "../../store/actions/userPrefrenceActions";
import { useDispatch, useSelector } from 'react-redux';
import ViewSmsTemplate from "./ViewSmsTemplate";
import EditSMSTemplate from "./EditSMSTemplate";
import Swal from 'sweetalert2';
import Paginate from "../common/Paginate";
import Tabelcolumn from "../common/Tabelcolumn";
const headers = [
    { key: 'template_name', title: 'Template Name' },
    { key: 'template_type', title: 'Template Type' },
    { key: 'message', title: 'Message' },
    { key: 'created_by', title: 'Created By' },
    { key: 'created_date', title: 'Created Date' },
    { key: 'updated_by', title: 'Updated By' },
    { key: 'updated_date', title: 'Updated Date' },
];

const SmsTemplateList = ({ addSmsTemplate }) => {
    const dispatch = useDispatch();
    const [smsTemplateId, setSmsTemplateId] = useState()
    const [updateList, setUpdateList] = useState(false)
    const [showViewdetailsTemplate, setViewdetailsTemplate] = useState(false);
    const [editSMSTemplate, setEditSMSTemplate] = useState(false);
    const [deletej, setDeletej] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [searchField, setSearchField] = useState({});
    const [sortConfig, setSortConfig] = useState({});
    const prefrenceName = "users";
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const { smsTemplates, loading, error } = useSelector((state) => state.smsTemplate);
    let templatesList = smsTemplates.data;
    let pages = smsTemplates.pages;
    let totalPages = smsTemplates.total_page;
    let totalRecords = smsTemplates.total_record;


    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);

    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(data));
        }

    }, [dispatch, updateList, addSmsTemplate, deletej, currentPage, perPage, searchField, sortConfig]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };

    const handelModel = (id, props) => {


        if (props === "view") {
            setSmsTemplateId(id)
            setViewdetailsTemplate(true);
        }

        if (props === "edit") {
            setSmsTemplateId(id)
            setEditSMSTemplate(true);
        }
    }
    const handelCloseModel = (props) => {
        setSmsTemplateId(null)
        setUpdateList(prev => !prev)

        if (props === "view")
            setViewdetailsTemplate(false);
        if (props === "edit") {
            setEditSMSTemplate(false);
        }
    }

    const handleDelete = (smstemplateid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSTemplate(smstemplateid))
                    .then(() => {
                        setDeletej(true)

                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })
                    });
                setUpdateList(prev => !prev)
            }

            setDeletej(false)
        });
    };


    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        searchField[name] = value;
        setSearchField({ ...searchField })
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }

            <div className="oveflow-auto mt-1">
                <Table className="tabel" >

                    <thead>
                        <tr>
                            {/* <th style={{ width: "15px" }}><Form.Check type="checkbox" /></th>
                            <th style={{ width: "15px" }} className="th-icon"><i className="fa fa-ellipsis-v" title="Quick Action"></i></th> */}
                            <th style={{ width: "20px" }} className="th-text">View  Details</th>
                            <th style={{ width: "20px" }} className="th-text">Edit Sms</th>
                            <th style={{ width: "20px" }} className="th-text">Delete</th>
                            {headers &&
                                headers.map((header, index) => {
                                    const fieldValue = searchField[header.key] || '';
                                    const isSortedColumn = sortConfig.key === header.key;

                                    return (
                                        <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                            <span onClick={() => toggleSortOrder(header.key)}>
                                                {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                            </span>

                                            <Form.Control name={header.key} value={fieldValue} type="text" placeholder={header.title} onChange={handleFieldSearch} />
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {templatesList && templatesList.length > 0 ? (
                            templatesList.map(template => (
                                <tr key={template.sms_template_id}>
                                    <td className="cursor quick-action td-icon">
                                        <Tabelcolumn
                                            span="span"
                                            onClick={() => handelModel(template.sms_template_id, "view")}
                                            icon={eye}
                                            Tooltip="View"
                                        />
                                    </td>
                                    <td className="cursor quick-action td-icon">
                                        <Tabelcolumn
                                            span="span"
                                            onClick={() => handelModel(template.sms_template_id, "edit")}
                                            icon={pencil}
                                            Tooltip="Edit"
                                        />
                                    </td>
                                    <td className="cursor quick-action td-icon">
                                        {template.is_default !== true ? (
                                            <Tabelcolumn
                                                span="span"
                                                onClick={() => handleDelete(template.sms_template_id)}
                                                icon={deletclrred}
                                                Tooltip="Delete"
                                            />
                                        ) : (
                                            <Tabelcolumn
                                                span="span"
                                                className="no-drop"
                                                icon={Isdefault}
                                                Tooltip="Default template can't be deleted"
                                            />
                                        )}
                                    </td>
                                    <td className="td-text text-ellipsis">{template.template_name}</td>
                                    <td className="td-text text-ellipsis">{template.sms_template_type_name}</td>
                                    <td className="td-text text-ellipsis">{template.message}</td>
                                    <td className="td-text">{template.created_by}</td>
                                    <td className="td-text">{moment(template.created_date).format('DD-MM-YYYY h:mm A')}</td>
                                    <td className="td-text">{template.updated_by}</td>
                                    <td className="td-text">{moment(template.updated_date).format('DD-MM-YYYY h:mm A')}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={9} className="text-center">No records found.</td>
                            </tr>
                        )}
                    </tbody>

                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />

            </div>
            {editSMSTemplate && <EditSMSTemplate smsTemplateId={smsTemplateId} handleClose={() => handelCloseModel("edit")} />}
            {showViewdetailsTemplate && <ViewSmsTemplate smsTemplateId={smsTemplateId} handleClose={() => handelCloseModel("view")} />}
        </>
    );
};

export default SmsTemplateList;