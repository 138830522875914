import React from 'react';
import { Form } from 'react-bootstrap';

const Textarea = (props) => {
  return (
    <Form.Control
   { ...props}
    
    />
  );
};

export default Textarea ;