import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { Button, Col, Modal } from "react-bootstrap";
import { UplodedFiles } from '../../store/actions/companyActions';
import { CustomButton } from "../form";
import { camera } from "../../helper/Icons/Icon";
const config = require('../../config/config.json');

const Culture = ({ setcultureImgs, imgesCulture }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);

    // Handle image upload button click
    const handleImageUpload = () => fileInputRef.current.click();

    // Handle file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    // Handle image upload to the server
    useEffect(() => {
        if (selectedImage) {
            const payload = {
                file: selectedImage,
                moduleName: "company"

            }
            dispatch(UplodedFiles(payload))
                .then((res) => {
                    if (res.payload.error) {
                        Swal.fire("Error!", res.payload.error, "error");
                    } else {
                        const uploadedImage = res.payload.data[0].path;
                        const imageObject = { image_file: uploadedImage };
                        setUploadedImages(prev => [...prev, imageObject]);
                        setcultureImgs(prev => [...prev, imageObject]);

                        setSelectedImage(null);
                    }
                })
                .catch(() => Swal.fire("Error!", "Something went wrong!", "error"));
        }
    }, [selectedImage, dispatch, setcultureImgs]);


    useEffect(() => {
        if (imgesCulture) {
            setUploadedImages(imgesCulture);
            setcultureImgs(imgesCulture)
        }
    }, [imgesCulture]);

    // Handle deletion of an image
    const handleDeleteImage = (imagePath) => {
        setUploadedImages(prevImages => prevImages.filter(img => img.image_file !== imagePath));
        setcultureImgs(prevImages => prevImages.filter(img => img.image_file !== imagePath));
        Swal.fire("Deleted!", "Image has been removed.", "success");
    };

    // Show image modal
    const handleShowModal = (image) => {
        setCurrentImage(image);
        setShowModal(true);
    };

    // Close image modal
    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentImage(null);
    };

    return (
        <>
            <h4 className="sub-heading pad-0">Our Culture</h4>
            <span className="single-border d-block"></span>
            <Col md={8} sm={8} className="pad-0 mt-10">
                <div className="culture-body mb-1">
                    {uploadedImages.length > 0 ? (
                        uploadedImages.map((image, index) => (
                            <div key={index} className="mb-3 d-flex">
                                <span className="truncated-text pad-0">{image.image_file.split('/').pop()}</span>
                                <a
                                    className="ml-10 pad-0"
                                    href="#!"
                                    onClick={() => handleShowModal(image)}
                                >
                                    Preview
                                </a>
                                <a
                                    className="clr-red-ligt ml-10 pad-0"
                                    onClick={() => handleDeleteImage(image.image_file)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Delete
                                </a>
                            </div>
                        ))
                    ) : (
                        <span className="pad-0">No images uploaded</span>
                    )}
                </div>
            </Col>
            <Col md={4} sm={4} className="text-end pad-0 mt-10 d-flex flex-end flex-column ">
                <CustomButton
                    onClick={handleImageUpload}
                    className="custom-btn-primary"
                    text="Upload Pictures"
                    icon={camera}
                />




                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
                <span className="mt-1" style={{ color: "#CACACA", fontSize: "10px" }}>
                    Allowed formats: .jpg .jpeg .png
                </span>
                <span className="" style={{ fontSize: "10px", color: "#CACACA" }}>
                    Max file size: 5MB
                </span>
            </Col>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Image Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentImage && (
                        <img
                            src={`${config.API_BASE_URL_PUBLIC}/${currentImage.image_file}`}
                            alt="Preview"
                            style={{ width: "100%" }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Culture;
