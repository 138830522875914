

import React, { useState } from 'react';
import { Col, Row, Tab, Nav, Button } from 'react-bootstrap';
import { ringcopy, videobox, mcqQuestion, textquestion, uploadfile, shareleftarrow, thingtime, retake, readcopy, responsestime, word } from "../../helper/Icons/Icon";
import { Checkbox, CustomButton, CustomRating, Label, Textarea, } from "../form";
import RatingExperience from "../candidate/RatingExperience";
import FileUploadView from '../common/FileUploadView';
const navitem = [
  { eventKey: 'VideoResponse', icon: videobox, label: 'Question 1 - Video Response' },
  { eventKey: 'AudioResponse', icon: ringcopy, label: 'Question 2 - Audio Response' },
  { eventKey: 'MCQResponse', icon: mcqQuestion, label: 'Question 3 - MCQ Response' },
  { eventKey: 'TextResponse', icon: textquestion, label: 'Question 4 - Text Response' },
  { eventKey: 'FileUploadResponse', icon: uploadfile, label: 'Question 5 - File Upload Response' }
];
const PreviewInterview = ({ Proceedaction }) => {
  const [activeKey, setActiveKey] = useState('VideoResponse');
  console.log(navitem)
  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  const comment = "It's hard to put together a meaningful UI prototype without making real requests to an API. By making real requests, you'll uncover problems with application flow, timing, and API design early, improving the quality of both the user experience and AP";
  return (
    <>
      <Row className="row-pad-0  interviewer-responses recordedinterview-wrap">
        <Col md={8}>
          <Row className="row-pad-0 bg-white border-radius-5 pad-10" >
            <h6 className='pad-0 mt-1'> Candidate’s Responses</h6>
            <Row className='row-pad-0 pad-0'>
              <Tab.Container id="left-tabs-example" className="" activeKey={activeKey} onSelect={handleTabSelect}>

                <div className='responses-wrap pad-0 mt-10'>
                  <Nav variant="pills" className="responses-wrap-nav ">
                    {navitem.map((item) => (
                      <Nav.Item key={item.eventKey}>
                        <Nav.Link
                          eventKey={item.eventKey}
                          className={`box cursor ${activeKey === item.eventKey ? 'active' : ''}`}
                        >
                          <span>{item.icon}</span>
                          <span>{item.label}</span>
                        </Nav.Link>
                      </Nav.Item>
                    ))}

                  </Nav>

                  <div className='Response-left-side'>




                    <Row className=' row-pad-0 '>
                      <Tab.Content  >
                        <Tab.Pane eventKey="VideoResponse" >
                          <div className='Response-div question row-pad-0 '>
                            <p>

                              Question: What role do you see yourself in five years from now?
                            </p>
                          </div>
                          <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10 '>
                            <div className='thingtime'>
                              <small>{thingtime}Think Time:</small><span>2hr 5mins</span>
                            </div>
                            <div className='responestime'>
                              <small>{responsestime}Response Time:</small><span>5mins</span>
                            </div>
                            <div className='retakes'>
                              <small>{retake}Retakes:</small><span>5</span>
                            </div>
                          </div>
                          <div className='  mt-20 Response-wrap pad-10'>
                            <video width="100%" height="auto" controls>
                              <source src="weater-video.mp4" type="video/mp4" />
                              Your browser does not support the video tag.

                            </video>
                          </div>

                        </Tab.Pane>
                        <Tab.Pane eventKey="AudioResponse" >
                          <div className='Response-div question  row-pad-0 '>
                            <p>
                              Question: What role do you see yourself in five years from now?
                            </p>
                          </div>
                          <div className='Response-div thingtime-wrap d-flex mt-10 mb-10 row-pad-0 pad-10 '>
                            <div className='thingtime'>
                              <small>{thingtime}Think Time:</small><span>2hr 5mins</span>
                            </div>
                            <div className='responestime'>
                              <small>{responsestime}Response Time:</small><span>5mins</span>
                            </div>
                            <div className='retakes'>
                              <small>{retake}Retakes:</small><span>5</span>
                            </div>
                          </div>
                          <div className=' mt-20 Response-wrap pad-10'>
                            <video width="100%" height="auto" controls>
                              <source src="weater-video.mp4" type="video/mp4" />
                              Your browser does not support the video tag.

                            </video>
                          </div>

                        </Tab.Pane>
                        <Tab.Pane eventKey="MCQResponse">
                          <div className='Response-div question  row-pad-0 '>
                            <p>
                              Question: What role do you see yourself in five years from now?
                            </p>
                          </div>
                          <div className='Response-div thingtime-wrap d-flex mt-10 mb-10 row-pad-0 pad-10 '>
                            <div className='thingtime'>
                              <small>{thingtime}Think Time:</small><span>2hr 5mins</span>
                            </div>
                            <div className='responestime'>
                              <small>{responsestime}Response Time:</small><span>5mins</span>
                            </div>
                            <div className='retakes'>
                              <small>{retake}Retakes:</small><span>5</span>
                            </div>
                          </div>
                          <div className='  Response-wrap pad-10 mt-20'>

                            <div className='Response-MCQResponse  '>
                              <Checkbox
                                label="Option 1"
                                type="radio"
                                id="option1"
                                name="options"
                                defaultChecked
                              />
                            </div>

                            <div className='Response-MCQResponse  '>
                              <Checkbox
                                label="Option 3"
                                type="radio"
                                id="option2"
                                name="options"

                              />
                            </div>
                            <div className='Response-MCQResponse  '>
                              <Checkbox
                                label="Option 3"
                                type="radio"
                                id="option3"
                                name="options"

                              />
                            </div>
                            <div className='Response-MCQResponse  '>
                              {shareleftarrow}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="TextResponse">
                          <div className='Response-div question  row-pad-0 '>
                            <p>
                              Question: What role do you see yourself in five years from now?
                            </p>
                          </div>
                          <div className='Response-div thingtime-wrap d-flex mt-10 mb-10 row-pad-0 pad-10 '>
                            <div className='thingtime'>
                              <small>{thingtime}Think Time:</small><span>2hr 5mins</span>
                            </div>
                            <div className='responestime'>
                              <small>{responsestime}Response Time:</small><span>5mins</span>
                            </div>
                            <div className='retakes'>
                              <small>{retake}Retakes:</small><span>5</span>
                            </div>
                          </div>
                          <div className='  Response-wrap mt-20 pad-10'>
                            <p className='text-p'>
                              In five years, I envision myself in a role where I've made significant contributions to the company's growth and success. I would like to have taken on more responsibilities and potentially be in a leadership position where I can mentor and guide others. However, my primary focus will always be on continuous learning and development to ensure I'm delivering the best possible results for the organization.
                            </p>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="FileUploadResponse">

                          <div className='Response-div question  row-pad-0 '>
                            <p>
                              Question: What role do you see yourself in five years from now?
                            </p>
                          </div>
                          <div className='Response-div thingtime-wrap d-flex mt-10 mb-10 row-pad-0 pad-10 '>
                            <div className='thingtime'>
                              <small>{thingtime}Think Time:</small><span>2hr 5mins</span>
                            </div>
                            <div className='responestime'>
                              <small>{responsestime}Response Time:</small><span>5mins</span>
                            </div>
                            <div className='retakes'>
                              <small>{retake}Retakes:</small><span>5</span>
                            </div>
                          </div>
                          <div className='  Response-wrap pad-10 mt-20'>
                            <FileUploadView icon={word} name="filename.docx (1.88KB)" />

                          </div>
                        </Tab.Pane>
                      </Tab.Content>

                      <Row className='feedback-wrap mt-20 mb-20 row-pad-0 pad-0'>
                        <Col md={6} >
                          <Label text="Comments:" />

                          < Textarea
                            as="textarea"
                            row={5}
                            className="feedback "
                            placeholder={comment ? comment : ""}
                          />

                        </Col>
                        <Col md={6} className='pl-10'>

                          <Label text="Rate Response:" />
                          <div className='feedback pad-10'>
                            <CustomRating />
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </div>
              </Tab.Container>
            </Row>

          </Row>
        </Col>

        <Col md={4}>
          <RatingExperience />
        </Col>
        <Col md={12} className="text-end mt-20 mb-10">
          <CustomButton className="custom-btn-primary" text="Save & Exit Interview" onClick={() => Proceedaction("calendar")} />
        </Col>

      </Row>
    </>)
}
export default PreviewInterview;