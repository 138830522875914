import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getAll } from '../../store/actions/questionActions';
import CreatableSelect from 'react-select/creatable';

const CustomSelect = ({ onchange, selectQuestion }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { questions, loading } = useSelector(state => state.question);
  const questionList = questions.data || [];

  useEffect(() => {
    const payload = {
      search: searchQuery,
      limit: 1,  
    };
    dispatch(getAll(payload));
  }, [dispatch, searchQuery]);

  const transformedOptions = questionList.map(option => ({
    value: option.question_text,
    label: option.question_text,
  }));

  const handleInputChange = (inputValue) => {
    setSearchQuery(inputValue);
  };

  const handleChange = (selectedOption) => {
    if (!selectedOption) return;
    const { value, label } = selectedOption;
    onchange({ value, label });
  };

  const handleCreateOption = (inputValue) => {
    const newOption = {
      value: inputValue,
      label: inputValue,
    };
    onchange(newOption); 
  };

  return (
    <CreatableSelect
      options={transformedOptions}
      onChange={handleChange}
      isLoading={loading}
      onInputChange={handleInputChange}
      placeholder="Enter or create a question"
      onCreateOption={handleCreateOption}
      value={selectQuestion || null}  
    />
  );
};

export default CustomSelect;
