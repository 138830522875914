

import Checkbox from "../form/Checkbox.jsx";

import { Col, Row, Button,Form } from "react-bootstrap";
import CustomButton from "../form/CustomButton.jsx";
import Spinner from "../common/Spinner.jsx";


const Information=({Proceedaction,jobDetail})=>{
  console.log("  Information")
  console.log(jobDetail.job_description)
    return(<>
   
   {!jobDetail ? <Spinner/>:
     <Row className="row-pad-0 mt-1">
         {/* job and interview infromation*/}
    
                  <Col md={6} className="pad-10">
                    <Row className="mt-1  Job-Information-wrap radius-7 row-pad-0 bg-white ml-10 mt-1">
                      <h5 className="heading-5 mt-1">Job Information</h5>
                      <span className="single-border d-block"></span>
                      <div className="Job-Information-content">
                      <div><label >Job Type:</label><span >{jobDetail.job_type_name}</span></div>
                      <div><label >Workspace Type:</label><span >{jobDetail.workspace_type_name}</span></div>
                      <div><label >Job Location:</label><span >{jobDetail.address}</span></div>
                      <div><label >Salary:</label><span >{jobDetail.pay_salary}</span></div>
                      <div><label >Work Authorization:</label><span >{jobDetail.work_authorization_name}</span></div>

                     </div>
                    </Row>
                    </Col>
                 
                  <Col md={6} className="pad-10">
                      <Row className="mt-1  interview-information-wrap radius-7 row-pad-0 bg-white ml-10 mt-1">
                      <h5 className="heading-5 mt-1">Interview Information</h5>
                      <span className="single-border d-block"></span>
                      <div className="interview-information-content">
                      <div><label >Number of Questions:</label><span >{jobDetail.interview_questions_counts?.total_count}</span></div>
                      <div><label >Interview Duration:</label><span >{jobDetail.interview_questions_counts?.total_count}</span></div>
                      <div><label >Video Questions:</label><span >{jobDetail.interview_questions_counts?.video_count}</span></div>
                      <div><label >Audio Question:</label><span >{jobDetail.interview_questions_counts?.audio_count}</span></div>
                      <div><label >Multiple Choice Questions:</label><span >{jobDetail.interview_questions_counts?.total_count}</span></div>
                      

                     </div>
                    </Row>
                   
                  </Col>
                    {/* job description */}
                  <Col md={12} className="pad-10">
                  <Row className='mt-1  description-wrap row-pad-0   bg-white  mt-1'>
            <h5 className=" heading-5 mt-1">Job Description:</h5>
            <span className='single-border d-block'></span>
            <Col md={12} className='jobdescription-conetent'>

      <p>
      <b>Job Summary:</b>  {jobDetail.job_description}
      </p>

      <strong>Key Responsibilities:</strong>
      <ul  className='mt-10' style={{ listStyleType: "decimal"}}>
        
      <li>
      <label>PHP Development:</label> Write clean, efficient, and well-documented PHP code to develop web applications and websites.
        </li>
        <li>
        <label>Database Integration: </label> Develop and maintain databases using technologies such as MySQL, ensuring data integrity and security.
        </li>
        <li>
        <label>Web Application Development: </label> Create interactive and responsive web applications that meet client or organizational requirements.
        </li>
        <li>
        <label>Code Optimization: </label> Optimize PHP code for improved performance and scalability.
        </li>
        <li>
        <label>Debugging and Troubleshooting: </label> Identify and resolve issues and bugs in existing PHP applications.
        </li>
        <li>
      <label>PHP Development:</label> Write clean, efficient, and well-documented PHP code to develop web applications and websites.
        </li>
        <li>
        <label>Database Integration: </label> Develop and maintain databases using technologies such as MySQL, ensuring data integrity and security.
        </li>
        <li>
        <label>Web Application Development: </label> Create interactive and responsive web applications that meet client or organizational requirements.
        </li>
        <li>
        <label>Code Optimization: </label> Optimize PHP code for improved performance and scalability.
        </li>
        <li>
        <label>Debugging and Troubleshooting: </label> Identify and resolve issues and bugs in existing PHP applications.
        </li>
       
     
    
    

       
      </ul>
      </Col>
    </Row>
                  </Col>
                  {/* Disclaimer */}
                  <Col md={12} className="pad-10">
                  <Row className='mt-1  disclaimer-wrap row-pad-0   bg-white  mt-1'>
            <h5 className=" heading-5 mt-1">Disclaimer:</h5>
            <span className='single-border d-block'></span>
            <Col md={12} className='disclaimer-conetent'>

            <p className="mt-10">Please agree to the following terms and conditions before commencing this interview:</p>
        
            <Row className='mt-1 row-pad-0   bg-white  mt-1'>
              <Checkbox
             type="checkbox"
             label="During the interview process, your video interview, name, email address, and phone number may be shared with the inviting organization. For inquiries, please reach out to us at care@vidbpm.com"
             className="form-check-lg" 
           />
             <Checkbox
                type="checkbox"
                label="I acknowledge that my interview data may undergo artificial intelligence processing to generate a transcript, highlight keywords, and similar functions. You can find a comprehensive overview of VidBPM's AI features here."
               className="form-check-lg" 
             />
 
   
                  </Row>
   
      </Col>
    </Row>
                  </Col>
                
                  <Col md={12} className="text-end mt-20 mb-12">
                    <CustomButton text="Proceed" className="custom-btn-primary" onClick={() => Proceedaction("Information")}/>
                  </Col>
                </Row>
     }
                   
    </>)
}
export default Information;


