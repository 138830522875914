import { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';

const ResponseEditor = ({ options }) => {
    const [wordCount, setWordCount] = useState(0);

    const handleChange = (event) => {
        const text = event.target.value;
        const count = text.split(/\s+/).filter(Boolean).length; // Counting words
        setWordCount(count);
    };

    return (
        <>
            <Col md={12} >

                <h4 className='heading'>Enter Response</h4>

                <Form.Group controlId="exampleForm.ControlTextarea1" className='mt-20 p-relative'>
                    <Form.Control as="textarea" rows={6} placeholder="Enter your text here" onChange={handleChange} />
                    <span className={`count-word ${wordCount > options.max_words ? 'text-danger' : ''}`}>
                        {wordCount}/{options.max_words}
                    </span>
                </Form.Group>
                {wordCount > options.max_words && (
                    <p className="text-danger">Word limit exceeded</p>
                )}
            </Col>
        </>
    );
}

export default ResponseEditor;
