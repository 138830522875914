import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {  jobAnalytics ,getInvitations,getrecordedInterviews,getliveInterviews} from '../actions/DashboardAction'

const initialState = {
    loading: false,
    dashboardAnalytics: [],
    dashboardInvitations: [],
    liveInterviews:[],
    recordedInterviews:[],
    error: null,
}

const Dashboard = createSlice({
    name: 'Dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(jobAnalytics.pending, (state, action) => {
                state.loading = true
            })
            .addCase(jobAnalytics.fulfilled, (state, action) => {
                state.loading = false
                state.dashboardAnalytics = action.payload
            })
            .addCase(jobAnalytics.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getInvitations.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getInvitations.fulfilled, (state, action) => {
                state.loading = false
                state.dashboardInvitations = action.payload
            })
            .addCase(getInvitations.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getrecordedInterviews.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getrecordedInterviews.fulfilled, (state, action) => {
                state.loading = false
                state.recordedInterviews = action.payload
            })
            .addCase(getrecordedInterviews.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getliveInterviews.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getliveInterviews.fulfilled, (state, action) => {
                state.loading = false
                state.liveInterviews = action.payload
            })
            .addCase(getliveInterviews.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })

    },
})


export default Dashboard.reducer