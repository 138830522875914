import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { CustomButton, InputField, Label, Textarea } from "../form/index";
import { useDispatch, useSelector } from "react-redux";
import { preInterviewQuestionToken, getCandidateToken, updateCandidateToken, UplodedFileToken } from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from "react-router-dom";

const AddCandidate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const token = location.state?.token;
    const { token } = useParams();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [showerror, setError] = useState(null)

    const { preInterviewQuestionDetail } = useSelector((state) => state.Interviewproceed);
    const { getCandidateDetail, loading, error } = useSelector((state) => state.Interviewproceed);

    useEffect(() => {
        if (token) {
            dispatch(getCandidateToken(token));
            dispatch(preInterviewQuestionToken(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });
        }
    }, [dispatch, token]);
    useEffect(() => {
        setFormData(getCandidateDetail?.data)
    }, [getCandidateDetail]);
    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validatePhone = (phone) => /^[0-9\s\-+()]+$/.test(phone);
    const validateFirstName = (name) => /^[A-Z][a-z]*$/.test(name); // First letter uppercase, rest lowercase

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;

        if (name === "resume" && files.length > 0) {
            const file = files[0];
            setImage(true);

            dispatch(UplodedFileToken({ token, file }))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        setFormData({
                            ...formData,
                            [name]: res.payload.data[0].path
                        });
                        setImage(false);
                    }
                })
                .catch((error) => {
                    sweetAlert("error", "Error!", `${error}!`);
                });
        } else {
            const newValue = value.trim();

            if (name === "profile_summary") {
                const wordCount = newValue.length;
                setWordCount(wordCount);
            }

            let errorMsg = "";
            if (name === "email" && newValue && !validateEmail(newValue)) {
                errorMsg = "Invalid email address";
            } else if (name === "phone" && newValue && !validatePhone(newValue)) {
                errorMsg = "Phone number must be in digits";
            }

            setFormData({
                ...formData,
                [name]: newValue
            });

            setErrors({
                ...errors,
                [name]: errorMsg
            });
        }
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 2500
        });
    };

    const renderInputField = (question) => {
        if (question.checked) {
            switch (question.name) {
                case "address":
                    return (
                        <>
                            <Col md={6} className="mb-10">
                                <Label text={question.label} />
                                <Textarea name={question.name} value={formData[question.name] || ''} placeholder={`Enter ${question.label}`} onChange={handleInputChange} />
                                {errors[question.name] && <span className="text-danger">{errors[question.name]}</span>}
                            </Col>

                        </>
                    );
                case "resume":
                    return (
                        <>
                            <Col md={6} className="mb-10">
                                <Row >
                                    <Col md={12}>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Label text={question.label} />
                                            <InputField type="file" name={question.name} id={question.name} onChange={handleInputChange} />
                                        </Form.Group>
                                        {errors[question.name] && <span className="text-danger">{errors[question.name]}</span>}
                                    </Col>
                                    <Col md={12}>
                                        <Label style={{ fontSize: "12px", color: "#545454", marginTop: "47px" }} text="Allowed File Formats: .docx, .doc, .pdf. Max Size: 5MB" />
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    );
                case "profile_summary":
                    return (
                        <Col md={12} className="mb-10">
                            <Form.Group controlId="exampleForm.ControlTextarea1" className='mt-20 p-relative'>
                                <Form.Control
                                    as="textarea"
                                    rows={6}
                                    value={formData[question.name] || ''}
                                    name={question.name}
                                    placeholder={`Enter ${question.label}`}
                                    onChange={handleInputChange}
                                />
                                <span className={`count-word ${wordCount > 500 ? 'text-danger' : ''}`}>
                                    {wordCount}/500
                                </span>
                            </Form.Group>
                        </Col>
                    );
                default:
                    return (
                        <>
                            <Col md={6} className="mb-10">
                                <Label text={question.label} />
                                <InputField type="text" name={question?.name} value={formData[question?.name] || ''} placeholder={`Enter ${question?.label}`} onChange={handleInputChange} />
                                {errors[question?.name] && <span className="text-danger">{errors[question?.name]}</span>}
                            </Col>
                        </>
                    );
            }
        } else {
            return null;
        }
    };

    const addCandidate = () => {
        const newErrors = {};

        preInterviewQuestionDetail.pre_interview_form.filter(question => question.checked === true).forEach(element => {
            if (!formData[element.name]) {
                newErrors[element.name] = `${element.label.toLowerCase()} is required`;
            } else if (element.name === "email" && !validateEmail(formData[element.name])) {
                newErrors[element.name] = "Invalid email address";
            } else if (element.name === "phone" && !validatePhone(formData[element.name])) {
                newErrors[element.name] = "Phone number must be in digits";
            } else if (element.name === "profile_summary" && wordCount > 500) {
                newErrors[element.name] = "Profile summary cannot exceed 5000 words";
            }
        });

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(updateCandidateToken({ token, payload: formData }))
                .then((response) => {
                    if (response.payload.success) {
                        sweetAlert("success", "Success!", `${response.payload.success}!`);
                        // navigate(`/system-check`, { state: { token: location.state?.token } });
                        navigate(`/system-check/${token}`);
                    }
                    else {
                        return sweetAlert(
                            "error",
                            "Error!",
                            `${response.payload.error}`

                        )
                    }




                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    };

    return (
        <>
            {loading && <Spinner />}
            {showerror ? <div className="link-error-wrap">
                <div className="link-error"><h1>{showerror}</h1></div>
            </div> : (!preInterviewQuestionDetail ? <Spinner /> :
                <Form>
                    <Col md={12} className="bg-white pad-20 mt-20 radius-7">
                        <h4><p>First Things First, Fill Out This Form</p></h4>

                        <Row className="row-pad-0 d-flex flex-wrap">
                            {preInterviewQuestionDetail.pre_interview_form && preInterviewQuestionDetail.pre_interview_form.map((question, index) => (
                                <>
                                    {renderInputField(question)}
                                </>


                            ))}
                        </Row>
                    </Col>
                    <Row className="text-end mt-20 mb-12 row-pad-0">
                        <Col md={12} className="text-end pad-0">
                            <CustomButton className="custom-btn-primary" onClick={addCandidate} text="Proceed" />
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default AddCandidate;
