import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Vidpmlogo } from '../helper/Icons/Icon';
import { Link } from 'react-router-dom';

function Templateemail(){
    const clientName = "[Client]";

  return (
    <Container fluid className='SectionTemplate-wrap' style={{ background:"#F8F8F8", overflowY: "auto" ,width:'100vw',height:'100vh',    padding:'20px 14px' }} >

      <div className='SectionTemplate bg-white pad-20'>
        <Row className='' >
        <Col md={12} className='' style={{textAlign:'center'}}>
      {   Vidpmlogo}
      <h5>VidBPM Trial Follow Up</h5>
        </Col>
      </Row>

      {/* Main Content */}
      <Row>
        <Col>
          <h6 className='template-h6 mt-10'>Hi {clientName},</h6>
          <p className='template-p line-height-26 mt-15' >
            Welcome to VidBPM – we’re excited to get you started! VidBPM is a uniquely equipped applicant tracking system
            that offers a user-friendly, affordable cloud-based platform to streamline your entire sales and recruiting process.
          </p>
          <p className='template-p mt-15 mb-10'>Your free trial account can be accessed using the details given below:</p>

          <Button
            variant="primary"
            href="/register"
          
            style={{
          
              padding: '10px',
              marginTop:'15px',
              backgroundColor: '#007bff',
              color: '#fff',
              textDecoration: 'none',
              textAlign: 'center',
              borderRadius: '4px',
            }}
          >
            Access Your Free Trial Account
          </Button>

          <p  className='template-p mt-15'>Over 1,000s of paying customers have been using VidBPM to hire and manage the best talent:</p>
          <ul className='template-ul'>
            <li>Job posting and complete applicant tracking lifecycle</li>
            <li>Schedule appointments and video interviews made easier</li>
            <li>Affordable cloud-based recruiting platform</li>
          </ul>
        </Col>
      </Row>
   
      <Row>
        <Col className='line-height-14 mt-10'>
        <p className='template-p'>Best Regards,</p>
          <p className='template-p'>Theresa Anerson</p>
          <p className='template-p'>Client Relation Success Manager |<Link to="#"> VidBPM </Link> </p>
          <p className='template-p'><Link to="#">theresa@vidbpm.com</Link> | +1 952-548-6629 | <Link to="#">LinkedIn</Link></p>
       
          <p className='template-p'>Check Out our <Link to="#"> Knowledge-base</Link></p>
        </Col>
      </Row>

    
      </div>
    </Container>
  );
};


export default Templateemail;