import { useEffect } from 'react';
import { CustomButton, InputField, Label ,Checkbox, Switch} from '../form';
import { Row,Col,Table, ProgressBar,Nav,Tab } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get } from '../../store/actions/accountBillingAction';
import Spinner from '../common/Spinner';
import * as moment from 'moment'
const CardInfromation=({HandelModel})=>{

    const dispatch=useDispatch();

    const { accountBilling, loading, error } = useSelector((state) => state.accountBilling);


    useEffect(()=>{
        dispatch(get());
    },[dispatch])
console.log(accountBilling,"accountBilling")
    return(<>
{loading && <Spinner/>}
<Col md={12} className="bg-white radius-7 mt-20">

<Row className='row-pad-0 mt-10'>
<Col md={12} className="d-flex justify-content-between  mb-10 bg-white radius-7" >

<h5>Credit Card Information</h5>
<CustomButton text="Update Card Information "  onClick={HandelModel}  className="custom-btn-outline"/>
</Col> 
<Col md={12}>
<h6>Card Information</h6>
<div className='mt-10 mb-10'><label>Card Number:</label><span>{accountBilling?.card_number}</span></div>
<div className='mt-10 mb-10'><label>Expiration Date:</label><span>{moment(accountBilling?.expiry_date).format('DD-MM-YYYY h:m A')}</span></div>
</Col>
<Col md={12} className='mt-10 mb-10'>
<h6>Card's Billing Information</h6>
<address>
{accountBilling?.address}
</address>
</Col>
</Row>
</Col>
    </>)
}

export default CardInfromation;