import { Col, Row } from "react-bootstrap";
import { CustomButton } from "../form/index";
import NavTabel from "./NavTabel";
import users from '../../assets/images/users.png';
import { Jobs } from "../../helper/Icons/Icon";
import ChangeStatus from "./ChangeStatus";
import * as moment from 'moment'

const Detail = ({ jobDetail, onclick, update }) => {

    return (
        <>
            <Row className="row-pad-0 custom-row-pad-0">
                <Col md={1} className="custom-md-1 mt-15">
                    <div className="profile">
                        <span>{Jobs}</span>
                    </div>
                </Col>
                <Col md={11} className="content pad-0 custom-md-11 mt-10">
                    <div className="flex-space-between">
                        <div className="d-flex base-line">
                            <h5 className="min-sub-heading">
                                <label>{jobDetail.job_title} <small className="span-data"> / {jobDetail.company_name}</small> </label>
                            </h5>
                            <CustomButton className={`btn-status ml-10 status-${jobDetail.stage_name}`} text={jobDetail.stage_name} />
                        </div>
                        <div>
                            {/* <CustomButton className="actionlist-btn" icon={<i class="fa fa-pencil"></i>} /> */}
                        </div>
                    </div>
                    <div className="flex-Xcenter">
                        <div className="list-title">
                            <label className="title-label">Job Type :</label><span className="span-data">{jobDetail.job_type_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Pay/Salary :</label><span className="span-data">{jobDetail.pay_salary}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Work Authorization :</label><span className="span-data">{jobDetail.work_authorization_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Workspace :</label><span className="span-data">{jobDetail.workspace_type_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Job Expires on :</label><span className="span-data">{moment(jobDetail.job_expiry_date).format('MM-DD-YYYY')}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Pay Type :</label><span className="span-data">{jobDetail.pay_type_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Interview Template :</label><span className="span-data">{jobDetail.interview_template_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label">Followup User:</label><span className="span-data">{jobDetail.followup_user_name}</span>
                        </div>
                        <div className="list-title">
                            <label className="title-label"> Industry :</label><span className="span-data">{jobDetail.industry_name}</span>
                        </div>
                    </div>
                    <div className="list-location">
                        <label className="title-label">Location :</label>
                        <span className="span-data">
                            <a
                                href={`https://www.google.com/maps/place/${encodeURIComponent(jobDetail.address + ', ' + jobDetail.city + ', ' + jobDetail.state_name + ', ' + jobDetail.zip_code + ', ' + jobDetail.country_name)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {jobDetail.address + ', ' + jobDetail.city + ', ' + jobDetail.state_name + ', ' + jobDetail.zip_code + ', ' + jobDetail.country_name}
                            </a>
                        </span>
                    </div>

                    <div className="list-description">
                        <label className="title-label">job description :</label>

                        <p className="span-data bg-white pad-10 radius-5" dangerouslySetInnerHTML={{ __html: jobDetail.job_description }}></p>


                    </div>

                </Col>
            </Row>

            <NavTabel onclick={onclick} jobId={jobDetail.id} update={update} />
        </>
    )
}

export default Detail;