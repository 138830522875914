import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteCompany, deleteDocument } from '../actions/documentActions'

const initialState = {
    loading: false,
    documents: [],
    documentDetail: null,
    error: null,
}

const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.documents = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.documentDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteDocument.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteDocument.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteDocument.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default documentSlice.reducer