import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TextEditor = ({ setTextValue, textValue ,placeholderValue }) => {
    const editorRef = useRef(null);

    const handleEditorChange = (newContent, editor) => {
        setTextValue(newContent);
    };

  

    return (
        <>
            <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                apiKey='79gdjwo16xfsiymxu25o317d9ry4a10vj11yacbxsxsfd4ig'
                init={{
                    height: 250,
                    menubar: false,
                    language: 'en_US',
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar: 'styles | bold italic | alignleft aligncenter alignright alignjustify',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; direction:ltr; }',
                    placeholder:placeholderValue 
                }}
                value={textValue}
                onEditorChange={handleEditorChange}
            />
        </>
    );
};

export default TextEditor;
