import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../form/Checkbox.jsx";
import CustomButton from "../form/CustomButton.jsx";
import Spinner from "../common/Spinner.jsx";
import { jobtoken } from "../../store/actions/InterviewproceedAction.js";

const LiveJobDetail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { jobDetail, loading, error } = useSelector((state) => state.Interviewproceed);

  useEffect(() => {
    dispatch(jobtoken(token));
  }, [dispatch, token]);

  // State to manage checkbox selections
  const [isDisclaimerChecked1, setDisclaimerChecked1] = useState(false);
  const [isDisclaimerChecked2, setDisclaimerChecked2] = useState(false);

  // Handler to update checkbox states
  const handleDisclaimerChange1 = (e) => setDisclaimerChecked1(e.target.checked);
  const handleDisclaimerChange2 = (e) => setDisclaimerChecked2(e.target.checked);

  // Check if both checkboxes are checked
  const isProceedButtonDisabled = !(isDisclaimerChecked1 && isDisclaimerChecked2);

  const InterviewProceed = () => {
    if (isProceedButtonDisabled) return; // Prevent proceeding if checkboxes are not checked
    navigate(`/system_check_Live/${token}`);
  };

  return (
    <>
      {loading && <Spinner />}
      {!jobDetail ? (
        <Spinner />
      ) : (
        <>
          <Row className="row-pad-0 mt-1">
            {/* job and interview information */}
            <Col md={12} className="pad-10">
              <Row className="mt-1 Job-Information-wrap radius-7 row-pad-0 bg-white ml-10 mt-1">
                <h5 className="heading-5 mt-1">Job Information</h5>
                <span className="single-border d-block"></span>
                <div className="Job-Information-content d-flex flex-wrap">
                  <div><label>Job Type:</label><span>{jobDetail.job_type_name}</span></div>
                  <div><label>Workspace Type:</label><span>{jobDetail.workspace_type_name}</span></div>
                  <div><label>Job Location:</label><span>{jobDetail.address}</span></div>
                  <div><label>Salary:</label><span>{jobDetail.pay_salary}</span></div>
                  <div><label>Work Authorization:</label><span>{jobDetail.work_authorization_name}</span></div>
                </div>
              </Row>
            </Col>

            {/* Job Description */}
            <Col md={12} className="pad-10">
              <Row className='mt-1 description-wrap row-pad-0 bg-white mt-1'>
                <h5 className="heading-5 mt-1">Job Description:</h5>
                <span className='single-border d-block'></span>
                <Col md={12} className='jobdescription-conetent'>
                  <p>
                    <b>Job Summary:</b> {jobDetail.job_description}
                  </p>
                </Col>
              </Row>
            </Col>

            {/* Disclaimer */}
            <Col md={12} className="pad-10">
              <Row className='mt-1 disclaimer-wrap row-pad-0 bg-white mt-1'>
                <h5 className="heading-5 mt-1">Disclaimer:</h5>
                <span className='single-border d-block'></span>
                <Col md={12} className='disclaimer-conetent'>
                  <p className="mt-10">Please agree to the following terms and conditions before commencing this interview:</p>
                  <Row className='mt-1 row-pad-0 bg-white mt-1'>
                    <Checkbox
                      type="checkbox"
                      label="During the interview process, your video interview, name, email address, and phone number may be shared with the inviting organization. For inquiries, please reach out to us at care@vidbpm.com"
                      className="form-check-lg"
                      onChange={handleDisclaimerChange1}
                    />
                    <Checkbox
                      type="checkbox"
                      label="I acknowledge that my interview data may undergo artificial intelligence processing to generate a transcript, highlight keywords, and similar functions. You can find a comprehensive overview of VidBPM's AI features here."
                      className="form-check-lg"
                      onChange={handleDisclaimerChange2}
                    />
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* Proceed Button */}
            <Col md={12} className="text-end mt-20 mb-12">
              <CustomButton
                text="Proceed"
                className="custom-btn-primary"
                onClick={InterviewProceed}
                disabled={isProceedButtonDisabled}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LiveJobDetail;
