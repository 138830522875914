import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import { Label } from '../form';

const InterviewTemplates = ({ interviewTemplateId, onChange,interviewTemplates }) => {
    const dispatch = useDispatch();
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleChange = (selectedOption) => {
        if (selectedOption) {
            onChange('interview_template_id', selectedOption.value);
        } else {
            onChange('interview_template_id', '');
        }
    };

    if (!interviewTemplates.data || !Array.isArray(interviewTemplates.data)) {
        return (
            <>
                <Label text="Select Interview Template" htmlFor="interview_template_id" />
                <Form.Select name='interview_template_id'>
                    <option value="">Something went wrong. Check your internet connection.</option>
                </Form.Select>
            </>
        );
    }

    const options = interviewTemplates.data.map(option => ({
        is_default: option.is_default,
        value: option.interview_template_id,
        label: option.interview_template_name
    }));

    const selectedOption = interviewTemplateId && options.find(option => option.value === interviewTemplateId) 
       

    return (
        <>
            <Label text="Interview Templates" htmlFor="interview_template_id" />
            <Select
                isDisabled={isDisabled}
                isLoading={isLoading}
                isSearchable={isSearchable}
                options={options}
                onChange={handleChange}
                value={selectedOption} // Ensure this is correctly set
                placeholder="Select interview template name"
            />
        </>
    );
};


export default InterviewTemplates;
