import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Label, SelectField } from "../form/index";
import { useDispatch, useSelector } from 'react-redux';
import { getAll as geAllEamilTypes } from '../../store/slices/emailTemplateTypeSlice';
import { getAll as getAllSmsTyps } from '../../store/slices/smsTemplateTypeSlice';

const EmailSmsConfiguration = ({ onChange,emailInterview }) => {
    const dispatch = useDispatch()

    const emailTemplateTypes = useSelector(state => state.emailTemplateType.emailTemplateTypes);
    let videoInterviewEmail = emailTemplateTypes[0]?.emails_templates;
    let liveInterviewEmail = emailTemplateTypes[1]?.emails_templates;
    let externalEvaluatorEmail = emailTemplateTypes[2]?.emails_templates;
    let evaluationSuccessEmail = emailTemplateTypes[3]?.emails_templates;
    let evaluationSuccessEmailEvaluator = emailTemplateTypes[4]?.emails_templates;

    const smsTemplateTypes = useSelector(state => state.smsTemplateType.smsTemplateTypes);
    let videoInterviewSms = smsTemplateTypes[0]?.sms_templates;
    let liveInterviewSms = smsTemplateTypes[1]?.sms_templates;
    let externalEvaluatorSms = smsTemplateTypes[2]?.sms_templates;

    useEffect(() => {
        dispatch(geAllEamilTypes());
        dispatch(getAllSmsTyps());
    }, [dispatch]);

    const handleChange = (e) => {
        onChange(e)
    };


    return (

        <Col md={12} className="pad-0">
            <h5 className="heading-5 ml-10 mt-10">
                Email & SMS Configuration
            </h5>

            <Row className="row-pad-0 mt-10 mb-10">
                <Col md={12} className="configuration mt-10">
                    <Row>
                    <Col md={3} className="mb-10">
                            <Label
                                text="Video Interview Invitation Email"
                                className="label"
                                htmlFor="video_interview_invitation_email"
                            />
                            <Form.Select name='video_interview_invitation_email' id='video_interview_invitation_email' onChange={handleChange}>
                  
                           
                                {videoInterviewEmail &&
                                    videoInterviewEmail.map(option => (
                                        <option key={option.emailTemplateId} 
                                        value={option.emailTemplateId}
                                        selected={(
                                            (emailInterview?.video_interview_invitation_email 
                                              ? option.emailTemplateId === emailInterview?.video_interview_invitation_email 
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}
                                        >{option.emailTemplateName}</option>
                                    ))}
                            </Form.Select >
                        </Col>


                        <Col md={3} className="mb-10">
                            <Label
                                text="Video Interview Invitation SMS"
                                className="label"
                                htmlFor="video_interview_invitation_sms"
                            />
                            <Form.Select name='video_interview_invitation_sms' id='video_interview_invitation_sms' onChange={handleChange}>
                  
                               
                                {videoInterviewSms &&
                                    videoInterviewSms.map(option => (
                                        <option key={option.smsTemplateId} value={option.smsTemplateId}
                                        selected={(
                                            (emailInterview?.video_interview_invitation_sms
                                              ? option.smsTemplateId === emailInterview?.video_interview_invitation_sms 
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}
                                      
                                        
                                        >{option.templateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>




                        
                        <Col md={3} className="mb-10">
                            <Label
                                text="Live Interview Invitation Email"
                                className="label"
                                htmlFor="live_interview_invitation_email"
                            />
                            
                            <Form.Select name='live_interview_invitation_email' id='live_interview_invitation_email' onChange={handleChange}>
                  
                               
                                {liveInterviewEmail &&
                                    liveInterviewEmail.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                        selected={(
                                            (emailInterview?.live_interview_invitation_email
                                              ? option.emailTemplateId === emailInterview?.live_interview_invitation_email 
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}

                                        
                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text="Live Interview Invitation SMS"
                                className="label"
                                htmlFor="live_interview_invitation_sms"
                            />
                            <Form.Select name='live_interview_invitation_sms' id='live_interview_invitation_sms' onChange={handleChange}>
                  
                               
                                {liveInterviewSms &&
                                    liveInterviewSms.map(option => (
                                        <option key={option.smsTemplateId} value={option.smsTemplateId}
                                    
                                        
                                        selected={(
                                            (emailInterview?.live_interview_invitation_sms
                                              ? option.smsTemplateId  === emailInterview?.live_interview_invitation_sms
                                              :option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}
                                        >{option.templateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>


                        <Col md={3} className="mb-10">
                            <Label
                                text="External Evaluator Invitation Email"
                                className="label"
                                htmlFor="external_evaluator_invitation_email"
                            />

                            <Form.Select name='external_evaluator_invitation_email' id='external_evaluator_invitation_email' onChange={handleChange}>
                  
                               
                                {externalEvaluatorEmail &&
                                    externalEvaluatorEmail.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                        selected={(
                                            (emailInterview?.external_evaluator_invitation_email
                                              ? option.emailTemplateId === emailInterview?.external_evaluator_invitation_email
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}

                                        
                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text="External Evaluator Invitation SMS"
                                className="label"
                                htmlFor="external_evaluator_invitation_sms"
                            />
                            <Form.Select name='external_evaluator_invitation_sms' id='external_evaluator_invitation_sms' onChange={handleChange}>
                  
                               
                                {externalEvaluatorSms &&
                                    externalEvaluatorSms.map(option => (
                                        <option key={option.smsTemplateId} value={option.smsTemplateId}
                                        selected={(
                                            (emailInterview?.external_evaluator_invitation_sms
                                              ? option.smsTemplateId === emailInterview?.external_evaluator_invitation_sms
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}
                                      
                                        >{option.templateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text="Interview Success Email to Candidate"
                                className="label"
                                htmlFor="interview_success_email_to_candidate"
                            />
                            <Form.Select name='interview_success_email_to_candidate' id='interview_success_email_to_candidate' onChange={handleChange}>
                  
                               
                               
                                {evaluationSuccessEmail &&
                                    evaluationSuccessEmail.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                        selected={(
                                            (emailInterview?.interview_success_email_to_candidate
                                              ? option.emailTemplateId === emailInterview?.interview_success_email_to_candidate
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}
                                        
                                        
                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>

                        <Col md={3} className="mb-10">
                            <Label
                                text="Evaluation Success Email to External Evaluator"
                                className="label"
                                htmlFor="evaluation_success_email_to_ext_evaluator"
                            />
                            <Form.Select name='evaluation_success_email_to_ext_evaluator' id='evaluation_success_email_to_ext_evaluator' onChange={handleChange}>
                  
                               
                                {evaluationSuccessEmailEvaluator &&
                                    evaluationSuccessEmailEvaluator.map(option => (
                                        <option key={option.emailTemplateId} value={option.emailTemplateId}
                                        
                                        selected={(
                                            (emailInterview?.evaluation_success_email_to_ext_evaluator
                                              ? option.emailTemplateId === emailInterview?.evaluation_success_email_to_ext_evaluator
                                              : option.isDefault)
                                            ? "selected" 
                                            : ""
                                          )}
                                        
                                       
                                        >{option.emailTemplateName}</option>
                                    ))
                                }
                            </Form.Select >
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    );
};

export default EmailSmsConfiguration;
