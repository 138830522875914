import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;

const BASE_URL = `${API_BASE_URL}/documents`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null

export const create = createAsyncThunk(
    'documents/create',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }



        try {
            const { data } = await axios.post(`${BASE_URL}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const getAll = createAsyncThunk(
    'documents/getAll',
    async (payload, thunkAPI) => {
       const payloady = {
            "module_code":"companies",
            "module_pk_id": 2
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }
       // console.log(config);
        try {
            const { data } = await axios.get(`${BASE_URL}`, config)
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const getById = createAsyncThunk(
    'documents/getById',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/${id}`, config);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)


export const deleteDocument = createAsyncThunk(
    'documents/deleteDocument',
    async (id, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.delete(`${BASE_URL}/${id}`, config);
            return data;

        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)
