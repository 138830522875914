import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Textarea from '../form/Textarea';
import Label from '../form/Label';
import StarRating from 'react-rating-stars-component';
import { useDispatch } from 'react-redux';
import CustomButton from '../form/CustomButton'; 
import { addAnswerRating } from '../../store/actions/recorderInterviewAction';
import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1000
    });
};

const InternalComment = ({ selectedAnswer,comment }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        answer_rating:selectedAnswer.answer_rating ? selectedAnswer.answer_rating :0,
        answer_comments: selectedAnswer.answer_comments ? selectedAnswer.answer_comments :' ',
    });

    const [errors, setErrors] = useState({
        answer_rating: '',
        answer_comments: ''
    });

    const validate = () => {
        let isValid = true;
        let newErrors = {
            answer_rating: '',
            answer_comments: ''
        };

        if (formData.answer_comments.trim() === '') {
            newErrors.answer_comments = 'Comments cannot be empty';
            isValid = false;
        }

        if (formData.answer_rating <= 0) {
            newErrors.answer_rating = 'Rating must be greater than zero';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : 'Field is required'
        }));
    };

    const handleRating = (value) => {
        console.log('Handling rating change:', value); // Debugging

        setFormData(prevState => ({
            ...prevState,
            answer_rating: value
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            answer_rating: value > 0 ? '' : 'Rating must be greater than zero'
        }));
    };

    const handleSave = () => {
        if (!validate()) return;

        if (!selectedAnswer?.answer_id) {
            sweetAlert('error', 'Error!', 'Answer ID is missing!');
            return;
        }

        const payload = {
            answer_rating: formData.answer_rating,
            answer_comments: formData.answer_comments
        };

        dispatch(addAnswerRating({ answerId: selectedAnswer.answer_id,payload }))
        .then((res) => {
              
                
            if(res.payload.error){
          
              sweetAlert(
                  "error",
                  "Error!",
                 
                  `${res.payload.error}!`
              );
           
            }
            else{
          
              sweetAlert(
                  "success",
                  "Success!",
                  res.payload.message
              );
            }
           
            
          })
          .catch(error => {
           
              sweetAlert(
                  "error",
                  "Error!",
                  "Something went wrong!"
              );
          });
    };

    return (
        <>
            {comment ? <>
                <Row className='feedback-wrap mb-20 row-pad-0 pad-0 mt-10'>
   

    <Col md={6} className='pad-0'>
                    <Label text="Enter Comments:" />
                    <Textarea
                     style={{ maxHeight: '52px' }}
                        as="textarea"
                        rows={3}
                        onChange={handleChange}
                        name='answer_comments'
                        className="feedback"
                        placeholder=""
                        value={selectedAnswer.answer_comments}
                        readOnly
                    />
                 
                </Col>
                <Col md={6} className='pl-10'>
                    <Label text="Rate Response:" />
                    <div className='feedback pad-10'>
                        <StarRating
                            value={selectedAnswer.answer_rating}
                            count={5}
                            size={20}
                            name="answer_rating"
                      
                            activeColor="#FFD700"
                            readOnly
                        />
                    </div>
               
                </Col>
                </Row>
</>:<>
<Row className='feedback-wrap mb-20 row-pad-0 pad-0 mt-10'>
                <Col md={6} className='pad-0'>
                    <Label text="Enter Comments:" />
                    <Textarea
                     style={{ maxHeight: '52px' }}
                        as="textarea"
                        rows={3}
                        onChange={handleChange}
                        name='answer_comments'
                        className="feedback"
                        placeholder=""
                        value={formData.answer_comments}
                    />
                    {errors.answer_comments && <span className="text-danger">{errors.answer_comments}</span>}
                </Col>
                <Col md={6} className='pl-10'>
                    <Label text="Rate Response:" />
                    <div className='feedback pad-10'>
                        <StarRating
                            value={formData.answer_rating}
                            count={5}
                            size={20}
                            name="answer_rating"
                            onChange={handleRating}
                            activeColor="#FFD700"
                            read
                        />
                    </div>
                    {errors.answer_rating && <span className="text-danger">{errors.answer_rating}</span>}
                </Col>
                </Row>
            <Row className='mb-20 row-pad-0 pad-0'>
                <Col md={12} className="text-end">
                    <CustomButton text="Cancel" className="custom-btn-outline mr-10" />
                    <CustomButton text="Save" className="custom-btn-primary" onClick={handleSave} />
                </Col>
                </Row>
            </>}
        </>
    );
};

export default InternalComment;
