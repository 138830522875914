import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;
const API_public_URl = config.API_BASE_URL_PUBLIC;



const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
  
export const interviewForEvaluator = createAsyncThunk(
    'interviewEvaluator ',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_BASE_URL}/interview-evaluation`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const   generateLink = createAsyncThunk(
    '  generateLink ',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            
        }
   

        try {
            const { data } = await axios.post(`${API_BASE_URL}/candidate-invitations/generate-invitation-link`,payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
export const   generaterecordedinvitationlink = createAsyncThunk(
    '  generaterecordedinvitationlink  ',
    async (payload, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            
        }
   

        try {
            const { data } = await axios.post(`${API_BASE_URL}/candidate-invitations/generate-recorded-invitation-link`,payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
export const   getInterviewEvaluation = createAsyncThunk(
    '  getInterviewEvaluation ',
    async (token, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
           
            },
            
        }
   

        try {
            const { data } = await axios.get(`${API_public_URl}/get-interview-evaluation/${token}`,config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const create = createAsyncThunk(
    'create/Evaluation ',
    async ({token,payload}, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_public_URl}/add-interview-evaluation/${token}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)

export const EvaluationFinalRemarks = createAsyncThunk(
    'EvaluationFinalRemarks ',
    async ({token,payload}, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
        }

        try {
            const { data } = await axios.post(`${API_public_URl}/set-interview-remarks/${token}`, payload, config)
            return data
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)