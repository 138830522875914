import { useState,useEffect } from 'react';
import { CustomButton, InputField, Label ,Checkbox, Switch} from '../form';
import { attachment ,sms,email,users,interviewbilling,JobActive} from '../../helper/Icons/Icon';
import { Row,Col } from 'react-bootstrap';
import UpgradePlan from './UpgradePlan';
import   PlanOverview from "./PlanOverview";
import { accountUsageInfo } from '../../store/actions/accountBillingAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Spinner from '../common/Spinner';
import * as moment from 'moment'

const Plan=({Upgrade,accountInfo,loading,accountStatusverify})=>{
 
    return(
        <>
        
        <Col md={12} className="d-flex justify-content-between pad-0 mb-10" >

{!accountStatusverify.is_trial ?  <h4>{accountStatusverify?.account?.accountName} - {accountStatusverify?.package?.packageName}</h4>: <h4>[Company Name] - Free Trial</h4> }
<CustomButton text="Upgrade Plan "  onClick={Upgrade} className="btn-full"/>
</Col>
<Col md={12} className='billing-plan-card'>
{loading && <Spinner/>}
<div className="billing-wrap-p">
    <p >
    Your Free trial of Growth plan ends in {accountStatusverify?.trial_days_remaining} days. You currently have 1 <a href="http://localhost:3000/integration" target='-blank'>user</a> on your account who have received a total of 2 responses.

    </p>

  
    <p style={{display:"flex",
          justifyContent:"start",
    alignItems:"center"
       
        }} >
         
       <label style={{fontWeight:"700px",}}>Period : </label> <span style={{fontSize:"12px"}}>{ accountStatusverify.is_trial ? moment( accountStatusverify?.trial_expiry).format('DD-MM-YYYY h:m A'):moment(accountStatusverify?.package_expiry).format('DD-MM-YYYY h:m A')  }</span>
        </p>
  <div className='d-flex billing-Content'>
    <div className='title'>
        <h5>{interviewbilling}Interviews: <span>{accountInfo?.interviews}</span></h5>
    </div>
    <div className='billing-box'>
        <span>Allowed Interviews: {accountInfo?.limit_of_interviews} </span>
    </div>
  </div>
  <div className='d-flex billing-Content'>
    <div className='title'>
        <h5>{JobActive}Jobs: <span>{accountInfo?.jobs}</span></h5>
    </div>
    <div className='billing-box'>
        <span>Allowed Jobs: {accountInfo?.limit_of_jobs} </span>
    </div>
  </div>
 
  <div className='d-flex billing-Content'>
    <div className='title'>
        <h5>{users}Users:<span> {accountInfo?.users}</span></h5>
    </div>
    <div className='billing-box'>
        <span>Allowed Users: {accountInfo?.limit_of_users} </span>
    </div>
  </div>
  <div className='d-flex billing-Content'>
    <div className='title'>
        <h5>{email}Email Credits:<span>{accountInfo?.email_messages}</span></h5>
    </div>
    <div className='billing-box'>
        <span>Allowed Credits:{accountInfo?.limit_of_emails}</span>
    </div>
  </div>
  <div className='d-flex billing-Content'>
    <div className='title'>
        <h5>{sms}SMS Credits:<span>{accountInfo?.sms_messages}</span></h5>
    </div>
    <div className='billing-box'>
        <span>Allowed Credits: {accountInfo?.limit_of_sms} </span>
    </div>
  </div>
</div>
</Col> 
        </>
    )
}
export default Plan;