import React, { useEffect, useState ,useNavigate} from "react";
import { useDispatch ,useSelector} from "react-redux";
import { useLocation ,useParams} from "react-router-dom";
import { Col, Row,} from "react-bootstrap";
import SystemCheck from "./SystemCheck.jsx";
import Information from "./Information.jsx";
import PracticeQuestion from "./PracticeQuestion.jsx";
import StartInterview from "./StartInterview.jsx";
import InterviewQuestions1 from "./InterviewQuestions1.jsx";
import PreviewInterview from "./PreviewInterview.jsx";

import ProceedsForm from "./ProceedsForm.jsx";

import Spinner from "../common/Spinner.jsx";


import { jobtoken} from "../../store/actions/InterviewproceedAction";
const Proceed=()=> {
  // const location = useLocation();
  const { token  } = useParams();
  const dispatch =useDispatch()
    const [ProceedShow, proceedset] = useState(true);
    const [systemShow, systemset] = useState(false);
    const [InterviewQuestionShow, InterviewQuestionset]= useState(false);
    const [StartInterviewShow, StartInterviewset]= useState(false);
    const [interviewQuestionShow,   interviewQuestionset]= useState(false);
    const [PreviewInterviewShow ,   PreviewInterviewset]= useState(false);
    const [ProceedsFormShow ,  ProceedsFormset]= useState(false);
    const [calendarShow  , calendarset]=useState(false);
 
  

  
    
const {   jobDetail,loading,error}=useSelector((state) => state.Interviewproceed);

useEffect(()=>{
  
  dispatch(jobtoken(token))
},[dispatch])

    const Proceedaction = (props) => {
    
      if(props=="Information")
      {
        proceedset(false);
        ProceedsFormset(true);
      }
      else if(props=="systemcheck"){
        ProceedsFormset(false);
        systemset(true)

      }
      
      else if (props=="StartInterview"){
        proceedset(false);
        systemset(false);
        StartInterviewset(true)
      }
      else if(props=="PracticeQuestion"){
        proceedset(false);
        systemset(false);
        InterviewQuestionset(true);

      }
  
      else if(props=="InterviewQuestions"){
        proceedset(false);
        systemset(false);
        InterviewQuestionset(false);
        StartInterviewset(false)
        interviewQuestionset(true)

      }
      else if(props=="PreviewInterview"){
        proceedset(false);
        systemset(false);
        InterviewQuestionset(false);
        StartInterviewset(false)
        interviewQuestionset(false)

        PreviewInterviewset(true)

      }
      else if(props=="calendar"){
        proceedset(false);
        systemset(false);
        InterviewQuestionset(false);
        StartInterviewset(false)
        interviewQuestionset(false)

        PreviewInterviewset(false)
        calendarset(true)

      }
   
   
    };
  
    
  
    return (
      <>
        <Col className="pad-0">
          <Row className="row-pad-0 bg-white mb-10 pad-5 mt-10 proceeding-interview-warp">
            <Col md={12}  className="pad-10"  style={{marginLeft:"2.2rem"}}>
              <h5>Company Logo</h5>
            </Col>
          </Row>
          <Col className="pad-20 bg-ligte-gray y-scroll">
            <Row className="row-pad-0">
              <Col md={12} className="">
                <div className=" ml-10">
                  <h4>
                    PHP Developer - <small>Apple Inc</small>
                  </h4>
                </div>
              </Col>
  
             {loading ? <Spinner/>:<>{ProceedShow && <Information jobDetail={jobDetail} Proceedaction={Proceedaction}/>} </>} 
              {ProceedsFormShow && <ProceedsForm Proceedaction={Proceedaction} token={token} />}
              {systemShow && <SystemCheck Proceedaction={Proceedaction} />}  
              {StartInterviewShow &&  <StartInterview  Proceedaction={Proceedaction}  token={token} />}
              {InterviewQuestionShow &&  <PracticeQuestion Proceedaction={Proceedaction} />}
              {interviewQuestionShow   &&<InterviewQuestions1 Proceedaction={Proceedaction} />}
              { PreviewInterviewShow  && <PreviewInterview Proceedaction={Proceedaction} />}
              
            </Row>
          </Col>
        </Col>
      </>
    );
  }
  

  
export default Proceed;
