import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

const Label = (props) => {
  return (
    <Form.Label {...props}>
      {props.text}
      {props.icon && (
        <OverlayTrigger
          placement="top" // Adjust placement if needed
          overlay={
            <Tooltip id="tooltip-quick-action">
             {props.tooltip}
            </Tooltip>
          }
        >
          <span>{props.icon}</span>
        </OverlayTrigger>
      )}
    </Form.Label>
  );
};

export default Label;
