import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { CustomButton } from '../form';
import { crossicon } from '../../helper/Icons/Icon';

const ViewerFile = ({ document, handleClose }) => {
  const docs = [
    {
      uri: document
    }
  ];

  return (
    <Offcanvas show={true} onHide={handleClose} placement='end' className="bg-light-gray custom-offcanvas" style={{ width: "60%" }}>
      <Offcanvas.Header className="model-header bg-white">
        <Offcanvas.Title><span className="svg mr-10"></span>Document Preview</Offcanvas.Title>
        <div className="text-end">
          <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className='mt-10'>
        <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          onError={(error) => {
            console.error("Error loading document:", error);
            alert("Error loading document. Check the console for details.");
          }}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewerFile;
