import { Col, Form, Row } from "react-bootstrap"
import { useState } from "react";
import { CustomRating } from "../form/index";



const RatingExperience = () => {
  const [ratingset, setRating] = useState(0);
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  return (
    <>
      <Row className="pad-0 bg-white pb-10">
        <Col md={12}>
          <h4>How was the interview process?</h4>
          <p className="mt-15">We value your feedback to enhance our interview process. Your input is important to us.
          </p>
          <Form.Label>Rating:</Form.Label>
          <CustomRating RatingExperience={RatingExperience} />
        </Col>
        <Form.Group controlId="exampleForm.ControlTextarea1" className="pad-20">
          <Form.Label>Comments</Form.Label>
          <Form.Control as="textarea" rows={10} placeholder="Enter your final remarks about this candidate here" />
        </Form.Group>
      </Row>
    </>
  )
}
export default RatingExperience;