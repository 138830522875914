
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Offcanvas, Container } from 'react-bootstrap';
import { crossicon, sms } from '../../helper/Icons/Icon';
import { CustomButton, Label } from '../form';
import { getById } from '../../store/actions/smsTemplateActions';
import * as moment from 'moment';
import Spinner from "../common/Spinner";

const ViewSmsTemplate = ({ handleClose, smsTemplateId }) => {
    const dispatch = useDispatch()
    const { smsTemplateDetail, loading, error } = useSelector((state) => state.smsTemplate);


    useEffect(() => {
        if(smsTemplateId){
            dispatch(getById(smsTemplateId));
        }
    
    }, [dispatch]);


    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "50%" }}>
            <Offcanvas.Header className="model-header bg-white actionlist-header ">
                <Offcanvas.Title>
                <span className="model-header-svg">{sms}View SMS Template</span> 
                </Offcanvas.Title>
                <div className="text-end">
                    <CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} />
                </div>
            </Offcanvas.Header>

            {loading && <Spinner />}

            {smsTemplateDetail &&
                <Container fluid className="pad-10">
                    <Row className="mt-20 row-pad-0 bg-white border-radius-5 mt-10 pad-10 view-templae-wrap">
                        <Col md={6} className=" mb-10">
                            <Label className="text-input" text="Template Name" />
                            <div className='view-text'>{smsTemplateDetail.template_name}</div>
                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="text-input" text="Template Type" />
                            <div className='view-text'>{smsTemplateDetail.sms_template_type_name}</div>

                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="text-input" text="Created By" />
                            <div className='view-text'>{smsTemplateDetail.created_by}</div>

                        </Col>
                        <Col md={6} className=" mb-10">
                            <Label className="text-input" text="Created Date " />
                            <div className='view-text'>{moment(smsTemplateDetail.created_date).format('DD-MM-YYYY h:mm A')}</div>

                        </Col>

                        <Col md={12} className=" mb-10">
                            <Label className="text-input" text="Message" />
                            <div className='view-text'> {smsTemplateDetail.message}</div>
                        </Col>

                    </Row>
                </Container>
            }
        </Offcanvas >
    );
};

export default ViewSmsTemplate;
