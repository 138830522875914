import HeaderListing from "../components/common/HeaderListing";
import { JobActive } from "../helper/Icons/Icon";
import JobAnalytics from "../components/dashboard/JobAnalytics";
import Card from "../components/dashboard/Card";
import AddCompany from '../components/company/AddCompany';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getInvitations, jobAnalytics, getliveInterviews, getrecordedInterviews } from "../store/actions/DashboardAction";
import Spinner from "../components/common/Spinner";
import * as moment from 'moment';
import { UsersProfile } from "../store/actions/userActions";
function Dashboard() {
    const headetext = "Dashboard";
    const textbtn = "Customize Company Branding";
    const [show, setShow] = useState(false);
    const [customProps, setcustomProps] = useState(null);
    const profileID="  ";
   
    const handleShow = () => setShow(true);
    const [changeDate,setDate]=useState({
        startDate: new Date(),
        endDate: new Date(),

    })
   

    const [formData, setFormData] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const handleClose = () =>{
        setShowModal(false);
    } 
    const { dashboardInvitations, dashboardAnalytics, loading, error, liveInterviews, recordedInterviews } = useSelector(state => state.dashboard);
    const { UsersProfiledata, } = useSelector((state) => state.user);
    useEffect(() => {
        dispatch(jobAnalytics({ startDate: null, endDate: null }));
        dispatch(getInvitations({ startDate: null, endDate: null }));
        dispatch(getliveInterviews({ startDate: null, endDate: null }));
        dispatch(getrecordedInterviews({ startDate: null, endDate: null }));
        dispatch(UsersProfile(profileID));
    }, [dispatch]);

   useEffect(() => {
        if(UsersProfiledata){
          localStorage.setItem('storedImage', UsersProfiledata?.data?.user?.user_image);
         
        }
        
       
       
},[UsersProfiledata])
    const Invitations = [
        dashboardInvitations?.pending_invitations_count,
        dashboardInvitations?.in_progress_invitations_count,
        dashboardInvitations?.completed_invitations_count,
        dashboardInvitations?.expired_invitations_count
    ];
    const labelInvitations = ['Pending', 'In Progress', 'Completed', 'Expired'];
    const Interviews = [
        liveInterviews?.invited_live_interviews_count,
        liveInterviews?.all_live_interviews_count,
        liveInterviews?.passed_live_interviews_count,
        liveInterviews?.failed_live_interviews_count
    ];
    const labelInterviews = ['Invited', 'Interviewed', 'Passed', 'Failed'];
    const recorded = [
        recordedInterviews?.invited_recorded_interviews_count,
        recordedInterviews?.all_recorded_interviews_count,
        recordedInterviews?.passed_recorded_interviews_count,
        recordedInterviews?.failed_recorded_interviews_count
    ];
    const labelrecorded = ['Invited', 'Interviewed', 'Passed', 'Failed'];

    const handleOpenModal = (props) => {
        setcustomProps(props);
        setShowModal(true);
    };

    const handleDateChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = () => {
      

        const formattedStartDate = moment(formData.startDate).format('YYYY-MM-DD');
        const formattedEndDate = moment(formData.endDate).format('YYYY-MM-DD');

        if (customProps === "jobAnalytics") {
            setDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,

            })
            dispatch(jobAnalytics({
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }))
            .then((res) => {
             
                setShowModal(false);
            })
            .catch((error) => {
           
            });
        } else if (customProps === "Invitations") {
            setDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,

            })
            dispatch(getInvitations({
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }))
            .then((res) => {
           
                setShowModal(false);
            })
            .catch((error) => {
                console.error('getInvitations error:', error);
            });
        } else if (customProps === "RecordedInterviews") {
            setDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,

            })
            dispatch(getrecordedInterviews({
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }))
            .then((res) => {
               
                setShowModal(false);
            })
            .catch((error) => {
           
            });
        } else if (customProps === "LiveInterviews") {
            setDate({
                startDate: formattedStartDate,
                endDate: formattedEndDate,

            })
            dispatch(getliveInterviews({
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }))
            .then((res) => {
              
                setShowModal(false);
            })
            .catch((error) => {
                
            });
        }
    };

    return (
        <>
            <Row className="row-pad-0">
                <Col md={12} className="y-scroll">
                    <HeaderListing
                        headertext={headetext}
                        btnicon={JobActive}
                        onclick={handleShow}
                        variant="outline-primary"
                        textbtn={textbtn}
                    />
                    {loading && <Spinner />}
                    <Row className="row-pad-0 mt-20 border-radius-5 dashoard-analytics">
                        {dashboardAnalytics && (
                            <JobAnalytics
                            formData={changeDate}
                                dashboardAnalytics={dashboardAnalytics}
                                handleOpenModal={()=>handleOpenModal("jobAnalytics")}
                            />
                        )}
                    </Row>
                    <Row className="mt-10 d-flex">
                        {Invitations && (
                            <Card
                            formData={changeDate}
                                dashboard={Invitations}
                                labelData={labelInvitations}
                                handleOpenModal={() => handleOpenModal("Invitations")}
                                name="Invitations"
                            />
                        )}
                        {recorded && (
                            <Card
                            formData={changeDate}
                                dashboard={recorded}
                                labelData={labelrecorded}
                                name="Recorded Interviews"
                                handleOpenModal={() => handleOpenModal("RecordedInterviews")}
                            />
                        )}
                        {Interviews && (
                            <Card
                            formData={changeDate}
                                name="Live Interviews"
                                dashboard={Interviews}
                                labelData={labelInterviews}
                                handleOpenModal={() => handleOpenModal("LiveInterviews")}
                            />
                        )}
                    </Row>
                </Col>
                {showModal && (
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Filter</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Col md={12}>
                                <FormGroup className='d-flex flex-column mb-10'>
                                    <FormLabel className="text-start">Filter Date Range (Start - End)</FormLabel>
                                    <div className="d-flex">
                                        <DatePicker
                                            selected={formData.startDate}
                                            onChange={(date) => handleDateChange('startDate', date)}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                        <span className="mx-2">to</span>
                                        <DatePicker
                                            selected={formData.endDate}
                                            onChange={(date) => handleDateChange('endDate', date)}
                                            className="form-control"
                                            dateFormat="yyyy-MM-dd"
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button   className="custom-btn-outline" onClick={handleClose}>
                                Close
                            </Button>
                            <Button className="custom-btn-primary" onClick={handleSubmit}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Row>
            {show && <AddCompany handleClose={handleClose} />}
        </>
    );
}

export default Dashboard;
