import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const config = require('../../config/config.json');
const API_BASE_URL = config.API_BASE_URL;


const BASE_URL = `${API_BASE_URL}/dashboard`;

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null


export const jobAnalytics = createAsyncThunk(
    'company/jobAnalytics',
    async ({startDate,endDate}, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: {
                startDate,
                endDate
            }
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/job-analytics`,  config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)


export const getInvitations = createAsyncThunk(
    'company/AnalyticsInvitations',
    async ({startDate,endDate}, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: {
                startDate,
                endDate
            }
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/invitations`,  config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)
export const getrecordedInterviews = createAsyncThunk(
    'company/getrecordedInterviews',
    async ({startDate,endDate}, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: {
                startDate,
                endDate
            }
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/recorded-interviews`,  config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
         
        }
    }
)
export const getliveInterviews = createAsyncThunk(
    'company/getliveInterviews',
    async ({startDate,endDate}, thunkAPI) => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userToken
            },
            params: {
                startDate,
                endDate
            }
        }

        try {
            const { data } = await axios.get(`${BASE_URL}/live-interviews`,  config)
            return data;
        } catch (error) {
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response.data.message)
            }
        }
    }
)