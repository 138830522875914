import React, { useState, useEffect } from "react";
import { Table, Col, Row, Form } from "react-bootstrap";
import { eye, pencil, deletclrred } from "../../helper/Icons/Icon";
import { CustomButton } from "../form";
import Spinner from "../common/Spinner";
import { getAll, deleteCompany } from "../../store/actions/companyActions";
import { getByName } from "../../store/actions/userPrefrenceActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReorderColumns from "../common/ReorderColumns";
import EditCompany from "./EditCompany";
import * as moment from 'moment'
import Swal from 'sweetalert2';
import Paginate from "../common/Paginate";
import ViewCompanyModel from "./ViewCompanyModel";
import Tabelcolumn from "../common/Tabelcolumn";


const defaultCurrentPage = 1

const CompanyList = ({ addCompany }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateList, setUpdateList] = useState(true)
    const [deletec, setDeletec] = useState(false);
    const [showEditCompany, setEditCompany] = useState(false);
    const [showCompany, setCompany] = useState(false);
    const [companyId, setCompanyId] = useState();
    const [showreorderColumns, setshowreorderColumns] = useState(false);

    const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
    const [perPage, setPerPage] = useState(25);
    const [searchField, setSearchField] = useState({});
    const prefrenceName = "users";

    const { companies, loading, error } = useSelector((state) => state.company);
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    let companyList = companies.data;
    let headers = companies.headers;
    let pages = companies.pages;
    let totalPages = companies.total_page;
    let totalRecords = companies.total_record;

    const [sortConfig, setSortConfig] = useState({});
    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch, updateList])
    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);
    useEffect(() => {
        if (perPage) {
            let data = {
                'limit': perPage, 'page': currentPage, search: searchField, order_by: sortConfig?.key, sort_by: sortConfig?.order
            }
            dispatch(getAll(data));
        }


    }, [dispatch, addCompany, deletec, currentPage, perPage, searchField, updateList, sortConfig])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };




    const handelModel = (companyId) => {
        setCompanyId(companyId);
        setEditCompany(!showEditCompany);
        setUpdateList(!updateList);
    };

    const handelToggelModel = (type, id) => {
        setCompanyId(id);

        if (type === "reorderColumns") {
            setshowreorderColumns(!showreorderColumns)
        }

        setUpdateList(!updateList);
    };

    const handleDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure to delete the selected item(s).",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCompany(id)).then(() => {
                    setDeletec(true);
                    Swal.fire({
                        icon: "success",
                        title: "Deleted",
                        text: "Item(s) has been deleted.",
                        showConfirmButton: false,
                        timer: 1000,
                    });
                });
            }

            setDeletec(false);
        });
    };

    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        searchField[name] = value;
        setSearchField({ ...searchField })
    };

    const refreshPage = (page) => {
        setSearchField({});
        setCurrentPage(defaultCurrentPage);
        setPerPage(perPage);
        setSortConfig({})
        setUpdateList(!updateList);
        let data = {
            'limit': perPage, 'page': currentPage, search: searchField
        }
        dispatch(getAll(data));
    };

    const viewCompany = (id) => {
        sessionStorage.setItem('companyID', id);
        window.open('/company', '_blank');
    };

    const toggleSortOrder = (key) => {
        setSortConfig(prevConfig => {
            if (prevConfig.key === key) {
                return { key, order: prevConfig.order === 'asc' ? 'desc' : 'asc' };
            } else {
                return { key, order: 'asc' };
            }
        });
    };

    return (
        <>
            {loading && <Spinner />}

            <Row className="listing-section row-pad-0">
                <Col md={12} className="text-end  mt-10 mb-10">
                    <CustomButton
                        className="header-btn mr-10"
                        text={<i class="fa fa-columns fa-fw"></i>}
                        module="companies"
                        Tooltip="Select column"
                        onClick={() => handelToggelModel("reorderColumns")}
                    />
                    <CustomButton
                        className="header-btn mr-10"
                        text={<i class="fa fa-refresh"></i>}
                        onClick={refreshPage}
                        Tooltip="Refresh"

                    />
                </Col>
                <div className="oveflow-companyList-auto">
                    <Table className="tabel" style={{}}>
                        <thead>
                            <tr>
                                <th style={{ width: "20px" }} className="th-text">
                                    View Details
                                </th>
                                <th style={{ width: "45px" }} className="th-text">
                                    Edit
                                </th>
                                <th style={{ width: "20px" }} className="th-text">
                                    Delete
                                </th>
                                {headers &&
                                    headers.map((header, index) => {
                                        const fieldValue = searchField[header.key] || '';
                                        const isSortedColumn = sortConfig.key === header.key;

                                        return (
                                            <th key={index} className="sorting" style={{ minWidth: "100px", width: "200px" }}>
                                                <span onClick={() => toggleSortOrder(header.key)}>
                                                    {isSortedColumn ? (sortConfig.order === 'asc' ? <i class="fa fa-sort-amount-asc" aria-hidden="true"></i> : <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>) : <i class="fa fa-exchange" aria-hidden="true"></i>}
                                                </span>
                                                <Form.Control name={header.key} value={fieldValue} type="text" placeholder={header.title} onChange={handleFieldSearch} />
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {companyList &&
                                companyList.map((company) => {

                                    return (
                                        <tr key={company.id}>

                                            <td className="cursor quick-action td-icon"><Tabelcolumn span="span" onClick={() => viewCompany(company.id)} icon={eye} Tooltip="Detail" /> </td>
                                            <td className="cursor quick-action td-icon"><Tabelcolumn span="span" onClick={() => handelModel(company.id)} icon={pencil} Tooltip="Edit" /></td>
                                            <td className="cursor quick-action td-icon"><Tabelcolumn span="span" onClick={() => handleDelete(company.id)} icon={deletclrred} Tooltip="Delete" /></td>



                                            {Object.keys(company).map(function (key) {
                                                if (key.includes("_date"))
                                                    return <td className="td-text">{moment(company[key]).format('DD-MM-YYYY h:mm A')}</td>;
                                                if (key.includes("company_descriptio"))
                                                    return <td className="td-text" dangerouslySetInnerHTML={{ __html: company[key] }} />;
                                                else if (key != "id")
                                                    return <td className="td-text">{company[key]}</td>

                                            })}


                                        </tr>

                                    )
                                })
                            }
                        </tbody>
                    </Table>

                    <Paginate
                        pages={pages}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        perPage={perPage}
                        totalRecords={totalRecords}
                        handlePageChange={handlePageChange}
                        handlePerPage={handlePerPage}
                    />

                </div>
                {showEditCompany && <EditCompany companyId={companyId} handleClose={() => handelModel(0)} />}
                {showreorderColumns && <ReorderColumns module="companies" headertext="Reorder Columns " handleClose={() => handelToggelModel("reorderColumns")} />}

            </Row>
        </>
    );
};

export default CompanyList;
