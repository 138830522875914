import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const FileUploadView = (props) => {
    const { icon, name, nameext } = props; // `name` should be a valid URL
    const [showPreview, setShowPreview] = useState(false);
    const documentUri = name;

    const handleDownload = async () => {
        try {
            if (nameext === 'pdf') {
                const response = await fetch(documentUri); // No need for `responseType`
                if (!response.ok) throw new Error('Network response was not ok');
                
                const blob = await response.blob();
                const fileURL = window.URL.createObjectURL(blob);
                
                const a = document.createElement('a');
                a.href = fileURL;
                a.download = name.split('/').pop() || 'download.pdf'; // Ensure the filename is correct
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(fileURL); 
            } else {
                const a = document.createElement('a');
                a.href = documentUri;
                a.download = name.split('/').pop() || 'download';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        } catch (error) { 

            window.open(documentUri, '_blank'); 
            // console.error('Download failed', error);
            // alert('An error occurred while trying to download the file.');
        }
    };

    const handlePreview = () => {
        setShowPreview(true);
    };

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    return (
        <>
            <Row className="row-pad-0">
                <Col md={12} className="bg-white border-radius-5 FileUploadView-wrap mt-10">
                    <div className="file-icon">{icon}</div>
                    <div className="file-wrap mt-10">
                        <span className="action-file">
                            <small className="file-download cursor" onClick={handleDownload}>Download</small> |
                            <small className="file-preview cursor" onClick={handlePreview}>Preview</small>
                        </span>
                    </div>
                </Col>
            </Row>
            <Modal show={showPreview} onHide={handleClosePreview} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Preview {nameext}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocViewer
                        documents={[{ uri: documentUri, fileType: nameext }]}
                        pluginRenderers={DocViewerRenderers}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClosePreview}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FileUploadView;
