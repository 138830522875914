import { Offcanvas,Container,Row,Col ,OverlayTrigger, Tooltip } from "react-bootstrap";

import CriclrDiv from "../common/CriclrDiv";
import ShareCandidateLiveEvaluation from "../common/ShareCandidateLiveEvaluation";
import { CustomRating } from "../form/index";
import CandidateRespones from "../common/CandidateRespones";
import TimeLine from "./TimeLine";
import {ResponesFileds}  from "./field"
import { adduser,crossicon,userclick ,acceptcandidate,rejectecandidate,share,downloadarrowblow,Video} from "../../helper/Icons/Icon";
import {CustomButton} from "../form/index";
import  aryaimg  from "../../assets/images/users.png"
import RatingsTabel from "./RatingsTabel";
import IndividualEvaluation from "./IndividualEvaluation";

import { useState } from "react";
import CommentModel from "../common/CommentModel";
import CustomAlert from "../common/CustomAlert";
const Liveterview=({handleClose,timeLineheader})=>{
  const [showIndividualEvaluation, setShowIndividualEvaluation] = useState(false);
  const    [showApcceptCandidate ,setApcceptCandidate ]=useState(false);
  const    [showRejectCandidate ,setRejectCandidate ]=useState(false);
  const [showShareCandidateLiveEvaluation,setShareCandidateLiveEvaluation]=useState(false);
  const [interviewshowalert,interviewsetinterview]=useState(false);
  const [interviewShowCommentModel,  interviewsetCommentModel]=useState(false);

  const  handleModel=(props)=>{
    console.log(props)
    if(props=="apcceptCandidate"){
      setApcceptCandidate (!showApcceptCandidate)

    }
     else if(props=="ShareCandidateEvaluation"){
      setShowIndividualEvaluation(true);
    }

    else if(props=="interviewCandidateLiveEvaluation"){
      setShareCandidateLiveEvaluation(!showShareCandidateLiveEvaluation);

    }
    else if(props=="interviewdownload"){
      interviewsetinterview(!interviewshowalert);

    }
    else if(props=="rejectCandidate"){
      setRejectCandidate(!showRejectCandidate)

    }
    else if (props=="interviewCommentModel"){
      interviewsetCommentModel(!interviewShowCommentModel);
    }
  }
  const handelCloseModel=(props)=>{
    if(props=="apcceptCandidate"){
      setApcceptCandidate (!showApcceptCandidate)

    }
    else if(props=="rejectCandidate"){
      setRejectCandidate(!showRejectCandidate)

        }
      else if(props=="interviewCandidateLiveEvaluation"){
        setShareCandidateLiveEvaluation(false);
      }
      else if(props=="alert"){
        interviewsetinterview(false);
      }
     else if(props=="interviewComment"){
      interviewsetCommentModel(false);
     }
  }
    return(
    <>
       <Offcanvas show={true} onHide={handleClose}  placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "80%" }}>  
     
  
    
     <Container fluid className="detailpage-wrap pad-0">
     <Offcanvas.Header className="model-header actionlist-header bg-white">
            <Offcanvas.Title><span className="svg mr-10">{Video}</span>Live Interview Details</Offcanvas.Title> 
           <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div> 
          </Offcanvas.Header>
       
        <Col md={12} className="pad-0 recordedinterview-body">
        <Row className="row-pad-0">
          <Col md={8} className="">
          <Row className="row-pad-0 detail-interviewer bg-white">
          <div className="flex-space-between">
                <div className="d-flex ">
                  <h5 className="name" >
                    <span >Candidate Details <small>/Job Namec</small></span>
                 
                  </h5>
                  <CustomButton className="btn-status status-active" text="Interview Submitted"/>
                </div>
                <div>
                <CustomRating/>
                </div>

        </div> 
           <Row className="row-pad-0 custom-row-pad-0">
            <Col md={1} className="custom-md-1 mt-15" >
              <div className="profile">
              <img src={aryaimg } alt="user profile" />
              </div>
            </Col>
            <Col md={11}  className="content pad-0 custom-md-11 mt-10">
              <div className="flex-space-between">
                <div className="d-flex ">
                  <h5 className="name">
                    <span>Marie C.</span>
                 
                  </h5>
             
                </div>
              </div>
              <div className="flex-Xcenter">
                <div className="list-title">
                  <span>Email:</span><label>joseph@live.com</label>
                </div>

              

              
                <div className="list-title">
                  <span>Phone Number:</span><label>+1 866 453 2343</label>
                </div>
               
               
                
              
                </div>
         
            </Col> 
            </Row>
            </Row>
           <Row className="row-pad-0  pad-20  interviewer-responses bg-white mt-20 ">
           <h6 className='pad-0 mt-1'> Meeting Recording</h6>
            <div className=' mt-20 Response-wrap  pad-10'>
                <video width="100%" height="auto" controls>
        <source src="weater-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
     
      </video>
      </div>
          </Row>
          </Col>
          <Col md={4}>
              <Row className="row-pad-0 timeline-wrap">
              <div className="d-flex">
              {timeLineheader.map((item, index) => (<>
        
              
      <OverlayTrigger
        key={index}
        placement="top"
        overlay={<Tooltip id={`tooltip-${index}`}>{item.texttooltip}</Tooltip>}
        trigger={['hover', 'focus']}
      >
        <span>
        <CriclrDiv
          icon={item.icon}
          clrName="timeline-criclr border-header cursor"
          onclick={() => handleModel(item.action)}
        />
        </span>
      </OverlayTrigger>
      </>
    ))}
</div>
                    <TimeLine/>
            </Row>
          </Col>
        </Row>

          <Row className="row-pad-0 rating-tabel-wrap ">
            <RatingsTabel />
          </Row>

          
        </Col>
            </Container>
            {showIndividualEvaluation && <IndividualEvaluation handleClose=""/>}
          
            { showRejectCandidate && <CommentModel icon={rejectecandidate} headertext="Reject Candidate"  handleClose={()=>handelCloseModel("rejectCandidate")}/>}
            {showApcceptCandidate  && <CommentModel icon={acceptcandidate} headertext="Accept Candidate"  handleClose={()=>handelCloseModel("apcceptCandidate")}/>}
            {showShareCandidateLiveEvaluation      &&   <ShareCandidateLiveEvaluation handleClose={()=>handelCloseModel("interviewCandidateLiveEvaluation")}/>}
            { interviewshowalert  &&  <CustomAlert  handleClose={()=>handelCloseModel("alert")}/>}
            {interviewShowCommentModel && <CommentModel icon={userclick} headertext="Mark Candidate as Hired"  handleClose={()=>handelCloseModel("interviewComment")}/>}
      </Offcanvas>

        
     

    </>
    

    
    )
}

export default Liveterview;