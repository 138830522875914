import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Spinner from '../common/Spinner';
import { interviewtemplatetokent } from "../../store/actions/InterviewproceedAction";
import RecordVideo from './RecordVideo';
import OptionsQuestion from './OptionsQuestion';
import ResponseEditor from "./ResponseEditor";
import Fileupload from "./FileUpload";
import InterviewMedia from "./InterviewMedia";
import { CustomButton } from '../form';
import AudioRecorder from './AudioRecorder';
import CriclrDiv from '../common/CriclrDiv';
import { option, sky, Video, time, timer, uploadfile, InterviewMediaicon, videobox, mic, fileupploed, textquestion, ChoiceQuestion } from "../../helper/Icons/Icon";
const icons = { 1: Video, 2: mic, 3: ChoiceQuestion, 4: textquestion, 5: fileupploed }

const InterviewQuestion = () => {
    const { token } = useParams();

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showerror, setErrors] = useState(null)
    const [activeStep, setActiveStep] = useState(0);
    const start_timestamp = new Date();

    const { interviewtemplatetDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    let activeQuestion = interviewtemplatetDetail?.interview_template_questions[activeStep];

    const answers = useSelector((state) => state.recordedVideo.answers);
    let answer = answers[activeStep];

    useEffect(() => {
        if (token) {
            dispatch(interviewtemplatetokent(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });
        }

    }, [dispatch, token]);



    const handleNextQuestion = () => {
        let current = activeStep + 1;
        setActiveStep(current);
    };

    const handlePreviousQuestion = () => {
        if (activeStep > 0) {
            let current = activeStep - 1;
            setActiveStep(current);
        }
    };


    const InterviewProceed = () => {
        // const urlData = {
        //     interview_name: interviewtemplatetDetail.interview_name,
        //     candidateId: location.state.candidateId 
        //     token: token,
        //     jobId: interviewtemplatetDetail?.job_id,
        //     description: interviewtemplatetDetail?.interview_name,
        //     interview_template_description: interviewtemplatetDetail?.interview_template_description,
        //     start_timestamp: start_timestamp,
        // }
        // navigate('/candidate-response', { state: { data: urlData } });
        navigate(`/candidate-response/${token}`);
    };

    return (

        <>
            {loading ? <Spinner /> : null}
            {showerror ? <div className="link-error-wrap">
                <div className="link-error"><h1>{showerror}</h1></div>
            </div> : (
                interviewtemplatetDetail ? (
                    <>

                        <h4>Interview Questions</h4>

                        <Row className="row-pad-0 bg-white border-radius-5 pad-20  questions-wrap">
                            <Col md={12} style={{ width: "96%", marginBottom: "50px" }}>
                                <ul className="steps-numbrs-wid">
                                    {interviewtemplatetDetail?.interview_template_questions && interviewtemplatetDetail?.interview_template_questions.map((question, index) => (
                                        <li key={index} className={index === activeStep ? 'active' : ''}>
                                            <CriclrDiv icon={index + 1} clrName={`number-cricle v-blue-light`} />
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col md={12} className="mt-10 d-flex justify-content-between ">


                                <div className="system-card prevent-select">
                                    <div className="syetem-icon-question">{icons[activeQuestion.question_type_id]}</div>
                                    <div className="system-msg">
                                        <small className="system-text prevent-select">{activeQuestion.question_text}</small>
                                    </div>
                                </div>
                                <div className=" system-card-fixed">
                                    <div className="syetem-icon-question">
                                        {sky}
                                    </div>
                                    <div className="system-msg">
                                        <small className="system-text">Allowed Think Time:</small>
                                        <small className="system-text-span">{activeQuestion.think_time}:00 Mins</small>
                                    </div>
                                </div>

                                {
                                    (activeQuestion.question_type_id === 1 || activeQuestion.question_type_id === 2) &&
                                    <>
                                        < div className="system-card-fixed ">
                                            <div className="syetem-icon-question">
                                                {time}
                                            </div>
                                            <div className="system-msg">
                                                <small className="system-text">Allowed Answer Duration:</small>
                                                <small className="system-text-span">{activeQuestion.response_time}:00 Mins</small>
                                            </div>
                                        </div>
                                        <div className="system-card-fixed ">
                                            <div className="syetem-icon-question">
                                                {timer}
                                            </div>
                                            <div className="system-msg">
                                                <small className="system-text">Retakes:</small>
                                                <small className="system-text-span">{activeQuestion.retakes}/{answer?.retakes ?? 0}</small>
                                            </div>
                                        </div>
                                    </>
                                }

                            </Col>
                            <Row className="row-pad-0">
                                <Col md={6} sm={12} className='pad-0'>
                                    <div className="bg-ligte-gray radius-7 mt-10 mb-20 questions-media-wrap  ">
                                        <div className="media-box pad-10  mt-20">
                                            <h4 className='InterviewMed-svg sub-heading'><span>{InterviewMediaicon}</span>Interview Media</h4>
                                            <InterviewMedia media={activeQuestion.question_media} />
                                        </div>
                                    </div>
                                </Col>

                                <Col md={6} sm={12} className='pad-0'>
                                    <div className="bg-ligte-gray radius-7 mt-20 mb-20 answer-questions-wrap  ">
                                        {activeQuestion.question_type_id === 1 && <RecordVideo question={activeQuestion} answer={answer} activeStep={activeStep} />}
                                        {activeQuestion.question_type_id === 2 && <AudioRecorder question={activeQuestion} answer={answer} activeStep={activeStep} />}
                                        {activeQuestion.question_type_id === 3 && <OptionsQuestion question={activeQuestion} answer={answer} activeStep={activeStep} />}
                                        {activeQuestion.question_type_id === 4 && <ResponseEditor question={activeQuestion} answer={answer} activeStep={activeStep} />}
                                        {activeQuestion.question_type_id === 5 && <Fileupload question={activeQuestion} answer={answer} activeStep={activeStep} />}
                                        {/* Add conditions for other question types here */}
                                    </div>
                                </Col>


                            </Row>
                            <Col md={12} className=" mb-10 text-end pad-0 mt-10">
                                {activeStep > 0 && <CustomButton className="custom-btn-primary mr-10" text="Previous Question" onClick={handlePreviousQuestion} />}

                                {activeStep === interviewtemplatetDetail?.interview_template_questions.length - 1 ? (
                                    <CustomButton
                                        className="custom-btn-primary"
                                        text="Preview Answers"
                                        onClick={InterviewProceed}
                                        //disabled={!answer?.interview_answer}
                                        disabled={!answer?.interview_answer && !activeQuestion.is_optional}
                                    />
                                ) : (
                                    <CustomButton
                                        className="custom-btn-primary mr-10"
                                        text="Next Question"
                                        onClick={() => handleNextQuestion()}
                                        //disabled={!answer?.interview_answer}
                                        disabled={!answer?.interview_answer && !activeQuestion.is_optional}
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Spinner />
                )
            )
            }
        </>
    );
};

export default InterviewQuestion;
