import { Row, Col } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

const SettingLayout = () => {

    return (
        <>
            <Row className="row-pad-0" >
                <Col md={12} className="" >
                    <Row className=" row-pad-0 bg-white radius-5 mb-20 pad-10">
                        <Col md={2} className="template-wrap-nav">
                        <NavLink to="users">Users</NavLink>
                        <NavLink to="billing/plan-overview">Billing</NavLink>
<NavLink to="integration">Integrations</NavLink>
                        </Col>
                    </Row>
                    <Row className="listing-section wrap-accounting-setting  row-pad-0  radius-5 bg-white ">
                        <Outlet />
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default SettingLayout;