import React, { useState } from "react";
import { Table, Col, Row, Form, Button } from "react-bootstrap";
import AddSMSTemplate from "../components/templates/AddSMSTemplate";
import { CustomButton } from "../components/form";
import SmsTemplateList from "../components/templates/SmsTemplateList";
import { sms } from "../helper/Icons/Icon";

const SmsTemplates = () => {

    const [addSmsTemplate, setAddSmsTemplate] = useState(false);

    const handleModel = () => {
        setAddSmsTemplate(!addSmsTemplate)
    }

    return (<>
        <Row className="listing-section row-pad-0 bg-white " >
            <Row className="row-pad-0">
                <Col md={6}>
                    <h5 className="mt-15">SMS </h5>
                </Col>
                <Col className="text-end pad-0 mt-10 mb-10">

                    <CustomButton className="custom-btn-outline ml-10" icon={sms} onClick={() => handleModel("smstemplates")} text="Add SMS Template" />
                </Col>

                <span className="single-border d-block"></span>
            </Row>

            <SmsTemplateList addSmsTemplate={addSmsTemplate} />

            {
                addSmsTemplate && <AddSMSTemplate handleClose={() => handleModel()} />
            }
        </Row>
    </>
    );
};




export default SmsTemplates;