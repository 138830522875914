import { createSlice } from '@reduxjs/toolkit'
import { create, update, getAll, getById, deleteRequest } from '../actions/meetingRequestActions'


const initialState = {
    loading: false,
    meetingRequests: [],
    error: null,
}

const meetingRequest = createSlice({
    name: 'meetingRequest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
                //state.meetingRequests.push(action.payload)
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
                state.meetingRequests = action.payload
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.meetingRequests = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.meetingRequests = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteRequest.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteRequest.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteRequest.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default meetingRequest.reducer