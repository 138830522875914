import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Checkbox from "../form/Checkbox.jsx";
import CustomButton from "../form/CustomButton.jsx";
import Spinner from "../common/Spinner.jsx";
import { jobtoken } from "../../store/actions/InterviewproceedAction.js";
import { updateTimeline } from '../../store/actions/VideoRecordingAction.js';

const JobDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();

    const [showerror, setErrors] = useState(null)
    const { jobDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [aiProcessingAccepted, setAiProcessingAccepted] = useState(false);

    useEffect(() => {
        if (token) {
            dispatch(jobtoken(token)).then((res) => {
                if (res.payload.error) {
                    setErrors(res.payload.error)
                }

            });;
        }

    }, [dispatch]);

    const InterviewProceed = () => {
        const logone = {
            key: `email_delivered`,
            log: `Email delivered`,
            date_time: jobDetail?.email_deleivery_date?.date ?? new Date()
        };
        dispatch(updateTimeline(0, logone));

        const log = {
            key: `landing_page`,
            log: `Visited interview landing page`,
            date_time: new Date()
        };
        dispatch(updateTimeline(1, log));
        // navigate(`/add-candidate`, { state: { token } });
        navigate(`/add-candidate/${token}`);
    };

    return (
        <>
            {loading && <Spinner />}
            {showerror ? <div className="link-error-wrap">
                <div className="link-error"><h1>{showerror}</h1></div>
            </div> : (

                !jobDetail ? <Spinner /> : (
                    <Row className="row-pad-0 mt-1">
                        {/* Job and interview information */}
                        <Col md={6} className="pad-10">
                            <Row className="mt-1 Job-Information-wrap radius-7 row-pad-0 bg-white ml-10 mt-1">
                                <h5 className="heading-5 mt-1">Job Information</h5>
                                <span className="single-border d-block"></span>
                                <div className="Job-Information-content">
                                    <div><label>Job Type:</label><span>{jobDetail.job_type_name}</span></div>
                                    <div><label>Workspace Type:</label><span>{jobDetail.workspace_type_name}</span></div>
                                    <div><label>Job Location:</label><span>{jobDetail.address}</span></div>
                                    <div><label>Pay/Salary:</label><span>{jobDetail.pay_salary}</span></div>
                                    <div><label>Work Authorization:</label><span>{jobDetail.work_authorization_name}</span></div>
                                </div>
                            </Row>
                        </Col>

                        <Col md={6} className="pad-10">
                            <Row className="mt-1 interview-information-wrap radius-7 row-pad-0 bg-white ml-10 mt-1">
                                <h5 className="heading-5 mt-1">Interview Information</h5>
                                <span className="single-border d-block"></span>
                                <div className="interview-information-content">
                                    <div><label>Number of Questions:</label><span>{jobDetail.interview_questions_counts?.total_count}</span></div>
                                    <div><label>Written Questions:</label><span>{jobDetail.interview_questions_counts?.text_count}</span></div>
                                    <div><label>Video Questions:</label><span>{jobDetail.interview_questions_counts?.video_count}</span></div>
                                    <div><label>Audio Question:</label><span>{jobDetail.interview_questions_counts?.audio_count}</span></div>
                                    <div><label>Multiple Choice Questions:</label><span>{jobDetail.interview_questions_counts?.mcqs_count}</span></div>
                                    <div><label>File upload Questions:</label><span>{jobDetail.interview_questions_counts?.upload_count}</span></div>
                                </div>
                            </Row>
                        </Col>

                        {/* Job description */}
                        <Col md={12} className="pad-10">
                            <Row className='mt-1 description-wrap row-pad-0 bg-white mt-1'>
                                <h5 className="heading-5 mt-1">Job Description</h5>
                                <span className='single-border d-block'></span>
                                <Col md={12} className='jobdescription-content'>
                                    <p className="mt-10"><b>Job Summary:</b> {jobDetail.job_description}</p>
                                </Col>
                            </Row>
                        </Col>

                        {/* Disclaimer */}
                        <Col md={12} className="pad-10">
                            <Row className='mt-1 disclaimer-wrap row-pad-0 bg-white mt-1'>
                                <h5 className="heading-5 mt-1">Disclaimer</h5>
                                <span className='single-border d-block'></span>
                                <Col md={12} className='disclaimer-content'>
                                    <p className="mt-10">Please agree to the following terms and conditions before commencing this interview:</p>
                                    <Row className='mt-1 row-pad-0 bg-white mt-1'>
                                        <Checkbox
                                            type="checkbox"
                                            label="During the interview process, your video interview, name, email address, and phone number may be shared with the inviting organization. For inquiries, please reach out to us at care@vidbpm.com"
                                            className="form-check-lg"
                                            checked={termsAccepted}
                                            onChange={(e) => setTermsAccepted(e.target.checked)}
                                        />
                                        <Checkbox
                                            type="checkbox"
                                            label="I acknowledge that my interview data may undergo artificial intelligence processing to generate a transcript, highlight keywords, and similar functions. You can find a comprehensive overview of VidBPM's AI features here."
                                            className="form-check-lg"
                                            checked={aiProcessingAccepted}
                                            onChange={(e) => setAiProcessingAccepted(e.target.checked)}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={12} className="text-end mt-20 mb-12">
                            <CustomButton
                                text="Proceed"
                                className="custom-btn-primary"
                                onClick={InterviewProceed}
                                disabled={!termsAccepted || !aiProcessingAccepted}
                            />
                        </Col>
                    </Row>
                )
            )
            } </>
    );
};

export default JobDetail;

