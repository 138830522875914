import React, { useState, useEffect } from "react";
import { Table, Col, Row, Form, Button } from "react-bootstrap";
import AddEmailTemplate from "../components/templates/AddEmailTemplate";
import { CustomButton } from "../components/form";
import { email } from "../helper/Icons/Icon";
import EmailTemplatesList from "../components/templates/EmailTemplatesList";

const EmailTemplates = () => {
    const [addEmailTemplate, setAddEmailTemplate] = useState(false);
    const [UpdateList, setUpdateList] = useState(false);

    const handleModel = () => {
        setAddEmailTemplate(!addEmailTemplate);
        setUpdateList(!UpdateList)
    }

    return (
        <>
            <Row className="listing-section row-pad-0 bg-white " >
                <Row className="row-pad-0">
                    <Col md={6}>
                        <h5 className="mt-15">Email</h5>
                    </Col>
                    <Col className="text-end pad-0 mt-10 mb-10" >

                        <CustomButton className="custom-btn-outline ml-10" icon={email} onClick={() => handleModel()} text="Add Email Template" />
                    </Col>

                    <span className="single-border d-block"></span>
                </Row> 

                <EmailTemplatesList  updatelist={UpdateList} addEmailTemplate={addEmailTemplate} />

                {addEmailTemplate && <AddEmailTemplate handleClose={() => handleModel()} />}

            </Row>
        </>
    );
};





export default EmailTemplates;