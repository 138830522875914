import {
  share,
  Video,
  view,
  checkbox,
  adduser,
  eye,
  pencil,
  deletclrred,
  JobActive,
  exporticon,
  deletegray,
} from "../../helper/Icons/Icon";
import { useState, useEffect } from "react";
import Statusbtn from "../../components/common/Statusbtn";
import { Table, Form } from "react-bootstrap";
import { CustomRating } from "../../components/form";
import { CustomButton } from "../../components/form/index";
import { Link } from "react-router-dom";
import * as moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';

import EditCandidate from "../candidate/EditCandidate";
import EditInvitationsDetail from "../candidate/EditInvitationsDetail";
import { getAll, deleteCandidateInvitation } from "../../store/actions/candidateInvitationActions";
import { deleteCandidate } from "../../store/actions/candidateActions";
import Interview from "../RecordedInterviewDetails/Interview";
import ShareCandidate from "../common/ShareCandidate";
const Candidates = ({ invitations, invitationsLoading, setDeletej, deletej, onChange, searchField }) => {
  const dispatch = useDispatch();

  const [showCandidateEdit, setCandidateEdit] = useState(false);
  const [invitationId, setInvitationId] = useState()
  const [showInterview, setshowInterview] = useState(false)

  const [candidateId, setcandidateId] = useState()
  const [ShowShareCandidate, setShareCandidate] = useState(false)
  const [jobId, setJobId] = useState()

  const handleDelete = (candidateInvitationId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure to delete the selected item(s).',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCandidate(candidateInvitationId))
          .then((res) => {



            Swal.fire({
              icon: 'success',
              title: 'Deleted',
              text: 'Item(s) has been deleted.',
              showConfirmButton: false,
              timer: 1000
            })
            setDeletej(!deletej)
          });
      }
    });

  };

  const handelModel = (type, id, candidateId) => {


    if (type == "editCandidate") {
      setInvitationId(id)

      setCandidateEdit(true);

      setDeletej(!deletej)

    }
    if (type == "Interview") {
      setInvitationId(id)
      setcandidateId(candidateId)
      setshowInterview(true);
    }

  }
  const handeShareCandidate = (id) => {
    setJobId(id)
    setShareCandidate(!ShowShareCandidate)


  }
  const handelCloseModel = (type) => {

    setInvitationId(null)
    if (type == "editCandidate") {


      setCandidateEdit(false);

      setDeletej(!deletej)

    }
    if (type == "Interview") {


      setshowInterview(false);
    }

  }
  return (
    <>

      <Table className="table" style={{ width: "1200px" }}>
        <thead>
          <tr>
            <th style={{ minWidth: "100px", width: "140px" }}>
              <span>Quick Action</span>
            </th>
            <th style={{ minWidth: "100px", width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Candidate"
                name="candidate_name"
                value={searchField.candidate_name || ''}
                onChange={onChange}
              />
            </th>
            <th style={{ minWidth: "100px", width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Email"
                name="email"
                value={searchField.email || ''}
                onChange={onChange}
              />
            </th>
            <th style={{ minWidth: "100px", width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="phone"
                value={searchField.phone || ''}
                onChange={onChange}
              />
            </th>
            <th style={{ minWidth: "100px", width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Job Title"

                name="job_name"
                value={searchField.job_name || ''}
                onChange={onChange}
              />
            </th>
            <th style={{ minWidth: "100px", width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Status"
                name="status"
                value={searchField.status || ''}
                onChange={onChange}
              />
            </th>
            <th style={{ minWidth: "100px", width: "200px" }}>
              <Form.Control
                type="text"
                placeholder="Invited AT"
                value={searchField.invited_at || ''}
                onChange={onChange}
              />
            </th>
          </tr>
        </thead>

        <tbody className="tabel-body">

          {invitations &&
            (invitations.length === 0 ? (

              <tr>
                <td colSpan="7" className="text-center">     {invitationsLoading ? <div className=""><span><Spinner /></span></div> : <h6>No record found</h6>}</td>



              </tr>) : (
              invitations.map(invitation =>
              (

                <tr>
                  <td>
                    <div className="quick-action">
                      {invitation?.candidate_invitation_id &&
                        <span className="cursor" onClick={() => handelModel("Interview", invitation?.job?.job_id, invitation?.candidate_id)}>
                          {Video}
                          {/* {invitation?.candidate?.candidate_id}{invitation?.job?.jobId} */}
                        </span>
                      }
                      {invitation?.candidate_id && <span className="cursor" onClick={() => handelModel("editCandidate", invitation?.candidate_id)}>{pencil}</span>}
                      {invitation?.candidate_id && <span className="cursor" onClick={() => handleDelete(invitation?.candidate_id)}>{deletclrred}</span>}
                      {invitation?.candidate_id ? <span className="cursor" onClick={() => handeShareCandidate(invitation?.job?.jobId)}>{share}</span> : null}
                      {invitation?.resume && <span className="cursor">{view}</span>}
                    </div>
                  </td>
                  <td>
                    <a href="#">{invitation?.name} </a>
                  </td>
                  <td>{invitation?.email_address}</td>
                  <td>{invitation?.phone}</td>
                  <td> <a href="#">{invitation?.job?.job_title}</a></td>
                  <td>
                    <Statusbtn
                      className={`${invitation?.stage?.stage_name} status`}
                      text={invitation?.stage?.stage_name}
                    />
                  </td>
                  <td>{moment(invitation?.created_date).format('DD-MM-YYYY h:mm A')}</td>

                </tr>
              )
              ))
            )
          }

        </tbody>
      </Table>

      {showCandidateEdit && <EditCandidate candidateId={invitationId} handleClose={() => handelCloseModel("editCandidate")} />}
      {showInterview && <Interview handleClose={() => handelCloseModel("Interview")} jobid={invitationId} candidateId={candidateId} />}
      {ShowShareCandidate && <ShareCandidate jobId={jobId} handleClose={handeShareCandidate} />}

    </>
  );
};

export default Candidates;
