import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Textarea ,Label,CustomButton,CustomRating} from '../components/form';

import StarRating from 'react-rating-stars-component';
import { useDispatch } from 'react-redux';

import { getInterviewEvaluation,create } from "../store/actions/interviewEvaluatorAction";
import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1000
    });
};

const EvaluateComment = ({ selectedAnswer,token,interviewId }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        evaluator_rating: 0,
        evaluator_remarks: ''
    });

    const [errors, setErrors] = useState({
        evaluator_rating: '',
        evaluator_remarks: ''
    });

    const validate = () => {
        let isValid = true;
        let newErrors = {
            evaluator_rating: '',
            evaluator_remarks: ''
        };

        if (formData.evaluator_remarks.trim() === '') {
            newErrors.evaluator_remarks = 'Comments cannot be empty';
            isValid = false;
        }

        if (formData.evaluator_rating <= 0) {
            newErrors.evaluator_rating = 'Rating must be greater than zero';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : 'Field is required'
        }));
    };

    const handleRating = (value) => {
        console.log('Handling rating change:', value); 

        setFormData(prevState => ({
            ...prevState,
            evaluator_rating: value
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            evaluator_rating: value > 0 ? '' : 'Rating must be greater than zero'
        }));
    };

    const handleSave = () => {
        if (!validate()) return;

        if (!selectedAnswer?.question_id) {
            sweetAlert('error', 'Error!', 'Answer ID is missing!');
            return;
        }

        const payload = {
            "interview_id": interviewId,
            "evaluator_remarks": formData.evaluator_remarks,
            "evaluator_rating": formData.evaluator_rating,
            "interview_question_id": selectedAnswer.question_id,
            "evaluation_status": "Passed",          
            "interview_answer_id": 123,
            "link_expiry_date": "2024-07-10",
            "show_feedback_candidate": true
        };
        if(token){
            dispatch(create({token,payload}))
            .then((res) => {
                if(res.payload.error){
                    sweetAlert(
                        "error",
                        "Error!",
                      
                        res.payload.error
                    );
                }
                 else{
                    sweetAlert(
                        "success",
                        "Success!",
                        res.message
                    );
    
    
                  
                  
                 }
             
                
            })
            .catch(() => {
                sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                );
            });
    
          }
       
    };

    return (
        <>
        <Row  className='feedback-wrap mt-20 mb-10 row-pad-0 pad-0'>
                        <Col md={6} className='pad-0' >
                        <Label text="Internal Comments:"/>
                        
                        < Textarea
                          style={{ maxHeight: '52px' }}
                    as="textarea"
                  
                    className="feedback "
                    placeholder= {selectedAnswer?.answer_comments}      />
                        
                        </Col>
                        <Col md={6} className='pl-10'>
                    
                        <Label text="Internal Rating:"/>
                        <div className='feedback pad-10'>
                        <CustomRating value={selectedAnswer?.answer_rating}/>
                        </div>
                        </Col>
                    </Row>
            <Row className='feedback-wrap mb-20 row-pad-0 pad-0 mt-10'>
                <Col md={6} className='pad-0'>
                    <Label text="Enter Comments:" />
                    <Textarea
                     style={{ maxHeight: '52px' }}
                        as="textarea"
                        rows={3}
                        onChange={handleChange}
                        name='evaluator_remarks'
                        className="feedback"
                        placeholder=""
                        value={formData.evaluator_remarks}
                    />
                    {errors.evaluator_remarks && <span className="text-danger">{errors.evaluator_remarks}</span>}
                </Col>
                <Col md={6} className='pl-10'>
                    <Label text="Rate Response:" />
                    <div className='feedback pad-10'>
                        <StarRating
                            value={formData.evaluator_rating}
                            count={5}
                            size={20}
                            name="evaluator_rating"
                            onChange={handleRating}
                            activeColor="#FFD700"
                        />
                    </div>
                    {errors.evaluator_rating && <span className="text-danger">{errors.evaluator_rating}</span>}
                </Col>
            </Row>
            <Row className='mb-20 row-pad-0 pad-0'>
                <Col md={12} className="text-end">
                    <CustomButton text="Cancel" className="custom-btn-outline mr-10" />
                    <CustomButton text="Save" className="custom-btn-primary" onClick={handleSave} />
                </Col>
            </Row>
        </>
    );
};

export default EvaluateComment;
