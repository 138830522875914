import React, { useEffect, useRef, useState } from 'react';
import CriclrDiv from "../common/CriclrDiv";
import { Button, Col, Row } from "react-bootstrap";
import InterviewMedia from "./InterviewMedia";
import RecordVideo from './RecordVideo';
import OptionsQuestion from './OptionsQuestion';
import ResponseEditor from './ResponseEditor';
import FileUploadView from '../common/FileUploadView';
import { sky, Video, timer, uploadfile, InterviewMediaicon } from "../../helper/Icons/Icon";
import { CustomButton } from '../form';
import { useDispatch, useSelector } from "react-redux";

const InterviewQuestions1 = ({ Proceedaction }) => {
 const videoRef=useRef();
    const [activeStep, setActiveStep] = useState(1); // Initialize with 0 to show the first question
    const { interviewtemplatetDetail } = useSelector((state) => state.Interviewproceed);
    const dispatch = useDispatch();

    const handleNextQuestion = () => {
    
        if (activeStep <= 5) {
            setActiveStep(activeStep + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1);
        }
    };
    


    return (
        <>
            <h4>Interview Questions </h4>
            <Row className="row-pad-0 bg-white border-radius-5 pad-20">
                <Col md={12} style={{ width: "96%", marginBottom: "80px" }}>
                    <ul className="steps-numbrs-wid">
                        {[1,2,3,4,5].map((label, index) => (
                            <li key={index} className={label=== activeStep ? 'active' : ''}>
                             
                                <CriclrDiv icon={label } clrName={`number-cricle v-blue-light`} />
                            </li>
                        ))}
                    </ul>
                </Col>
                <Col md={12} className="mt-10 d-flex justify-content-between">
                    {interviewtemplatetDetail.questions.map((question, index) => (
                        question.question_type_id === activeStep && (
                            <React.Fragment key={index}>
                                <div className="system-card prevent-select">
                                    <div className="syetem-icon">{Video}</div>
                                    <div className="system-msg prevent-select">
                                        <small className="system-text prevent-select">{question.question_text}</small>
                                    </div>
                                </div>
                                <div className="system-card ">
                                    <div className="syetem-icon">
                                        {sky}
                                    </div>
                                    <div className="system-msg">
                                        <small className="system-text">Allowed Think Time:</small>
                                        <small className="system-text">{question.think_time}</small>
                                    </div>
                                </div>
                                <div className="system-card ">
                                    <div className="syetem-icon">
                                        {timer}
                                    </div>
                                    <div className="system-msg">
                                        <small className="system-text">Allowed Answer Duration:</small>
                                        <small className="system-text">{question.response_time}</small>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    ))}
                </Col>
                <Row className="row-pad-0">
                    <Col md={6} className="">
                        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray mt-15">
                            <h4 className='heading-h4 m-0'>
                                <span className='mr-10'>{InterviewMediaicon}</span>Interview Media
                            </h4>
                            <InterviewMedia />
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-ligte-gray mt-15">
                            {interviewtemplatetDetail.questions.map((question, index) => (
                                question.question_type_id  === activeStep && (
                                    <React.Fragment key={index}>
                                        {activeStep === 1 && <RecordVideo  />}
                                        {activeStep === 2 && <RecordVideo  />}
                                        {activeStep===3 && <OptionsQuestion options={question} />}
                                        {activeStep === 4  && <ResponseEditor options={question}  />}
                                        {activeStep=== 5 && (
                                            <div>
                                                <h4>Upload File</h4>
                                                <Row className="row-pad-0">
                                                    <Col md={12} className="bg-white border-radius-5 FileUploadView-wrap mt-10 ">
                                                        <div className="file-icon">{uploadfile}</div>
                                                        <div className="file-wrap mt-10">
                                                            <CustomButton text="Upload File" className="custom-btn-outline mb-10" />
                                                            <span className="file-name"><small>Allowed Extension: </small> .docx,.doc,pdf,.txt </span>
                                                            <span className="file-size"><small>Allowed Limit: </small>5MB</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Col md={12} className=" mb-10 text-end pad-0 mt-10">
                    {activeStep > 1 && <CustomButton className="custom-btn-primary mr-10" text="Previous Question" onClick={handlePreviousQuestion} />}
                    {activeStep < interviewtemplatetDetail.questions.length && <CustomButton className="custom-btn-primary mr-10" text="Next Question" onClick={handleNextQuestion} />}
                    {activeStep === interviewtemplatetDetail.questions.length && <CustomButton className="custom-btn-primary" text="Preview Answers" onClick={() => Proceedaction("PreviewInterview")} />}
                </Col>
            </Row>
        </>
    )
}

export default InterviewQuestions1;
