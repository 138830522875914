import { createSlice } from '@reduxjs/toolkit'
import {getAll,getAllRecordedInterview,commentCreateHired,commentCreatecandRejected,addAnswerRating} from '../actions/recorderInterviewAction';


const initialState = {
    loading: false,
    recorderInterview:null,
    RecordedInterviewDetail:null,
    error: null,
   

}

const recorderInterviewSlices = createSlice({
    name: 'job',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
           
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.recorderInterview = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })    .addCase(getAllRecordedInterview.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAllRecordedInterview.fulfilled, (state, action) => {
                state.loading = false
                state.RecordedInterviewDetail = action.payload
            })
            .addCase(getAllRecordedInterview.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }) .addCase(commentCreateHired.pending, (state, action) => {
                state.loading = true
            })
            .addCase(commentCreateHired.fulfilled, (state, action) => {
                state.loading = false
              
            })
            .addCase(commentCreateHired.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(commentCreatecandRejected.pending, (state, action) => {
                state.loading = true
            })
            .addCase(commentCreatecandRejected.fulfilled, (state, action) => {
                state.loading = false
              
            })
            .addCase(commentCreatecandRejected.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(addAnswerRating.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addAnswerRating.fulfilled, (state, action) => {
                state.loading = false
              
            })
            .addCase(addAnswerRating.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            
            


    },
})

export default recorderInterviewSlices.reducer