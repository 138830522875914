
import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { share, adduser, eye, pencil, deletclrred, downloadarrowblow } from "../../helper/Icons/Icon";

import { Form, Button, Row, Col } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { getByName } from "../../store/actions/userPrefrenceActions";
import { getPackagesAll, downloadInvoice } from "../../store/actions/accountBillingAction";
import { useDispatch, useSelector } from 'react-redux';

import * as moment from 'moment'
import Tabelcolumn from "../common/Tabelcolumn";
import Paginate from "../common/Paginate";
import { CustomButton } from "../form";

import Swal from "sweetalert2";


const headers = [
    { key: 'invoice_date', title: 'Invoice Date' },
    { key: 'purchase_type', title: 'Purchase Type' },
    { key: 'package_purchase_id', title: 'Invoice ID' },
    { key: 'payment_status', title: 'Status' },
    { key: 'due_date', title: 'Due Date' },
    { key: 'due_paid', title: 'Due Paid' },
    { key: 'amount', title: 'amount' }
];

const BillingList = () => {

    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [searchField, setSearchField] = useState({});

    const prefrenceName = "users";
    const { userPrefrenceDetail } = useSelector((state) => state.userPrefrence);
    const { allPackage, loading, error } = useSelector((state) => state.accountBilling);
    const billingList = allPackage?.data;

    let pages = allPackage?.pages;
    let totalPages = allPackage?.total_page;
    let totalRecords = allPackage?.total_record;

    useEffect(() => {
        dispatch(getByName(prefrenceName));
    }, [dispatch])

    useEffect(() => {
        if (userPrefrenceDetail?.preference_value?.record_per_page) {
            setPerPage(userPrefrenceDetail.preference_value.record_per_page);
        }
    }, [userPrefrenceDetail]);



    useEffect(() => {
        if (perPage) {
            let payload = {
                'limit': perPage, 'page': currentPage, search: searchField
            }

            dispatch(getPackagesAll(payload));
        }


    }, [dispatch, currentPage, perPage, searchField]);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerPage = (perPage) => {
        setPerPage(perPage);
    };



    const handleFieldSearch = (e) => {
        const { name, value } = e.target;
        searchField[name] = value;
        setSearchField({ ...searchField })

    };
    const invoiceDownload = (packagePurchaseId) => {
        dispatch(downloadInvoice(packagePurchaseId))
            .then((res) => {

                const byteCharacters = atob(res.payload);
                const byteNumbers = new Array(byteCharacters.length);


                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);


                const blob = new Blob([byteArray], { type: 'application/pdf' });


                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'invoice.pdf';
                link.click();


                URL.revokeObjectURL(link.href);






                Swal.fire({
                    icon: 'success',
                    title: 'Invoice Downloaded',
                    text: 'Invoice downloaded successfully.',
                    showConfirmButton: false,
                    timer: 3000
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: `Failed to download invoice: ${error.message}`,
                });
            });
    };

    return (
        <>
            {loading &&
                <Spinner />
            }


            <Row className="listing-section billing-list row-pad-0" >



                <Table className="table mt-20">
                    <thead>
                        <tr>


                            <th style={{ width: "120px" }} className="th-text">Download
                                Invoice</th>

                            {headers &&
                                headers.map((header, index) => {

                                    return (
                                        <th key={index} style={{ minWidth: "100px", width: "200px" }}>
                                            <Form.Control name={header.key} type="text" placeholder={header.title} onChange={handleFieldSearch} />
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {billingList && billingList.map((label, index) => (
                            <tr key={index}>
                                < Tabelcolumn className="td-icon" icon={downloadarrowblow} onClick={() => invoiceDownload(label.package_purchase_id)} Tooltip="Download Invoice" />

                                <td className="td-text">{moment(label.created_date).format('DD-MM-YYYY h:mm A')}</td>
                                <td className="td-text">{label.purchase_type}</td>
                                <td className="td-text">{label.package_purchase_id}</td>
                                <td className="td-text">
                                    <CustomButton
                                        className={`status-${label.payment_status} status`}
                                        text={label.payment_status}
                                    />
                                </td>
                                <td className="td-text">{moment(label.expiry_date).format('DD-MM-YYYY h:mm A')}</td>
                                <td className="td-text">{moment(label.payment_date).format('DD-MM-YYYY h:mm A')}</td>
                                <td className="td-text">$ {label.amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Paginate
                    pages={pages}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    totalRecords={totalRecords}
                    handlePageChange={handlePageChange}
                    handlePerPage={handlePerPage}
                />

            </Row>

        </>
    );
};



export default BillingList;
