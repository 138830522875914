
import { useState } from "react"
import { CustomButton, InputField,Switch  } from "../form/index"
import { adduser ,crossicon,faplusicon,faminusicon,fileupploed,btnplus,expirtime,Video,copyicon,ringcopy,readcopy,textquestion,mcqQuestion} from "../../helper/Icons/Icon"
import { Container ,Row,Col, Offcanvas,} from "react-bootstrap"
import Textarea from "../form/Textarea"

import { questionSettingsfiled } from "./fileds"

const ShareCandidateLiveEvaluation=({ handleClose})=>{
    const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [expireDays, setExpireDays] = useState(0);
  const [inputFields, setInputFields] = useState([{ emailAddress: '', name: '', phoneNumber: '' }]);

  const handleIncrement = () => {
    setExpireDays(expireDays + 1);
  };

  const handleDecrement = () => {
    if (expireDays > 1) {
      setExpireDays(expireDays - 1);
    }
  };
  const handleChange1 = () => {
    setIsChecked1(!isChecked1); 
  };

  const handleChange2 = () => {
    setIsChecked2(!isChecked2);
  };


  const handleAddFields = () => {
    setInputFields([...inputFields, { emailAddress: '', name: ''}]);
  };

  const handleRemoveFields = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFields = [...inputFields];
    updatedFields[index][name] = value;
    setInputFields(updatedFields);
  };



    return(<>

     <Offcanvas
        show={true}
        onHide={handleClose}
        placement="end"
        className="bg-ligte-gray custom-offcanvas"
        style={{ width: "50%" }}
      >  

<Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
<Offcanvas.Header className="model-header bg-white  actionlist-header">
                            <Offcanvas.Title>
                            <span className="model-header-svg">{adduser}Share Candidate's Live Interview for Evaluation</span></Offcanvas.Title> 
           <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div> 
          </Offcanvas.Header>

    <Offcanvas.Body className="mt-10 wrap InviteCandidate-wrap">
    <Row className="row-pad-0  InviteCandidatebody bg-white pad-20">
    {inputFields.map((field, index) => (
        <Col key={index} md={12} className="d-flex InviteCandidate-addinput mb-10 pad-0">
          <InputField
            type="email"
            className="input"
            placeholder="Enter email address"
            name="emailAddress"
            id="emailAddress"
            value={field.emailAddress}
            onChange={(e) => handleInputChange(index, e)}
          />
          <InputField
            type="text"
            placeholder="Enter name"
            name="name"
            id="name"
            value={field.name}
            onChange={(e) => handleInputChange(index, e)}
          />
      
         <span onClick={() => handleRemoveFields(index)}>{crossicon}</span>
        </Col>
      ))}
      <Col md={12} className="text-end mt-15 mr-15">
        <CustomButton text="Add More" variant="outline-primary" onClick={handleAddFields} />
      </Col>
        <Col md={12} className="InviteCandidate-file FileUploadView-wrap mt-10 ">
            <div>{fileupploed}</div>
            <div className="file-wrap mt-10">
                <span className="file-name"><b>Upload Emails (Allowed format: .csv, .xlx, .xlsx)</b></span>
                <span >Upload a file containing names, emails and numbers.</span>
                <span className=" action-file"><small className="file-donwnload">Download Template</small></span>
            </div>
        </Col>
   
      
    </Row>


    <Row className="row-pad-0 bg-white mt-20 share-setting-wrap">
       
   <Col md={12} className="pad-0">
   <h4 className="mb-10">Share Settings</h4>
   <h5>Main Settings</h5>
   <Row className="row-pad-0"> 
  <Col md={12} className="main-setting-card">
      <div className="link mt-10">
        <div className="puls-minus">
          <span className="mr-10">{expirtime}</span>
          <span>Expire Link After</span>
          <CustomButton icon={faminusicon} className="btn-default btn-mius" onClick={handleDecrement} />
          <InputField type="text" value={expireDays} readOnly />
          <CustomButton className="btn-default btn-puls" icon={faplusicon} onClick={handleIncrement} />
          <span className="mr-10">Days</span>
        </div>
        <Switch checked={isChecked1} onChange={handleChange1} />
      </div>
      <div className="link mt-20 mb-10">
        <div>
          <span className="mr-10">{btnplus}</span>
          <span>Include company landing page</span>
        </div>
        <Switch checked={isChecked2} onChange={handleChange2} />
      </div>
    </Col>
   </Row>

   {/* <Col md={12} className="mt-20 pad-0">
   <h4>Question Settings</h4>
   <Row className="row-pad-0 question-settings mt-10"> 
   {questionSettingsfiled.map((setting, index) => (
        <div key={index} className="question-settings-body mt-20 mb-10">
          <div>
            <span className="mr-10">{setting.icon}</span>
            <span>{setting.text}</span>
          </div>
          <Switch checked={isChecked[setting.question]} onChange={() => handleChange(setting.question)} />
        </div>
      ))}


    
    </Row>
   */}
    <Col md={12} className="mt-20 pad-0">
    <h4>Add a Message</h4>  
    <Row className="row-pad-0 add-message mt-10"> 
    <div className="pad-20">
        <Textarea
         as="textarea" placeholder="Add a personal for the ones reviewing this candiate"
         
        />
    </div>
    </Row>
     </Col>
   
    
   </Col>

  
    </Row>
    <Col md={12} className="text-end mt-20 mb-20 pad-0">
   <CustomButton variant="outline-primary" className="mr-10" text="Cancel" onClick={handleClose}/>
   <CustomButton text="Share " />
   </Col>
    </Offcanvas.Body>
    </Container>
</Offcanvas>


    </>)
}


export default ShareCandidateLiveEvaluation ;