import React, { useEffect, useState ,useNavigate} from "react";
import { useDispatch ,useSelector} from "react-redux";
import { Row,Col, Container } from "react-bootstrap";
import { Video, hands } from "../../helper/Icons/Icon";
import InterviewMedia from "./InterviewMedia";
import { CustomButton } from "../form";
import { interviewtemplatetokent} from "../../store/actions/InterviewproceedAction";
import Spinner from "../common/Spinner";

const StartInterview=({Proceedaction,token})=>{
    const dispatch =useDispatch()


    useEffect(()=>{
      
      dispatch(interviewtemplatetokent(token))
    },[dispatch])
    const {   interviewtemplatetDetail,loading,error}=useSelector((state) => state.Interviewproceed);
   
    return(<>
{loading && <Spinner/>}
<Col md={12} className="pad-0 startInterview-content">
      <Container fluid className="pad-20" >
    <Row className="row-pad-0 bg-white  pad-20">

    <Col md={12} className=" mt-10 d-flex justify-content-between">
    <div className="system-card ">
        <div  className="syetem-icon">
           {hands}
     </div>
        <div className="system-msg question-text">
            <small className="system-text">{ interviewtemplatetDetail?.intro_message}</small>
         
        </div> 

</div>  
    </Col>

    </Row>
    <Row className="row-pad-0 bg-white radius-7 pad-10 mt-10 ">
       <div className="bg-ligte-gray  radius-7 mt-20 mb-20">
        <div className="media-box ml-15 mt-10">
        <InterviewMedia/>
     
        </div>
        </div>

    </Row>

    <Col md={12} className="text-end mt-20 mb-10"><CustomButton className="custom-btn-primary" onClick={()=>Proceedaction("InterviewQuestions")} text="Proceed"/></Col>
   </Container>
   </Col>
    </>)
}
export default StartInterview;