import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Button, Row, Col, Form, Offcanvas } from "react-bootstrap";
import Analytics from "../common/Analytics";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useNavigate } from "react-router-dom";
import { CompanyLinks } from "./Field";
import { link, setting, eye, JobActive, crossicon, camera, faappointment, facontact, companyHeaderIcon } from "../../helper/Icons/Icon";
import { Switch, CustomClosebtn, CustomButton, Label, InputField, SelectField, } from "../form/index";
import Spinner from "../common/Spinner";
import { update, getById, UplodedFiles } from '../../store/actions/companyActions'
import { useDispatch, useSelector } from 'react-redux';
import Industries from '../common/Industries';
import Countries from '../common/Countries';
import States from '../common/States';
import Swal from 'sweetalert2';
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import Culture from "./Culture";
import TextEditor from "../common/TextEditor";
const config = require('../../config/config.json');

const EditCompany = ({ companyId, handleClose }) => {
    const dispatch = useDispatch();
    const [logoRequired, setLogoRequired] = useState(true)
    const fileInputRef = useRef(null);
    const bgimg = useRef(null);
    const [formData, setFormData] = useState({});
    const [formSate, setFormSate] = useState(null);
    const [contentEditor, setContentEditor] = useState('');
    const [cultureImgs, setcultureImgs] = useState([]);
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedbgImage, setSelectedbgImage] = useState(null);
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [headerFooterColor, setHeaderFooterColor] = useState("");
    const [showbuttonColor, setButtonColor] = useState("");
    const [buttonTextColor, setButtonTextColor] = useState("");
    const [image, setImage] = useState(false);
    const [image1, setImage1] = useState(false);
    const { companyDetail, loading, error } = useSelector((state) => state.company);

    useEffect(() => {
        dispatch(getById(companyId));
    }, [dispatch])
    useLayoutEffect(() => {
        if (companyDetail) {
            setFormData({
                company_banner: companyDetail?.company_banner,
                company_logo: companyDetail?.company_logo,
                company_name: companyDetail?.company_name,
                industry_id: companyDetail?.industry?.id,
                city: companyDetail?.city,
                state_name: companyDetail?.state_name,
                zip_code: companyDetail?.zip_code,
                country_name: companyDetail?.country_name,
                company_size: companyDetail?.company_size,
                website_url: companyDetail.website_url,
                linkedin_url: companyDetail?.linkedin_url,
                facebook_url: companyDetail?.facebook_url,
                twitter_url: companyDetail.twitter_url,
                company_description: companyDetail?.company_description,
                settings: companyDetail.company_landing_page_settings,
                header_footer_colour_code: companyDetail?.header_footer_colour_code,
                button_colour_code: companyDetail.button_colour_code,
                culture_images: companyDetail?.culture_images,
                landing_page_settings: companyDetail?.landing_page_settings || {}
            });
            setFormSate(companyDetail?.state_name);
            setSelectedImage(companyDetail.company_logo);
            setSelectedbgImage(companyDetail.company_banner);
            setHeaderFooterColor(companyDetail.header_footer_colour_code);
            setButtonColor(companyDetail.button_colour_code);
            setButtonTextColor(companyDetail?.button_text_color_code);
            setContentEditor(companyDetail.company_description);

        }
    }, [companyDetail]);

    const handleColorChange = (event) => {
        setHeaderFooterColor(event.target.value);

    }

    const handleButtonColorChange = (event) => {
        setButtonColor(event.target.value);
    }

    const handleButtonTextColorChange = (event) => {
        setButtonTextColor(event.target.value);
    }


    useEffect(() => {
        const updatedData = { ...formData, culture_images: cultureImgs };
        setFormData(updatedData);
    }, [cultureImgs]);

    const handleImageUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {

        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file)

            setImage1(true)
        }



    };

    const setUrlVal = (field_name) => {
        if (field_name == "website_url")
            return companyDetail?.website_url;
        else if (field_name == "linkedin_url")
            return companyDetail?.linkedin_url;
        else if (field_name == "facebook_url")
            return companyDetail?.facebook_url;
        else if (field_name == "twitter_url")
            return companyDetail?.twitter_url;
    }

    // working on background img set
    const handlebgImageUpload = () => {
        bgimg.current.click();
    };

    const handlebgFileChange = (e) => {
        const file = e.target.files[0];


        if (file) {
            setSelectedbgImage(file);
            setImage(true);
        }

    };
    useEffect(() => {
        if (image) {
            const payload = {
                file: selectedbgImage,
                moduleName: "company"

            }
            dispatch(UplodedFiles(payload))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", res.payload.error);
                    } else {
                        setSelectedbgImage(res.payload.data[0].path);
                        setFormData(prevData => ({
                            ...prevData,
                            company_banner: res.payload.data[0].path
                        }));
                        setImage(false);
                    }
                })
                .catch(() => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    }, [image]);
    useEffect(() => {
        if (image1) {
            const payload = {
                file: selectedImage,
                moduleName: "company"

            }
            dispatch(UplodedFiles(payload))
                .then((res) => {

                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        );
                    } else {

                        setSelectedImage(res.payload.data[0].path);
                        setFormData(prevData => ({
                            ...prevData,
                            company_logo: res.payload.data[0].path
                        }));
                        setImage1(false)


                    }
                })
                .catch(error => {

                    sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    );
                });
        }
    }, [image1]);
    const pageSection = () => {
        setShow(true)

    };
    const pageSectionClose = () => {
        setShow(false)

    }
    // const viewCompany = () => {
    //     window.open('/preview-company', '_blank');
    // };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 1000
        });
    }
    const handleChange = (e) => {
        const { name, value } = e.target;


        setFormData({
            ...formData,
            [name]: value
        });
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));


    }
    const handleSearChange = (name, value) => {
        setFormSate(null)

        setFormData({
            ...formData,
            [name]: value
        });



        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));




    };

    const handelSwitch = (e) => {
        const { name, checked } = e.target;




        const updatedData = {
            ...formData,
            landing_page_settings: {
                ...formData.landing_page_settings,
                [name]: checked
            }
        };

        setFormData(updatedData);

    }


    const handleSubmit = (e) => {
        e.preventDefault();

        formData['header_footer_colour_code'] = headerFooterColor;
        formData['button_colour_code'] = showbuttonColor;
        formData["button_text_color_code"] = buttonTextColor;
        formData["company_description"] = contentEditor;


        var formDataFields = formData;

        if (selectedImage === null || (!formDataFields.company_name && !formDataFields.company_name.trim() === '')) {
            setLogoRequired(false)
            setErrors(prevErrors => ({
                ...prevErrors,
                company_name: 'Company name is required'
            }));
        }
        else {


            dispatch(update({ companyId, formDataFields }))




                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert(
                            "error",
                            "Error!",
                            `${res.payload.error}!`
                        );
                    }
                    else {
                        handleClose();
                        return sweetAlert(
                            "success",
                            "Success!",
                            `${res.payload.message}!`,
                        );
                    }
                })
                .catch(error => {

                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    )
                });
        }




    };



    const copyHandle = (text) => {
        navigator.clipboard.writeText(text);
        sweetAlert("success", "Copied!", "The link has been copied to your clipboard.");
    };


    return (
        <>

            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "80%" }}
            >
                {loading &&
                    <Spinner />
                }
                <Offcanvas.Header className="model-header svg-clr bg-white pad-10 ">
                    <Offcanvas.Title>
                        <span className="svg-clr">{companyHeaderIcon} Edit Company Landing Page</span>
                    </Offcanvas.Title>
                    <div className="text-end">
                        <CustomButton
                            className="custom-btn-primary svg-header svg-white cursor h-30"
                            Tooltip="Company copied to clipboard!"
                            icon={link}
                            dir="bottom"
                            onClick={() => copyHandle(companyDetail?.company_url)}
                        />
                        {/* <CustomButton
                            onClick={() => viewCompany()}
                            className="svg-header-outline custom-btn-outline h-30"
                            icon={eye}

                            text="Preview Landing Page"
                        /> */}
                        {/* <span className="ml-10 cursor">{link}</span> */}
                        <CustomButton
                            className="custom-btn-primary svg-header  h-30"
                            Tooltip="Setting"
                            dir="bottom"
                            icon={setting}



                            onClick={() => pageSection()}
                        />
                        <CustomButton
                            onClick={handleClose}
                            className="text-end close-btn "
                            text={crossicon}
                        />
                    </div>
                </Offcanvas.Header>


                <Offcanvas.Body className="mt-10">

                    <Form>
                        <Row md={12} className="row-pad-0 p-relative add-landingvideo-warp bg-white" style={{ backgroundImage: `url(${config.API_BASE_URL_PUBLIC}/${selectedbgImage})` }}>
                            <Col
                                md={12}
                                className=" d-flex justify-content-center align-items-center"
                            >
                                <div className="add-landing-page-video">
                                    <div>
                                        {selectedbgImage ? (
                                            <Button className=" editBtn-bg bg-landingPage-editBtn custom-btn-primary " onClick={handlebgImageUpload} >
                                                <i class="fa fa-undo fa-fw"></i>
                                                <input
                                                    ref={bgimg}
                                                    type="file"
                                                    accept="image/png, image/jpeg,image/jpg"

                                                    style={{ display: 'none' }} onChange={handlebgFileChange} />
                                            </Button>
                                        ) : <div className="add-video d-flex cursor justify-content-center align-items-center flex-column" onClick={handlebgImageUpload}

                                        >
                                            <input type="file" ref={bgimg} style={{ display: 'none' }} onChange={handlebgFileChange} accept="image/*" />
                                            {camera}

                                            <div
                                                className="mt-1 d-flex justify-content-center align-items-center flex-column"
                                                style={{ color: "#CACACA" }}
                                            >
                                                <span className="landing-imge">
                                                    Add Landing Page Cover Image
                                                </span>
                                                <span className="">Recommendesid ze:</span>
                                                <span>1584px x 396px</span>
                                            </div>
                                        </div>}

                                    </div>
                                    <div className="camera-circle-wrap cursor ">

                                        {selectedImage ? (<>
                                            <div className={`camera-circle cursor`} onClick={handleImageUpload}>
                                                <img
                                                    src={`${config.API_BASE_URL_PUBLIC}/${selectedImage}`}
                                                    alt="Selected Logo"
                                                    className="selected-image"
                                                />
                                                <Button className=" editBtn-logo bg-landingPage-editBtn custom-btn-primary" onClick={handleImageUpload} >
                                                    <i class="fa fa-undo fa-fw"></i>
                                                    <input
                                                        ref={fileInputRef}
                                                        type="file"
                                                        accept="image/png, image/jpeg,image/jpg"

                                                        style={{ display: 'none' }} onChange={handleFileChange} />
                                                </Button></div></>
                                        ) : (
                                            <div className={`camera-circle cursor`} onClick={handleImageUpload}>
                                                <span className="camera">{camera}</span>
                                                <span style={{ fontSize: "12px" }}>Add logo</span>
                                                <span style={{ fontSize: "11px" }}>Recommended size:</span>
                                                <span style={{ fontSize: "10px" }}> 400px * 400px</span>

                                                <input
                                                    ref={fileInputRef}
                                                    type="file"
                                                    accept="image/png, image/jpeg,image/jpg"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        )}



                                    </div>
                                </div>
                            </Col>
                        </Row>

                        {/* card start */}

                        <Row md={12} className="mt-60 g-5 row-pad-0">

                            <Col
                                md={6}
                                className="bg-white pad-10 bg-white-border border-radius-4"
                            >
                                <h4 className="sub-heading">Company Details</h4>
                                <span className="single-border d-block"></span>
                                <Col md={12} className="mt-10">

                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Company Name"
                                            htmlFor="company_name"
                                        />
                                        <InputField
                                            id="company_name"
                                            name="company_name"
                                            type="text"
                                            placeholder="Enter your company name"
                                            onChange={handleChange}
                                            defaultValue={companyDetail?.company_name}
                                        />
                                        {errors.company_name && <><span className="text-danger">{errors.company_name}</span></>}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Industries
                                            industryId={formData.industry_id}
                                            onChange={handleSearChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label text="City" HtmlFor="city" />
                                        <InputField
                                            type="text"
                                            id="city"
                                            name="city"
                                            placeholder="city"
                                            onChange={handleChange}
                                            defaultValue={companyDetail?.city}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <States
                                            state={formSate}
                                            stateName={formData.country_name}
                                            onChange={handleSearChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Countries
                                            countryName={formData.country_name}
                                            onChange={handleSearChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label text="Zip Code " HtmlFor="" />
                                        <InputField
                                            type="text"
                                            name="zip_code"
                                            placeholder="Enter ZIP code"
                                            onChange={handleChange}
                                            defaultValue={companyDetail?.zip_code} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Company Size"
                                            htmlFor="company_size"
                                        />
                                        <InputField
                                            type="text"
                                            id="company_size"
                                            name="company_size"
                                            placeholder="Company Size"
                                            onChange={handleChange}
                                            defaultValue={companyDetail?.company_size == 0 ? '' : companyDetail?.company_size}
                                        />
                                    </Form.Group>
                                    {/* color picker */}
                                    {/* color picker */}
                                    <Form.Group className="mb-3">
                                        <Label className="label-input" text="Header/Footer Color" htmlFor="headerFooterColor" />

                                        <InputGroup>
                                            <InputField
                                                type="text"
                                                className="w-percent-80 border-right-0"
                                                placeholder="Choose header and footer color"
                                                value={headerFooterColor}
                                                onChange={handleColorChange}
                                            />

                                            <InputField
                                                type="color"
                                                className="border-left-0"
                                                id="headerFooterColor"
                                                value={headerFooterColor}
                                                title="Choose header and footer color"
                                                onChange={handleColorChange}
                                            />
                                        </InputGroup>

                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Button Color"
                                            htmlFor="buttonColor"
                                        />
                                        <InputGroup>
                                            <InputField
                                                className="w-percent-80 border-right-0"
                                                placeholder="Choose button color"
                                                value={showbuttonColor}
                                                onChange={handleButtonColorChange}
                                            />

                                            <InputField
                                                type="color"
                                                className="border-left-0"
                                                id="buttonColor"
                                                title="Choose your color"
                                                value={showbuttonColor}
                                                onChange={handleButtonColorChange}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Label
                                            className="label-input"
                                            text="Button text Color"
                                            htmlFor="ButtonTextColor"
                                        />
                                        <InputGroup>
                                            <InputField
                                                className="w-percent-80 border-right-0"
                                                placeholder="Choose Text color"
                                                value={buttonTextColor}
                                                onChange={handleButtonTextColorChange}

                                            />

                                            <InputField
                                                type="color"
                                                className="border-left-0"
                                                id="ButtonTextColor"
                                                value={buttonTextColor}
                                                title="Choose Text color"
                                                onChange={handleButtonTextColorChange}
                                            />
                                        </InputGroup>
                                    </Form.Group>


                                </Col>
                            </Col>

                            <Col md={6} className="border-radius-4 company-highlights-wrap">
                                {/* <Row md={12} className="bg-white pad-10 row-pad-0">
                                    <h4 className="heading-h pad-0">Highlights</h4>
                                    <span className="single-border d-block"></span>

                                    <Col md={4} className="mt-10">
                                        <Analytics
                                            className="bg-orange dashboard-analytics"
                                            icon={JobActive}
                                            link=""
                                            activeNumber="5"
                                            text="jobs"
                                        />
                                    </Col>
                                    <Col md={4} className="mt-10">
                                        <Analytics
                                            className="bg-purple dashboard-analytics"
                                            icon={facontact}
                                            link=""
                                            activeNumber={activeNumber}
                                            text="Invites"
                                        />
                                    </Col>
                                    <Col md={4} className="mt-10">
                                        <Analytics
                                            className="bg-bright-green dashboard-analytics"
                                            icon={faappointment}
                                            link=""
                                            activeNumber={activeNumber}
                                            text="Interviews"
                                        />
                                    </Col>
                                </Row> */}
                                <Row md={12} className="bg-white pad-10 mt-10 row-pad-0">
                                    <h4 className="sub-heading pad-0">Company Links</h4>
                                    <span className="single-border d-block"></span>

                                    {CompanyLinks.map((field, index) => (

                                        <Form.Group
                                            key={index}
                                            className="mb-3 mt-10 pad-0"
                                        >

                                            <Label
                                                text={field.text}
                                                className={field.className}
                                                htmlFor={field.id}
                                            />
                                            <InputField
                                                placeholder={field.placeholder}
                                                type={field.type}
                                                id={field.id}
                                                name={field.name}
                                                onChange={handleChange}
                                                defaultValue={setUrlVal(field.name)}

                                            />
                                        </Form.Group>
                                    ))}
                                </Row>
                                <Row
                                    md={12}
                                    className="bg-white pad-10 mt-20 row-pad-0 Ourculture-wrap"
                                >
                                    <Culture setcultureImgs={setcultureImgs} imgesCulture={companyDetail?.culture_images} />
                                </Row>
                            </Col>

                            <Row md={12} className="mt-10 ">
                                <Col md={12} className="bg-white pad-10 ">
                                    <Label
                                        text="Company Description"
                                        className="label-input"

                                    />



                                    <TextEditor
                                        textValue={contentEditor}
                                        setTextValue={setContentEditor} />


                                </Col>
                            </Row>
                            <Col md={12} className="text-end mt-10">
                                <CustomButton
                                    text="Cancel "
                                    onClick={handleClose}
                                    className="ml-10 custom-btn-outline"

                                />
                                <CustomButton
                                    text="Save "
                                    onClick={handleSubmit}
                                    className="ml-10 custom-btn-primary"

                                />


                            </Col>

                        </Row>
                    </Form>

                    {/* second model  */}
                    <Offcanvas
                        show={show}
                        onHide={pageSectionClose}
                        placement="end"
                        className="bg-ligte-gray custom-offcanvas page-section-wrap"
                        style={{ width: "40%", zIndex: 1045 }}
                    >

                        <Offcanvas.Header className="model-header bg-white pad-10 ">
                            <Offcanvas.Title>Show/Hide Company Landing Page Sections</Offcanvas.Title>
                            <div className="text-end"><CustomButton className="text-end close-btn " text={crossicon} onClick={pageSectionClose} /></div>
                        </Offcanvas.Header>

                        <Row md={12} className="mt-20 pad-20 page-section-body">
                            <div className=" page-section-flex">
                                <p>Company Name</p>
                                <Switch onChange={handelSwitch} name="show_name" checked={formData?.landing_page_settings?.show_name} />
                            </div>

                            <div className=" page-section-flex">
                                <p>Company Logo</p>
                                <Switch onChange={handelSwitch} name="show_logo" checked={formData?.landing_page_settings?.show_logo} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Banner Video</p>
                                <Switch onChange={handelSwitch} name="show_banner" checked={formData?.landing_page_settings?.show_banner} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Company Images</p>
                                <Switch onChange={handelSwitch} name="show_images" checked={formData?.landing_page_settings?.show_images} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Company Description</p>
                                <Switch onChange={handelSwitch} name="show_description" checked={formData?.landing_page_settings?.show_description} />
                            </div>
                            <div className=" page-section-flex">
                                <p>Status Cards<span>(Jobs,Invites,interviews)</span></p>
                                <Switch onChange={handelSwitch} name="show_cards" checked={formData?.landing_page_settings?.show_cards} />

                            </div>
                            <div className=" page-section-flex">
                                <p>Company Socials</p>
                                <Switch onChange={handelSwitch} name="show_socials" checked={formData?.landing_page_settings?.show_socials} />
                            </div>


                        </Row>
                    </Offcanvas>
                </Offcanvas.Body>

            </Offcanvas>
        </>
    );
};


export default EditCompany;
