import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Form } from 'react-bootstrap';
import { CustomButton, Label, Switch } from '../form/index';
import { update } from '../../store/actions/meetingSchedularAction';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

const MeetingScheduler = ({ meetingScheduler, userprofileId }) => {
    const dispatch = useDispatch()

    const [isAccordionOpen, setAccordionOpen] = useState(true);
    const [isEnabled, setEnabled] = useState(meetingScheduler[0]?.enabled);
    const meetingSchedularId = meetingScheduler[0]?.meeting_schedular_id;
    const userId = userprofileId;
    const [availability, setAvailability] = useState(meetingScheduler[0]?.availability);

    const toggleAccordion = () => {
        setAccordionOpen(!isAccordionOpen);
    };
    const time = [
        { value: 0, label: '12:00' },
        { value: 1, label: '01:00' },
        { value: 2, label: '02:00' },
        { value: 3, label: '03:00' },
        { value: 4, label: '04:00' },
        { value: 5, label: '05:00' },
        { value: 6, label: '06:00' },
        { value: 7, label: '07:00' },
        { value: 8, label: '08:00' },
        { value: 9, label: '09:00' },
        { value: 10, label: '10:00' },
        { value: 11, label: '11:00' },
        { value: 12, label: '12:00' },
        { value: 13, label: '01:00' },
        { value: 14, label: '02:00' },
        { value: 15, label: '03:00' },
        { value: 16, label: '04:00' },
        { value: 17, label: '05:00' },
        { value: 18, label: '06:00' },
        { value: 19, label: '07:00' },
        { value: 20, label: '08:00' },
        { value: 21, label: '09:00' },
        { value: 22, label: '10:00' },
        { value: 23, label: '11:00' }
    ];
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const handleCheckChange = (e, day) => {
        const { checked } = e.target;

        setAvailability(prevAvailability => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                enabled: checked ? 1 : 0
            }
        }));
        console.log(availability,"ak;lkd;l")
    };

    useEffect(()=>{

    },[availability])
    const handleChange = (day, field, value) => {
        setAvailability(prevAvailability => ({
            ...prevAvailability,
            [day]: {
                ...prevAvailability[day],
                [field]: value
            }
        }));
    };

    const handleEnabled = (e) => {
        const { checked } = e.target;
        setEnabled(checked);
    };

    const handleSave = () => {
 
      

        const payload = {
            "user_id": userId, 
            "future_window_booking": 2,
            "link": "abc.com",
            "enabled": isEnabled,
            "availability": availability,
        };
    if(userId){
        dispatch(update({meetingSchedularId, payload}))
        .then((res) => {
              
                
            if(res.payload.error){

              sweetAlert(
                  "error",
                  "Error!",
                 
                  `${res.payload.error}!`
              );
           
            }
            else{
        
              sweetAlert(
                  "success",
                  "Success!",
                  res.payload.message
              );
            }
           
            
          })
          .catch(error => {
           
              sweetAlert(
                  "error",
                  "Error!",
                  "Something went wrong!"
              );
          });

    }
    else{
        sweetAlert(
            "error",
            "Error!",
            "User Id Missing!"
        );
    }
       
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 1000
        });
    };

    return (
        <>
            <Accordion className='pad-0 bg-white'>
            <Row className='pad-5 mt-1 mb-1 bg-white row-pad-0'>
            <Col md={6} className='d-flex justify-content-start align-items-center '><h5 className='sub-heading mt-2'>Meeting Scheduler</h5></Col>
                    <Col className="d-flex justify-content-end align-items-center" md={6}>
                        <a href="#" className='ml-10 mt-1 mr-10 ' onClick={toggleAccordion}>
                            <i className={`fa ${isAccordionOpen ? 'fa-angle-down' : 'fa-angle-up'} clr-light-black`} style={{ fontSize: "25px" }}></i>
                        </a>
                    </Col>
                </Row>
                <span className='single-border d-block'></span>
                <Accordion.Collapse in={isAccordionOpen}>
                    <div>
                        <Col md={12} className='ml-20 mb-10 mt-10'>
                            <Switch onChange={handleEnabled} checked={isEnabled} />
                        </Col>

                        {isEnabled &&
                            <>
                                <Row className='row-pad-0 mt-10 pb-3 wrap-meeting-scheduler'>
                                    {daysOfWeek.map((day, index) => (
                                        <>
                                            <div key={index} className=" days-label " >
                                                <Col md={6}>
                                                    <Form.Check
                                                        className='mt-10'
                                                        type="checkbox"
                                                        name={day.toLowerCase()}
                                                        defaultChecked={availability[day.toLowerCase()]?.enabled == 1}
                                                        label={day}
                                                        onChange={(e) => handleCheckChange(e,day.toLowerCase())}
                                                    />
                                                </Col>
                                                <Col md={6} className='d-flex  meeting-scheduler-select mt-15' style={{ alignItems: 'center',  }}>
                                                    <Form.Select name="from" value={availability[day.toLowerCase()]?.from} onChange={(e) => handleChange(day.toLowerCase(), 'from', e.target.value)}>
                                                        {time.map((slot, index) => (
                                                            <option key={index} value={slot.value}>
                                                                {slot.label}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <span className='ml-15 mr-15'>to</span>
                                                    <Form.Select name="to" value={availability[day.toLowerCase()]?.to} onChange={(e) => handleChange(day.toLowerCase(), 'to', e.target.value)}>
                                                        {time.map((slot, index) => (
                                                            <option key={index} value={slot.value}>
                                                                {slot.label}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </div>
                                        </>
                                    ))}
                                </Row>
                                <Row className='row-pad-0 mt-10 pb-3'>
                                    <Col md={4}>
                                        <Label text="Future Booking Window" value={meetingScheduler[0]?.future_window_booking} />
                                        <Form.Select>
                                            <option>{meetingScheduler[0]?.future_window_booking} Week</option>
                                            <option>1 Week</option>
                                        </Form.Select>
                                        <CustomButton text="Save Changes" onClick={handleSave} className="mt-20 custom-btn-outline" />
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Accordion.Collapse>
            </Accordion>
        </>
    );
};

export default MeetingScheduler;
