import { createAction } from '@reduxjs/toolkit';

export const addAnswer = createAction('storeanswer', (blobUrl) => {
    return {
        payload: blobUrl,
    };
});

export const updateAnswer = createAction('updateAnswer', (id, data) => {
    return {
        payload: {
            id: id,
            data: data
        }
    };
});

export const getAnswerById = createAction('getAnswerById', (id) => {
    return {
        payload: {
            id: id
        }
    };
});

export const updateTimeline = createAction('updateTimeline', (id, data) => {
    return {
        payload: {
            id: id,
            data: data
        }
    };
});
