import { createSlice } from '@reduxjs/toolkit'
import { interviewForEvaluator,generateLink, generaterecordedinvitationlink ,create ,getInterviewEvaluation,EvaluationFinalRemarks} from '../actions/interviewEvaluatorAction'

const initialState = {
    loading: false,
    interviews: [],
    InterviewEvaluation:[],
    error: null,
}

const interviewEvaluatorSlice= createSlice({
    name: 'Evaluator ',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(interviewForEvaluator.pending, (state, action) => {
                state.loading = true
            })
            .addCase(interviewForEvaluator.fulfilled, (state, action) => {
                state.loading = false
                //state.interviews.push(action.payload)
            })
            .addCase(interviewForEvaluator.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(generateLink.pending, (state, action) => {
                state.loading = true
            })
            .addCase(generateLink.fulfilled, (state, action) => {
                state.loading = false
                //state.interviews.push(action.payload)
            })
            .addCase(generateLink.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(generaterecordedinvitationlink.pending, (state, action) => {
                state.loading = true
            })
            .addCase(generaterecordedinvitationlink.fulfilled, (state, action) => {
                state.loading = false
                //state.interviews.push(action.payload)
            })
            .addCase(generaterecordedinvitationlink.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase( getInterviewEvaluation.pending, (state, action) => {
                state.loading = true
            })
            .addCase( getInterviewEvaluation.fulfilled, (state, action) => {
                state.loading = false
                state.InterviewEvaluation=action.payload;
            })
            .addCase( getInterviewEvaluation.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase( create.pending, (state, action) => {
                state.loading = true
            }) 
            .addCase( create.fulfilled, (state, action) => {
                state.loading = false
                // state.InterviewEvaluation=action.payload;
            })
            .addCase( create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(EvaluationFinalRemarks.pending, (state, action) => {
                state.loading = true
            })
            .addCase(EvaluationFinalRemarks.fulfilled, (state, action) => {
                state.loading = false
                // state.InterviewEvaluation=action.payload;
            })
            .addCase(EvaluationFinalRemarks.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
           
        
    },
   
})

export default interviewEvaluatorSlice.reducer