import React, { useEffect, useState } from 'react';
import { Col, Row, Tab, Nav, Modal, Button, Form } from 'react-bootstrap';

import { ringcopy, videobox, mcqQuestion, textquestion, uploadfile, shareleftarrow, thingtime, retake, responsestime, word } from "../../helper/Icons/Icon";
import { Checkbox, CustomButton, CustomRating, Label, Textarea, } from "../form";

import FileUploadView from '../common/FileUploadView';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Spinner from "../common/Spinner";
import Swal from 'sweetalert2';
import StarRating from 'react-rating-stars-component';
import { useDispatch, useSelector, } from "react-redux";
import { interviewtemplatetokentpost, UpdateInterviewByToken, interviewtemplatetokent } from '../../store/actions/InterviewproceedAction';
import * as moment from 'moment'
const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'Multiple Choice': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile }

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 2000
    });
}

const CandidateResponse = () => {
    const { token } = useParams();
    const loction = useLocation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rating, setRating] = useState(0);
    const [comments, setComments] = useState('');

    const answers = useSelector((state) => state.recordedVideo.answers);
    const [selectedAnser, setselectedAnser] = useState(answers[0]);
    const [answerId, setAnswerId] = useState(0);

    const end_timestamp = new Date();

    const timeline = useSelector((state) => state.recordedVideo.timeline);
    const { interviewtemplatetDetail, loading, error } = useSelector((state) => state.Interviewproceed);
    useEffect(() => {
        if (token) {
            dispatch(interviewtemplatetokent(token));
        }

    }, [dispatch, token]);

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const handleCommentsChange = (e) => {
        setComments(e.target.value);
    };

    const InterviewProceed = () => {
        let payload = {
            "interview_name": interviewtemplatetDetail?.interview_name,

            "interview_type_id": 1,
            "interview_title": interviewtemplatetDetail?.interview_name,

            "start_timestamp": new Date(),
            "end_timestamp": new Date(),
            "description": interviewtemplatetDetail?.interview_template_description,
            "interview_rating": rating,
            "comments": comments,
            "answers": answers,
            "time_line": timeline

        }

        dispatch(UpdateInterviewByToken({ token, payload })).then((resp) => {


            if (resp.payload.error) {

                sweetAlert(
                    "error",
                    "Error!",

                    `${resp.payload.error}!`
                );
            }
            else {
                sweetAlert(
                    "success",
                    "Success!",
                    resp.payload.message
                );
                // navigate('/exit-message', { state: { token: token, exitmessage: interviewtemplatetDetail } })
                navigate(`/exit-message/${token}`);
            }


        }).catch(() => {
            sweetAlert(
                "error",
                "Error!",
                "Something went wrong!"
            );
        });

    };


    useEffect(() => {
        if (answerId !== null && answers.length > 0) {
            setselectedAnser(answers[answerId]);
        }

    }, [answerId, answers]);

    const selectAnswer = (id) => {
        setAnswerId(id);
    }

    return (
        <>
            {loading &&
                <Spinner />
            }
            <Row className="row-pad-0  pad-10" >
                <Col md={8}>
                    <h6 className='pad-0 mt-1 sub-heading'> Preview Interview Questions</h6>
                </Col>
                <Col md={4}>
                    <h6 className='pad-0  sub-heading'> Rate Your Experience</h6>

                </Col>

            </Row>
            <Row className="row-pad-0  interviewer-responses recordedinterview-wrap">
                <Col md={8}>
                    <Row className="row-pad-0 bg-white border-radius-5 pad-10" >
                        <div>
                            <h4>You are almost there.</h4>
                            <p>Kindly take the time to thoroughly review your responses and make sure you are satisfied with them.</p>
                        </div>
                        <h6 className='pad-0 mt-1 min-heading'> Candidate’s Responses</h6>
                        <Row className='row-pad-0 pad-0'>

                            <div className='responses-wrap pad-0 mt-10'>
                                <Nav variant="pills" className="responses-wrap-nav">
                                    {answers &&
                                        answers.map((answer, index) => {
                                            return (
                                                <Nav.Item key={index + 1}>
                                                    <Nav.Link

                                                        className={`box cursor ${index === answerId ? 'active' : ''}`}

                                                        onClick={() => selectAnswer(index)}
                                                    >
                                                        <span>{icons[answer.answer_media]}</span>
                                                        <span>Question {index + 1} {answer.answer_media}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            );

                                        })}
                                </Nav>

                                <div className='Response-left-side'>
                                    <Row className='row-pad-0'>
                                        <div className='Response-div question row-pad-0 '>
                                            <p>{selectedAnser.question_text}</p>
                                        </div>
                                        <div className='Response-div thingtime-wrap d-flex mt-20 row-pad-0 pad-10 '>
                                            <div className='thingtime'>
                                                <small>{thingtime}Think Time:</small><span> {selectedAnser.think_time}:00 Mins</span>
                                            </div>

                                            {(selectedAnser.answer_media === 'Video Question' || selectedAnser.answer_media === 'Audio') &&
                                                <>
                                                    <div className='responestime'>
                                                        <small>{responsestime}Response Time:</small><span>{selectedAnser.response_time}:00 Mins</span>
                                                    </div>


                                                    <div className='retakes'>
                                                        <small>{retake}Retakes:</small><span>{selectedAnser.retakes}</span>
                                                    </div>
                                                </>
                                            }
                                        </div>

                                        {selectedAnser.answer_media === 'Video Question' && (
                                            <div className='mt-20 Response-wrap pad-10'>
                                                <video width="100%" height="100%" controls src={selectedAnser?.interview_answer} />
                                            </div>
                                        )}

                                        {selectedAnser.answer_media === 'Audio' && (
                                            <div className='mt-20 Response-wrap pad-10'>
                                                <audio controls src={selectedAnser?.interview_answer} />
                                            </div>
                                        )}

                                        {selectedAnser.answer_media === 'Multiple Choice' && (
                                            <div className='Response-wrap pad-10 mt-20'>
                                                {selectedAnser.question_options.map((label, index) => (
                                                    <div className='Response-MCQResponse' key={index}>
                                                        <Checkbox
                                                            label={label.value}
                                                            type="radio"
                                                            id={`option${index + 1}`}
                                                            name="options"
                                                            checked={label.value === selectedAnser.interview_answer}
                                                        />
                                                    </div>
                                                ))}

                                            </div>
                                        )}
                                        {selectedAnser.answer_media === 'Written' && (
                                            <div className='Response-wrap mt-20 pad-10'>
                                                <p className='text-p'>{selectedAnser.interview_answer}</p>
                                            </div>
                                        )}
                                        {selectedAnser.answer_media === 'File Upload' && (
                                            <div className='Response-wrap pad-10 mt-20'>
                                                <FileUploadView icon={word} name={selectedAnser.interview_answer} />
                                            </div>
                                        )}



                                    </Row>

                                </div>
                            </div>

                        </Row>
                    </Row>
                </Col>

                <Col md={4}>

                    <Row className="row-pad-0 bg-white border-radius-5 pad-10">
                        <Col md={12}>
                            <h4 ClassName="min-heading">How was the interview process?</h4>
                            <p className="mt-15 ">
                                We value your feedback to enhance our interview process. Your input is important to us.
                            </p>
                            <Form.Label className='min-sub-heading mb-10'>Rating:</Form.Label>
                            <StarRating
                                value={rating}
                                count={5}
                                size={20}
                                onChange={handleRatingChange}
                                activeColor="#FFD700"
                            />
                        </Col>
                        <Form.Group controlId="exampleForm.ControlTextarea1" className="pad-20">
                            <Form.Label className='min-sub-heading mb-10'>Comments</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={10}
                                placeholder="Enter your final remarks about this candidate here"
                                value={comments}
                                onChange={handleCommentsChange}
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={12} className="text-end mt-20 mb-10">
                    <CustomButton className="custom-btn-primary" text="Save & Exit Interview" onClick={InterviewProceed} />
                </Col>

            </Row>


        </>
    );
}

export default CandidateResponse;
