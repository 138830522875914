import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Image } from 'react-bootstrap';
import logo from '../../assets/images/rbmp-new-logo.png';
import users from '../../assets/images/users.png';
import { Vidpmlogo } from '../../helper/Icons/Icon';
import ChangePassword from '../../pages/ChangePassword';
import Profile from '../../pages/Profile';
import NavLinks from './NavLinks';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CustomButton } from '../form';
const config = require('../../config/config.json');

function TopHeader({ postjob }) {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const storedImage = localStorage.getItem('storedImage');




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleModel = (url) => {
    if (url === "t-1") {
      setShowProfile(!showProfile);
      setDropdownVisible(false);
    } else if (url === "t-10") {
      setShowChangePasswordModal(!showChangePasswordModal);
      setDropdownVisible(false);
    }
  };

  const changeRoute = () => {
    navigate("/interviews");
    setDropdownVisible(false);
  };

  return (
    <>
      <Navbar.Brand href="dashboard" className="brand-logo">
        <div className='top-menu-logo'>{Vidpmlogo}</div>
      </Navbar.Brand>

      <Navbar.Collapse className="justify-content-end custom-nav">
        <CustomButton className="ml-10 custom-btn-outline"
          text="Track Interviews"
          onClick={changeRoute}
        />
        <CustomButton className="custom-btn-primary ml-5 ml-10" onClick={() => postjob()}
          text="Post a Job"
        />

        <div className='header-profile-image ml-10' onClick={toggleDropdown} ref={dropdownRef}>
          <Image
            src={storedImage
              ? `${config.API_BASE_URL_PUBLIC}/${storedImage}`
              : users}
            roundedCircle
          />
          {dropdownVisible && (
            <div className='profile-dropdown'>
              <ul className='profile-dropdown-user'>
                {NavLinks.map((link, index) => (
                  <li key={index} onClick={() => handleModel(link.id)}>
                    {link.url === "" ?
                      <span>
                        <span className='no-link'>{link.icon}</span>
                        <span style={{ color: "#7D7D7D", cursor: "pointer", }}>{link.title}</span>
                      </span>
                      :
                      <Link to={link.url}>
                        <span>{link.icon}</span>
                        <span>{link.title}</span>
                      </Link>
                    }
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Navbar.Collapse>

      {showChangePasswordModal && <ChangePassword handleClose={() => setShowChangePasswordModal(false)} />}
      {showProfile && <Profile handleClose={() => setShowProfile(false)} />}
    </>
  );
}

export default TopHeader;
