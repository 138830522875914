import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getAllPhotos } from "../../store/actions/companyActions";
import Paginate from "../common/Paginate";
import { useSelector } from 'react-redux';
const config = require('../../config/config.json');

const defaultPerPage = 3;
const defaultCurrentPage = 1;

function Photos({ company }) {
  const dispatch = useDispatch();
  const { AllPhotos, loading, error } = useSelector((state) => state.company);


  const hasPhotos = AllPhotos?.culture_images?.length > 0;
  const pagination = AllPhotos?.culture_images_pagination;
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(defaultPerPage);


  const pages = pagination?.pages;
  const totalPages = pagination?.total_pages;
  const totalRecords = pagination?.total_images;

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const imagesToDisplay = company.culture_images.slice(startIndex, endIndex);
  const id = company?.id;
  useEffect(() => {

    const data = {
      limit: perPage,
      page: currentPage
    };

    dispatch(getAllPhotos({ id, data }));


  }, [dispatch, currentPage, perPage]);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPage = (perPage) => {
    setPerPage(perPage);
  };


  return (
    <>
      <Row className="row-pad-0 mt-10 mb-10">
        <Col md={12} className="pad-0">
          <h4 className="sub-heading">{company.company_name} Photos</h4>
        </Col>
        <span className="single-border d-block"></span>
      </Row>

      {!hasPhotos ? (
        <h6>No record dound.</h6>
      ) : (
        <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
          <div className="photos-carousel d-flex justify-content-center">
            {imagesToDisplay.map((photo, index) => (
              <Col md={4} className="photos-carousel-col " style={{ marginRight: "5px" }}>
                <Image
                  key={index}
                  src={`${config.API_BASE_URL_PUBLIC}/${photo.image_file}`}
                  fluid
                  style={{
                    width: '100%', // Set to full width of the container
                    height: '100%', // Set fixed height
                    objectFit: 'cover' // Ensure the image covers the area
                  }}
                />
              </Col>
            ))}
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <Paginate
              pages={pages}
              currentPage={currentPage}
              totalPages={totalPages}
              perPage={perPage}
              totalRecords={totalRecords}
              handlePageChange={handlePageChange}
              handlePerPage={handlePerPage}
              pagenation="only"
            />
          </div>
        </Row>

      )}
    </>
  );
}

export default Photos;
