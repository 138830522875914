import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPhotos } from '../../store/actions/InterviewproceedAction';
import Paginate from "../common/Paginate";

const defaultPerPage = 3;
const defaultCurrentPage = 1;
const config = require('../../config/config.json');
function PhotosToken({ token, company }) {
  const dispatch = useDispatch();
  const { Photos = {}, loading, error } = useSelector((state) => state.Interviewproceed);


  const hasPhotos = Photos?.culture_images?.length > 0;
  const pagination = Photos?.culture_images_pagination || {};
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const id = company?.company_id;
  const pages = pagination?.pages;
  const totalPages = pagination?.total_pages;
  const totalRecords = pagination?.total_images;

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;


  const imagesToDisplay = Photos?.culture_images?.slice(startIndex, endIndex) || [];


  useEffect(() => {
    const data = {
      limit: perPage,
      page: currentPage
    };

    if (id && token) {
      dispatch(getAllPhotos({ id, token, data }));
    }
  }, [dispatch, currentPage, perPage, id, token]);

  const handlePageChange = (page) => setCurrentPage(page);
  const handlePerPage = (perPage) => setPerPage(perPage);

  return (
    <>
      <Row className="row-pad-0 mt-10 mb-10">
        <Col md={12} className="pad-0">
          <h4 className="sub-heading">{company?.company_name} Photos</h4>
        </Col>
        <span className="single-border d-block"></span>
      </Row>

      {!hasPhotos ? (
        <h6>No record found.</h6>
      ) : (
        <Row className="row-pad-0 mt-10 mb-10 d-flex justify-content-center">
          <div className="photos-carousel d-flex justify-content-center">
            {imagesToDisplay.map((photo, index) => (
              <Col key={index} md={4} className="photos-carousel-col" style={{ marginRight: "5px" }}>
                <Image
                  src={`${config.API_BASE_URL_PUBLIC}/${photo.image_file}`}
                  fluid
                  style={{
                    width: '100%', // Set to full width of the container
                    height: '100%', // Set fixed height
                    objectFit: 'cover' // Ensure the image covers the area
                  }}
                />
              </Col>
            ))}
          </div>
          <div className='d-flex justify-content-center mt-5'>
            <Paginate
              pages={pages}
              currentPage={currentPage}
              totalPages={totalPages}
              perPage={perPage}
              totalRecords={totalRecords}
              handlePageChange={handlePageChange}
              handlePerPage={handlePerPage}
              pagenation="only"
            />
          </div>
        </Row>
      )}
    </>
  );
}

export default PhotosToken;
