import { Row,Col } from "react-bootstrap";

const AboutCompany=({companyDetail})=>{
   
    return(<>

       <Row  className='row-pad-0 mt-10 mb-1 '><Col md={12} className='pad-0'><h4 className="sub-heading">About {companyDetail?.company_name}</h4> </Col>
       <span className='single-border d-block'></span>
       </Row>
       <Col md={12} className="mt-1 mb-10">
       <div dangerouslySetInnerHTML={{ __html: companyDetail?.company_description }} />
       </Col>
  
       
    </>)
}


export default AboutCompany;