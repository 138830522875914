import { createSlice } from '@reduxjs/toolkit'
import { create, getAll, getByName } from '../actions/userPrefrenceActions'


const initialState = {
    loading: false,
    userPrefrenceDetail: [],
    userPrefrences:[],
    error: null,
}

const userPrefrenceSlice = createSlice({
    name: 'userPrefrence',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.userPrefrences = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getByName.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getByName.fulfilled, (state, action) => {
                state.loading = false
                state.userPrefrenceDetail = action.payload
            })
            .addCase(getByName.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })            
    },
})

export default userPrefrenceSlice.reducer