// BasicInfo component
import React, { useState } from 'react';
import { Accordion, Row, Col, Button, Badge } from 'react-bootstrap';
import { CustomButton } from '../../components/form';
const config = require('../../config/config.json');

const BasicInfo = ({ userBasicInfo, handelEditUser }) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };
  const toggleEditMode = () => {
    handelEditUser();
  }
  console.log(userBasicInfo, "userBasicInfo")
  return (
    <>
      <Accordion className="pad-0 bg-white">
        <Row md={12} className="pad-5 mt-1 mb-1 bg-white row-pad-0">
          <Col md={6} className='d-flex justify-content-start align-items-center'>
            <h5 className='sub-heading mt-2'>Basic Info</h5>
          </Col>
          <Col className="d-flex justify-content-end align-items-center" md={6}>
            <CustomButton
              variant="outline-dark"
              className="edit-btn bg-white mt-1 "
              icon={<i className="fa fa-pencil clr-light-black" style={{ fontSize: "14px" }}></i>}
              size="sm"
              onClick={toggleEditMode}
            />


            <a href="#" className="ml-10 mt-1 mr-10" onClick={toggleAccordion}>
              <i
                className={`fa ${isAccordionOpen ? ' fa-angle-down' : 'fa-angle-up'} clr-light-black`}
                style={{ fontSize: "25px" }}
              ></i>
            </a>
          </Col>
        </Row>

        <span className="single-border d-block"></span>
        <Accordion.Collapse in={isAccordionOpen}>
          <Row className="row-pad-0 mt-10 pb-3">
            <Col md={2}>
              {userBasicInfo.user_image ? <div className="profile-image">
                <img src={`${config.API_BASE_URL_PUBLIC}/${userBasicInfo.user_image}`} alt="" />
              </div> :
                <div className="profile-image">
                  <img src="" alt="" />
                </div>
              }
            </Col>
            <Col md={10} className="pad-0">

            <Row className='row-pad-0 '>
                <Col md={6} className='pad-0 ' >
                  <label className='ml-10 mb-10 title-label'>{userBasicInfo.fname}  {userBasicInfo.lname}</label><br />
                  <label className='ml-10 mb-10 title-label'>Role:</label><small className='ml-10 mb-10'>Admin</small><br />
                  <label className='ml-10 mb-10 '>Email:</label><small className='ml-10 mb-10'>{userBasicInfo.email}</small><br />
              
                </Col>
                <Col md={6} className='pad-0'>
                  {Number(userBasicInfo.is_active) == 1 ? <Badge className="status status-Active mb-10" > Active </Badge> : <Badge className="status status-Inactive  mb-10"> Inactive </Badge>}<br />
                  <label className='mb-10'>Timezone:</label><small className='ml-10 mb-10'>{userBasicInfo.timezone}</small><br />
                  <label className='mb-10 '>Phone Number:</label><small className='ml-10 mb-10'>{userBasicInfo.phone}</small><br />
                  
                </Col>
            
                <Col md={12} className='pad-0 ' >
                <label className='ml-10 mb-10'>Address:</label><small className='ml-10 mb-10'>  <a 
            href={`https://www.google.com/maps/place/${encodeURIComponent(userBasicInfo.address+ ', ' + userBasicInfo.city+ ', ' + userBasicInfo.state_name+ ', ' + userBasicInfo.zip_code + ', ' + userBasicInfo.country)}`} 
            target="_blank" 
            rel="noopener noreferrer"
        >
            {userBasicInfo.address+ ', ' + userBasicInfo.city+ ', ' + userBasicInfo.state_name+ ', ' + userBasicInfo.zip_code + ', ' +userBasicInfo.country}
        </a></small>
                </Col>
              </Row>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export default BasicInfo;

