import { Col, Container, Row } from "react-bootstrap";
import SystemCheckbox from "./SystemCheckbox";
import { useEffect,useState } from "react";
import { wifi, camera, mic,readcopy,time,lightimg,StartInterviwimg } from "../../helper/Icons/Icon";
import TipsRecordVideo from "./TipsRecordVideo";
const SystemCheck=({Proceedaction})=> {

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [cameraPermission, setCameraPermission] = useState(null);
  const [ microphonePermission,setMicrophonePermission] = useState(null);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    checkCameraPermission();
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  const checkCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true,audio:true });
      stream.getTracks().forEach(track => track.stop());
      setCameraPermission(true);
      setMicrophonePermission(true); 
    } catch (error) {
      console.error('Error accessing camera:', error);
      setCameraPermission(false);
      setMicrophonePermission(false); 
    }
  };
  const PermissionsHandle = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      // getUserMedia is not supported
    
      window.alert('getUserMedia is not supported in this browser.');
      return;
    }
  
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      console.log('Permissions granted:', stream);
      stream.getTracks().forEach(track => track.stop());
      setCameraPermission(true);
      setMicrophonePermission(true); 
    } catch (error) {
      console.error('Error accessing camera/microphone:', error);
      // Handle permission denial or other errors
      if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
        // Requested device not found
        window.alert('Requested device not found.');
      } else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        // Permission denied
        window.alert('Permission denied to access camera/microphone.');
      } else {
        // Other errors
        window.alert('Error accessing camera/microphone.');
      }
    }
  };
  
  return (
    <>
    <Col md={12}>
      <Container fluid className="pad-20" >
        <Col md={12} className="bg-white border-radius-5 pad-10">
        <h4>System Check</h4>
        <button onClick={PermissionsHandle}>click get premiision</button>
        <Row className="mt-15 pad-10 " >

          <Col md={4} className="mb-1"><SystemCheckbox icon={wifi}   onclick={PermissionsHandle} clrName={isOnline ? 'system-card-success' : 'system-card-erro'} text="System Check" /></Col>
          <Col md={4} className="mb-1"><SystemCheckbox icon={camera} onclick={PermissionsHandle}  clrName={cameraPermission ?'system-card-success':'system-card-erro'} text="Camera Check"  /></Col>
          <Col md={4} className="mb-1"><SystemCheckbox icon={mic}  onclick={PermissionsHandle} clrName={microphonePermission ?'system-card-success':'system-card-erro'} text="Microphone Check"/></Col>
        </Row>
        </Col>

        <Col md={12} className="bg-white border-radius-5 pad-20 mt-20">
            <TipsRecordVideo />
        </Col>
        <Col md={12} className="mb-20" >
            <Row className="row-pad-0">
                <Col md={5} className="bg-white border-radius-5 pad-10 mt-20   cursor StartInterview-main">
                <div className="StartInterview-wrap  cursor" onClick={()=>Proceedaction("PracticeQuestion")}>
                <img src={lightimg} alt="Light Image" />
                </div>
                <h4>  Try Practice Question</h4>
                
                </Col>
                <Col md={2}>

                </Col>
                <Col md={5} className="bg-white border-radius-5 pad-10 mt-20 StartInterview-main  cursor" onClick={()=>Proceedaction("StartInterview")}>    
                <div className="StartInterview-wrap  cursor"> <img src={StartInterviwimg} alt="Start Interview Now Image" />
                </div>
                <h4>  Start Interview Now</h4>
               
                </Col>
            </Row>
        </Col>
      </Container>
      </Col>
    </>
  );
}

export default SystemCheck;
