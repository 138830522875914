import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/workAuthorizationSlice';
import { Label } from '../form';

const WorkAuthorization = ({ workAuthorizationId, onChange }) => {
    const dispatch = useDispatch();
    const options = useSelector(state => state.workAuthorization.workAuthorizations);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const handleChange = (e) => {
        onChange(e);
    };

    return (
        <>
            <Label text="Work Authorization" HtmlFor="work_authorization_id" />
            <Form.Select name='work_authorization_id' id='work_authorization_id' onChange={handleChange}>
                <option value="">Select your work authorization status</option>
                {options &&
                    options.map(option => (
                        <option key={option.id} value={option.id} selected={(workAuthorizationId && workAuthorizationId==option.id)?"selected":""}>{option.name}</option>
                    ))}
            </Form.Select >
        </>
    );
};

export default WorkAuthorization;