import { CustomRating } from "../form/index";
import { eye } from "../../helper/Icons/Icon";
import { Form, Table, Col } from "react-bootstrap";
import IndividualEvaluation from '../RecordedInterviewDetails/IndividualEvaluation';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInterviewRating } from "../../store/actions/interviewActions";
import * as moment from 'moment'
import { getRecordedInterview } from "../../store/actions/interviewActions";
const RatingsTable = ({ id }) => {
  const [showIndividualEvaluation, setShowIndividualEvaluation] = useState(false);
  const { InterviewRating, loading, error } = useSelector((state) => state.interview);
  const [evaluatorId, setevaluatorId] = useState(null);
  const dispatch = useDispatch();


  useEffect(() => {
    if (id) {
      dispatch(getInterviewRating(id)).then((res) => {


      });
    }
  }, [dispatch, id]);
  const handelFinalRemarks = (id) => {
    setevaluatorId(id)
    if (evaluatorId) {
      setShowIndividualEvaluation(true)
    }

  }
  return (
    <>
      <Col md={12} className="pad-0 bg-white radius-7">

        <h5 className="min-sub-heading pad-10  "> <label>Ratings</label></h5>
        <Table className="table" style={{ width: "auto" }}>
          <thead>
            <tr>
              <th style={{ width: "120px" }}><span>View Details</span></th>
              {/* <th style={{ minWidth: "100px", width: "400px" }}><Form.Control type="text" placeholder="Questions Rated" /></th>
            */}
              <th style={{ minWidth: "100px", width: "200px" }}><span><Form.Control type="text" placeholder="Reviewer’s Name" /></span></th>
              <th style={{ minWidth: "100px", width: "200px" }}><span><Form.Control type="text" placeholder="Rating" /></span></th>
              <th style={{ minWidth: "100px", width: "370px" }}><span><Form.Control type="text" placeholder="Final Remarks" /></span></th>
              <th style={{ minWidth: "100px", width: "200px" }}><span><Form.Control type="text" placeholder="Requested On" /></span></th>
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td colSpan="5" className="text-center"> <div className=""><span>siojsiojosi</span></div></td>


              </tr>
            ) : InterviewRating?.data?.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center"><h6>Data not found</h6></td>
              </tr>
            ) : (
              InterviewRating?.data?.map((rating, index) => (
                <tr key={index}>
                  <td><span className="cursor" onClick={() => handelFinalRemarks(rating.evaluator_id)}  >{eye}</span></td>
                  {/* <td>
                  <div className="soft-primary1 d-flex">
                    <span className="row-1 static-bg2">
                      <i className="fa fa-user-o"></i>
                      <strong className="strong clr-back">5 </strong>
                      <span>Video Questions</span>
                    </span>
                    <span className="row-1 static-bg2">
                      <i className="fa fa-user-o"></i>
                      <strong className="strong clr-back">35 </strong>
                      <span>MCQs</span>
                    </span>
                    <span className="row-1 static-bg2">
                      <i className="fa fa-user-o"></i>
                      <strong className="strong clr-back">35 </strong>
                      <span>File Upload</span>
                    </span>
                    <span className="row-1 static-bg2">
                      <i className="fa fa-user-o"></i>
                      <strong className="strong clr-back">35 </strong>
                      <span>Text</span>
                    </span>
                  </div>
                </td> */}

                  <td><a href="#">{rating.name}</a></td>
                  <td><CustomRating value={rating.final_rating} readOnly /></td>
                  <td><span className="ellipsis">{rating.final_remarks}</span></td>
                  <td>{moment(rating.requested_on).format('DD-MM-YYYY h:m A')}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col>
      {showIndividualEvaluation && <IndividualEvaluation evaluatorId={evaluatorId} handleClose={() => setShowIndividualEvaluation(false)} />}
    </>
  );
};

export default RatingsTable;
