import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { option } from '../../helper/Icons/Icon'; // Make sure you're using this somewhere in your component

const OptionsQuestion = ({options}) => {
  console.log("options")
  console.log(options.question_options)
  return (
    <>
      <Col md={12}>
        <h4 className='heading'>{option}Options</h4>
        <div className='d-flex flex-column mt-30 bg-white pad-10 radius-5'>
        
            {
              options.question_options.map((label,index)=>(
                <div className="options-wrap" key={index}>
                <Form.Check
                type="radio"
                label={label}
                name="radio"
                id={label}
                value={label}
              />
                </div>
              ))
            }
        
        

        </div>
      </Col>
    </>
  );
};

export default OptionsQuestion;
