import { actionlist, deletegray, view, copyicon, pencil,adduser,share,time,elv } from "../../helper/Icons/Icon";
import users from '../../assets/images/users.png';


export const actionlistfields = [
    {
        icon: copyicon,
        text: "View Candidate Details",
        props:"userDetail"
    },
    {
        icon: copyicon,
        text: "Share Interview for Evaluation",
        props:"SelectJob"
    },
    {
        icon: adduser,
        text: "Invite for Another Job",
        props:"InviteAnotherJob"
    },
    {
        icon: time,
        text: "Schedule Live Interview",
        props:"InviteLiveInterview"
    },
    
];

export const  redordercolumnfields = [
    {
      
        text: "Job Title"
    },
    {
      
        text: "Job Type"
    },
    {
      
        text: "Status"
    },
    {
  
        text: "Date Updated"
    },
    {
  
        text: "Date Created"
    },
    {
      
        text: "Location"
    },
    {
      
        text: "Workplace Type"
    },
    {
      
        text: "Pay Type"
    },
    {
      
        text: "Salary"
    },
    {
      
        text: "No.of Openings"
    },
    {
      
        text: "Company"
    }

];


export const navcandidatepages = [{ key: "All", text: "All (1)" },
  { key: "Invited", text: "Invited (0)" },
  { key: "InterviewSent", text: "Interview Sent (0)" },
  { key: "LinkExpired", text: "Link Expired (0)" },
  { key: "InterviewUnderway", text: "Interview Underway (0)" },
  { key: "InterviewPassed", text: "Interview Passed (1)" },
  { key: "InterviewFailed", text: "Interview Failed (1)" },
  { key: "LiveInterviewScheduled", text: "Live Interview Scheduled (1)" },
  { key: "LiveInterviewConducted", text: "Live Interview Conducted" },
  { key: "Hired", text: "Hired (0)" }
];

export const candidatejob= [
    {
      profileImg: users,
      title: 'Anthony Russell',
      
       name:"Jobs Applied",
      jobDetails: [  
        { title: 'Email Address', value: 'anthonyrussel@aol.com' },
        { title: 'Jobs Applied', value: '5' },
      
      ],

    },
    // Add more data objects for additional rows
  ];
