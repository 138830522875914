import { Col,Row } from "react-bootstrap";
import CriclrDiv from "../common/CriclrDiv";
import {camera,readcopy,time } from "../../helper/Icons/Icon";

const TipsRecordVideo=()=>{
    return(<>
    <Row className="pad-20">
        <Col md={5} className="">
        <video width="440" height="300" controls>
        <source src="weater-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
     
      </video>
        </Col>
        <Col md={7} className="pad-0">
            <h4>Tips to Record a Great Video </h4>
          
            <div className="d-flex mt-20"> 
            <CriclrDiv icon={readcopy} clrName="v-blue-light tips-svg tips-Circle-wrap" />
           <div className="ml-15 line-height-24">
               <h6>Read Instructions Carefully</h6>
               <p>You will be more successful in the interview and you will demonstrate your ability to work carefully and thoroughly by following the directions for the interview very carefully.</p>
           </div>
           </div>

           {/* second tipe */}
           <div className="d-flex"> 
            <CriclrDiv icon={camera} clrName="v-blue-light tips-svg tips-Circle-wrap" />
           <div className="ml-15 line-height-24">
               <h6>Learn How to Record a Great Video </h6>
               <p>When you are answering a question, look directly at the camera rather than at your computer screen. Imagine that the personal who will be viewing this video is right there. Pretend you are talking to that person.</p>
           </div>
           </div>
           {/* */}
           <div className="d-flex"> 
            <CriclrDiv icon={time} clrName="v-blue-light tips-svg tips-Circle-wrap" />
           <div className="ml-15 line-height-24">
               <h6>Keep Tabs on Deadline</h6>
               <p>Usually these interviews have a time-limit. So, put a clock where you can easily see it, and use the time wisely.</p>
           </div>
           </div>        

        </Col>
    </Row>
    </>)
}

export default TipsRecordVideo;