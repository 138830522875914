import { createSlice } from '@reduxjs/toolkit'
import { create,getTimeZone,createPreference, update, getAll, getById, deleteUser, deleteUsers, getUsersRoles, getDesignatedUsers, UsersProfile } from '../actions/userActions'


const initialState = {
    loading: false,
    users: [],
    userDetail: [],
    designatedUsers: null,
    usersRoles:[],
    timeZones:[],
    error: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(create.pending, (state, action) => {
                state.loading = true
            })
            .addCase(create.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(create.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(update.pending, (state, action) => {
                state.loading = true
            })
            .addCase(update.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(update.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getAll.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAll.fulfilled, (state, action) => {
                state.loading = false
                state.users = action.payload
            })
            .addCase(getAll.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getById.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getById.fulfilled, (state, action) => {
                state.loading = false
                state.userDetail = action.payload
            })
            .addCase(getById.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(deleteUser.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getUsersRoles.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getUsersRoles.fulfilled, (state, action) => {
                state.loading = false
                state.usersRoles = action.payload
            })
            .addCase(getUsersRoles.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(deleteUsers.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteUsers.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteUsers.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(getDesignatedUsers.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getDesignatedUsers.fulfilled, (state, action) => {
                state.loading = false
                state.designatedUsers = action.payload
            })
            .addCase(getDesignatedUsers.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(UsersProfile.pending, (state, action) => {
                state.loading = true
            }).addCase(UsersProfile.fulfilled, (state, action) => {
                state.loading = false
                state.UsersProfiledata = action.payload
            }).addCase(UsersProfile.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(createPreference.pending, (state, action) => {
                state.loading = true
            }).addCase(createPreference.fulfilled, (state, action) => {
                state.loading = false
                // state.UsersProfiledata = action.payload
            }).addCase(createPreference.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getTimeZone.pending, (state, action) => {
                state.loading = true
            }).addCase(getTimeZone.fulfilled, (state, action) => {
                state.loading = false
                state.timeZones= action.payload
            }).addCase(getTimeZone.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
    },
})

export default userSlice.reducer