// reducers/videoReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { addAnswer, updateAnswer, getAnswerById, updateTimeline } from '../actions/VideoRecordingAction';


const videoRecordingSlice = createSlice({
    name: 'videoRecording',
    initialState: {
        answers: [],
        answer: null,
        timeline: [],

    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addAnswer, (state, action) => {
                state.answers.push(action.payload)
            })
            .addCase(updateAnswer, (state, action) => {
                state.answers[action.payload.id] = action.payload.data;
            })
            .addCase(getAnswerById, (state, action) => {
                state.answer = state.answers[action.payload.id]
            })
            .addCase(updateTimeline, (state, action) => {
                state.timeline[action.payload.id] = action.payload.data;
            })

    },
});

export default videoRecordingSlice.reducer;
