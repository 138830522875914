import { Col } from "react-bootstrap";
import { Video } from "../../helper/Icons/Icon";
import { CustomButton } from "../form";
import { useEffect, useRef, useState } from "react";

const RecordVideo = () => {
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    const getVideo = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { width: 200, height: 200 }
        });
        setStream(mediaStream);
      } catch (err) {
        alert(err);
      }
    };

    getVideo();

    return () => {
      // Clean up function to stop the media stream when the component unmounts
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  return (
    <>
      <Col md={12} className="RecordVideo-wrap">
        <h4 className=""><span>{Video}</span>Record Video</h4>
        <div className="video-section mt-20">
          <span className="ready-video"><small></small> Ready Video</span>
          <video width="100%" height="auto" ref={videoRef} controls />
          <div className="upload-btn-wrapper">
            <div className="float-left">
              <CustomButton text="Record Now" className="record-btn" />
              <CustomButton text="Record Now" className="hide custom-btn-outline" />
            </div>
            <div className="video-btn float-right">
              {Video}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default RecordVideo;
