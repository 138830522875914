import { Offcanvas, Row, Container, Col ,Spinner} from "react-bootstrap";

import { useState, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { generateLink } from "../../store/actions/interviewEvaluatorAction";
import { CustomButton, InputField, Switch, SelectField, Label } from "../form"
import { adduser, attachment, crossicon, website, faplusicon, faminusicon, btnplus, expirtime } from "../../helper/Icons/Icon"

import '../../css/actionlist.css';
import Alljobs from "../common/Alljobs";
import Swal from 'sweetalert2';

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 1000
    });
}
const InviteAnotherJob = (props) => {
    const dispatch = useDispatch();
  
    const { handleClose, text, icon,candidateId } = props;
    const [showlink,setLink]=useState()
    const [candidateid,setcandidateId]=useState(candidateId)
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);
    const [isCopyLinkInvited, setCopyLinkInvited] = useState(false);
    const [expireDays, setExpireDays] = useState(5);
    const [isCopyLink, setCopyLink] = useState(false);
    const [isLinkGenerate, setLinkGenerate] = useState(false);
    const inputRef = useRef(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
      select_job: ''
    });
    const handleIncrement = () => {
        setExpireDays(expireDays + 1);
    };

    const handleDecrement = () => {
        if (expireDays >= 1) {
            setExpireDays(expireDays - 1);
        }
    };

    const handleChange1 = () => {
        setIsChecked1(!isChecked1);
    };

    const handleChange2 = () => {
        setIsChecked2(!isChecked2);
    };

   
    const LinkGenerate = () => {
        if (!formData.select_job) {
            setErrors({ select_job: 'Select job' });
            return;
        } else {
            setErrors({}); 
        }
     
        
        const payload={
       'job_id': parseInt(formData.select_job),
       'candidate_id' :candidateid,
     'expire_after_days': expireDays,
        'show_company_profile': isChecked2 ? 1:0
        }
      
   
        dispatch(generateLink(payload))
            .then((res) => {
            
                if(res.payload.link){
                    setLink(res.payload.link)
                    setLinkGenerate(!isLinkGenerate);
                }
               if(res.payload.error){
                return sweetAlert(
                    "error",
                    "Error!",
                    `${res.payload.error}!`
                )

            }
            

                
            })
            .catch(error => {
                return sweetAlert(
                    "error",
                    "Error!",
                    "Something went wrong!"
                )
            });
  
   
     
      
    };
    const CopyLinkInvited = () => {
        setCopyLinkInvited(true);
    }
 const Changehandle = (e) => {
      const { name, value } = e.target;
      const numericValue = value.trim() ? parseInt(value) : null;
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue
      }));
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: value.trim() ? '' : 'Field is required'
      }));
    };
    const CopyLink = () => {
        if (showlink) {
          const textarea = document.createElement('textarea');
          textarea.value = showlink;
          document.body.appendChild(textarea);
          textarea.select();
          try {
            const successful = document.execCommand('copy');
            if (successful) {
              setCopyLinkInvited(true); 
              return sweetAlert(
                    "success",
                    "Copied the text",
                    showlink
                )
              
            } else {
              alert("Unable to copy the text. Please use Ctrl+C (Cmd+C on Mac) to copy.");
            }
          } catch (err) {
            console.error('Unable to copy text:', err);
            alert("Unable to copy the text. Please use Ctrl+C (Cmd+C on Mac) to copy.");
          } finally {
            document.body.removeChild(textarea);
          }
        }
      };
    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "650px" }}>


            <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20 ">
            <Offcanvas.Header className="model-header bg-white  actionlist-header">
                            <Offcanvas.Title>
                            <span className="model-header-svg">{adduser}Invite for Another Job</span> </Offcanvas.Title>
                    <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                </Offcanvas.Header>
                <Col md={12} className=" mt-20 pad-10 col-md-12 ">
                <Row className="row-pad-0  pad-20 bg-white radius-7 ">
                <Col md={12} className="pad-0 ">
                <Alljobs  Changehandle={Changehandle}/>
                {errors.select_job && <span className="text-danger">{errors.select_job}</span>}
                </Col>
                <Col md={12} className="main-setting-card mt-20 ">
                            <div className="link mt-10">
                                <div className="puls-minus">
                                    <span className="mr-10">{expirtime}</span>
                                    <span>Expire Link After</span>
                                    <CustomButton icon={faminusicon} className="btn-default btn-mius" onClick={handleDecrement} />
                                    <InputField type="text" value={expireDays} readOnly />
                                    <CustomButton className="btn-default btn-puls" icon={faplusicon} onClick={handleIncrement} />
                                    <span className="mr-10">Days</span>
                                </div>
                                <Switch checked={isChecked1} onChange={handleChange1} />
                            </div>
                            <div className="link mt-20 mb-10">
                                <div>
                                    <span className="mr-10">{btnplus}</span>
                                    <span>Include company landing page</span>
                                </div>
                                <Switch checked={isChecked2} onChange={handleChange2} />
                            </div>
                            {isLinkGenerate && (
                                <Col md={12} className="pad-0 mb-20 mt-20">
                                    <div className="Copy-Link">
                                    {!isLinkGenerate ? <Spinner/>:
                                        <Col  className="d-flex pt-10">
                                            
                                         
                                           
                                        {showlink &&   <p ref={inputRef} className="text-ellipsis-link"><span className="ml-10 mr-10">{website}</span>{showlink}</p>}
                                        
                                      
                                        </Col>
                                          }
                                        <Col  className="text-end">
                                            {!isCopyLink && <CustomButton variant="outline-primary" className={isCopyLinkInvited ? "green btn mr-10" : "mr-10"} onClick={CopyLinkInvited} text={isCopyLinkInvited ? " Candidate Invited! " : "Invite via Email"} />}
                                            {!isCopyLinkInvited && <CustomButton variant="outline-primary" className={isCopyLink ? "green btn " : null} onClick={CopyLink} text="Copy Link" />}
                                        </Col>
                                    </div>
                                </Col>
                            )}

                        </Col>
                        </Row>

                 
                 
                    {!isLinkGenerate && (
                        <Col md={12} className="text-end mt-20 mb-20 pad-0">
                             <CustomButton onClick={handleClose} className="mr-10 custom-btn-outline" text="Cancel" />
                             <CustomButton className="custom-btn-primary" text="Generate Link " onClick={LinkGenerate} />
                               
                          
                        </Col>
                    )}

                </Col>
            </Container>

        </Offcanvas>
    );
}



export default InviteAnotherJob;