
import { Row, Col, Button } from "react-bootstrap";

import {  usercircle } from "../helper/Icons/Icon";

import UsersList from "../components/accountSetting/UsersList";

const UserSetting = () => {

  
    return (
        <>

            <Row className="listing-section row-pad-0" >
               
                <UsersList />
            </Row>

         
        </>)
}

export default UserSetting;