import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import FileUploadView from '../common/FileUploadView';
import { word ,docx,pdf,txt} from '../../helper/Icons/Icon';
const config = require('../../config/config.json');

const InterviewMedia = ({ media }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const renderCarouselItem = (item,type, idx) => {
        const extension = type;

        switch (extension) {
            case 'mp4':
            case 'mov':
            case 'avi':
                return (
                    <Carousel.Item key={idx}>
                        <div className='video-Interview-Media-wrap'>
                        <video
                            className="d-block w-100"
                            src={`${config.API_BASE_URL_PUBLIC}/${item}`}
                            controls
                            alt={`Slide ${idx}`}
                        />
                        <Carousel.Caption>
                            <div className='media-index'>
                                <h3> {idx + 1}</h3>
                            </div>
                        </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                );

            case 'mp3':
            case 'wav':
            case 'aac':
                return (
                    <Carousel.Item key={idx}>
                        <div className='w-100'>
                        <audio
                            className="d-block w-100"
                            src={`${config.API_BASE_URL_PUBLIC}/${item}`}
                            controls
                            alt={`Slide ${idx}`}
                        />
                      
                      
                        </div>
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return (
                    <Carousel.Item key={idx}>
                        <img
                            className="d-block w-100"
                            src={`${config.API_BASE_URL_PUBLIC}/${item}`}
                            alt={`Slide ${idx}`}
                        />
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );

            case 'docx':
          
            
                return (
                    <Carousel.Item key={idx}>
                        <FileUploadView name={`${config.API_BASE_URL_PUBLIC}/${item}`} icon={docx} nameext="docx" />
                        <Carousel.Caption>
                            {/* <h3> {idx + 1}</h3> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                );
                case 'pdf':
                    return (
                        <Carousel.Item key={idx}>
                            <FileUploadView name={`${config.API_BASE_URL_PUBLIC}/${item}`} icon={pdf}  nameext="pdf" />
                            <Carousel.Caption>
                                {/* <h3> {idx + 1}</h3> */}
                            </Carousel.Caption>
                        </Carousel.Item>
                    );
                    case 'txt':
                        return (
                            <Carousel.Item key={idx}>
                                <FileUploadView name={`${config.API_BASE_URL_PUBLIC}/${item}`} icon={txt} nameext="txt" />
                                <Carousel.Caption>
                                    {/* <h3> {idx + 1}</h3> */}
                                </Carousel.Caption>
                            </Carousel.Item>
                        );
            default:
                return null; // Ignore unknown file types
        }
    };
  
    if (!media || !Array.isArray(media) || media.length === 0) {
        return (<Row className="row-pad-0 pad-10 border-radius-5  mt-15">
            <h6>No media available</h6>

        </Row>)

    }
    return (
        <Row className={`row-pad-0 pad-10  interview-media-wrap  mt-10 ${media.length === 1 ? "no-carusol-arrows":"show-carusol-arrows"}`}>
            <Carousel activeIndex={index} onSelect={handleSelect} data-bs-theme="dark">
                {media.map((item, idx) => renderCarouselItem(item?.path,item?.type, idx))}
            </Carousel>
        </Row>
    );
};

export default InterviewMedia;
