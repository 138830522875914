import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAmountBreakdown } from '../../store/actions/accountBillingAction';
import { CustomButton } from '../form';
import { Row, Col, Table, Spinner } from 'react-bootstrap';

const AmountBreakdown = ({ accountID, addPackage }) => {
    const dispatch = useDispatch();
    const { amoutBreakDown, loading, error } = useSelector((state) => state.accountBilling) || {};

    useEffect(() => {
        if (accountID) {
            dispatch(getAmountBreakdown(accountID));
        }
    }, [dispatch, accountID]);

    // Check if amoutBreakDown data is present
    const hasData = amoutBreakDown && amoutBreakDown.data && amoutBreakDown.data.length > 0;

    return (
        <>
            {loading && <Spinner animation="border" />}
            {error && <div className="text-danger">Error: {error}</div>}
            <Table className='mb-10'>
                <thead>
                    <tr>
                        <th>Package Name</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {hasData ? (
                        amoutBreakDown.data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.package_name}</td>
                                <td>${item.total_amount}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2" className="text-center">No credit information available</td>
                        </tr>
                    )}
                    <tr>
                        <td>Credits (Add-on)</td>
                        <td>
                            <a href="#" onClick={addPackage}>+ Add More</a>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td className='bold'>Monthly Total:</td>
                        <td className='bold'>
                            {hasData ? 
                                `$${amoutBreakDown.data.reduce((total, item) => total + item.total_amount, 0)}` 
                                : 'N/A'}
                        </td>
                    </tr>
                </tfoot>
            </Table>
            {!hasData && <div className="text-center">
                <CustomButton text="Add More Credits" onClick={addPackage} />
            </div>}
        </>
    );
};

export default AmountBreakdown;
