import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { option, sky, Video, timer, uploadfile, InterviewMediaicon } from "../../helper/Icons/Icon";
import { useSelector, useDispatch } from 'react-redux';
import { updateAnswer, updateTimeline } from '../../store/actions/VideoRecordingAction';
import Swal from 'sweetalert2';

const ResponseEditor = ({ question, answer, activeStep }) => {
    const dispatch = useDispatch();
    const th = useRef();
    const [thinkTime, setThinkTime] = useState(answer?.think_time_insec ?? 0);
    const [wordCount, setWordCount] = useState(answer?.interview_answer?.length || 0); // Initialize with current answer length
    const [inputValue, setInputValue] = useState(answer?.interview_answer || ""); // Manage input value

    useEffect(() => {
        th.current = setInterval(() => setThinkTime(prevSeconds => prevSeconds + 1), 1000);

        if (answer?.think_time_insec) {
            const payload = {
                ...answer,
                think_time_insec: thinkTime,
                think_time: Math.ceil(thinkTime / 60),
            };
            dispatch(updateAnswer(activeStep, payload));
        }

        return () => {
            clearInterval(th.current);
        };
    }, [thinkTime]);

    const handleChange = (event) => {
        let answerText = event.target.value;
        const count = answerText.length;

        // Check if word count exceeds the limit
        if (count > question.max_words) {
            // Display error message
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Word limit exceeded!",
                showConfirmButton: false,
                timer: 2500
            });

            // Prevent further input by trimming the text
            answerText = answerText.substring(0, question.max_words);
        }

        setWordCount(answerText.length); // Update the word count
        setInputValue(answerText); // Update the input value

        const payload = {
            interview_template_question_id: question.interview_template_question_id,
            think_time_insec: thinkTime,
            think_time: Math.ceil(thinkTime / 60),
            response_time: 0,
            retakes: 0,
            interview_answer: answerText,
            question_text: question.question_text,
            answer_media: question.question_type_name,
        };

        dispatch(updateAnswer(activeStep, payload));

        let qNumber = activeStep + 1;
        const log = {
            key: `question_${qNumber}`,
            log: `Submitted Response for ${question.question_type_name} Question ${qNumber}`,
            date_time: new Date()
        };
        dispatch(updateTimeline(qNumber + 1, log));
    };

    return (
        <Row className="row-pad-0 pad-10 border-radius-5 interview-media-wrap bg-light-gray mt-15">
            <Col md={12}>
                <h4 className='sub-heading'>Enter Response</h4>
                <Form.Group controlId="exampleForm.ControlTextarea1" className='mt-20 p-relative'>
                    <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Enter your text here"
                        onChange={handleChange}
                        value={inputValue} // Controlled input
                    />
                    <span className={`count-word ${wordCount > question?.max_words ? 'text-danger' : ''}`}>
                        {wordCount}/{question?.max_words}
                    </span>
                </Form.Group>

                <div className='mt-1'><span>Thinking time: {thinkTime}</span></div>
            </Col>
        </Row>
    );
}

export default ResponseEditor;
