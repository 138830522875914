import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Spinner } from 'react-bootstrap';
import { getAll } from '../../store/slices/smsTemplateTypeSlice';
import { Label } from '../form';

const SmsTemplateTypes = ({ templateTypeId, Changehandle }) => {
    const dispatch = useDispatch();
    const typesSmsTemplate = useSelector(state => state.smsTemplateType?.smsTemplateTypes);
   
   
    useEffect(() => {
        dispatch(getAll())
           
    }, [dispatch]);
 
   
    if (!typesSmsTemplate || !Array.isArray(typesSmsTemplate)) {
        return(<>
        
            <Label text="Template Type " HtmlFor="email_template_type_id" />
            <Form.Select name='sms_template_type_id' id='sms_template_type_id' className="input-select"onChange={Changehandle}>
            <option value="">Some thing wrong check internect connection</option>
                
                </Form.Select>
            </>)

    }


    return (
        <>

         
            <Label text="Template Type" HtmlFor="sms_template_type_id" />
            <Form.Select name='sms_template_type_id' id='sms_template_type_id' onChange={Changehandle}>
            <option value="">Select template type</option>
                {typesSmsTemplate.map(option => (
                    <option 
                        key={option.sms_template_type_id} 
                        value={option.sms_template_type_id}
                        selected={templateTypeId && templateTypeId == option.sms_template_type_id}
                    >
                        {option.sms_template_type_name}
                        </option>
                ))}
            </Form.Select>
        </>
    );
};

export default SmsTemplateTypes;
