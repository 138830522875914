import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyToken, registerUser } from "../../store/actions/InterviewproceedAction";
import Swal from 'sweetalert2';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Checkbox, Label, InputField, CustomButton, FeedBack } from "../form/index";
import { fieldConfiregister } from "./FieldConfig";
import Spinner from "../common/Spinner";
import { Vidpmlogo } from "../../helper/Icons/Icon";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 4000
    });
};

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(true);
    const dispatch = useDispatch();
    const [apiToken, setToken] = useState();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        password: "",
        confirm_password: "",
    });

    const TextButton = "Sign In";
    const BtnBlueClass = "BtnBlueClass";

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");
        setToken(token)
         
        if (apiToken) {
          const payload={apiToken};
            dispatch(verifyToken(payload))
                .then((res) => {
                    console.log(res); 
                    if (res.payload && res.payload.status_code === 201) {
                     
                        sweetAlert("success", "Success!", res.payload.message);
                    }
                })
                .catch(error => {
                    console.error("Error verifying token:", error);
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    }, [dispatch, location.search]);

   const handleChange = (e) => {
        const { name, value } = e.target;
        
       
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));


        if (name === 'confirm_password' ) {
          setErrors(prevErrors => ({
              ...prevErrors,
              [name]: formData.password === value ? '' : 'Passwords do not match'
          }));
     
      } else {
          setErrors(prevErrors => ({
              ...prevErrors,
              [name]: value.trim() ? '' : 'Required'
          }));
      }

       
    };

    const FormRequestdemo = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {
          const payload={
            password:formData.password,
            confirm_password:formData.confirm_password,
            token: apiToken
        }
            dispatch(registerUser(payload))
                .then((res) => {
                    sweetAlert("success", "Success!", res.payload.message);
                    navigate("/")
                })
                .catch(error => {
                    console.error("Error registering user:", error);
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (formData) => {
        const errors = {};

        if (!formData.password.trim()) {
            errors.password = 'Password is required';
        }

     

        return errors;
    };
    const passwordToggle = () => {

        setShowPassword(prevState => !prevState);
     
      
  }
    return (
        <>
            <Container fluid className="pad-0">
                <Row>
                    <Col md={7} className='pad-0'>
                        <div className='logo'>{Vidpmlogo}</div>
                        <div className='info-graphics'></div>
                    </Col>
                    <Col md={5} className="justify-content-center register-left-side d-flex">
                       
                            <Form className="d-flex justify-content-center align-items-center ">
                                <Row>
                                    <Col md={12}><p className='accountSetting-p'>Set New Password to Get Started</p></Col>
                                    <span className='accountSetting-span mt-10'>You're almost there. Set your password and you're all set.</span>
                                    <Row className="mb-10 mt-10">
                                        {fieldConfiregister.map((config, index) => (
                                            <Col md={12} className="mt-10 mb-10  p-relative" key={index}>
                                                <Label
                                                    htmlFor={config.id}
                                                    className={config.className}
                                                    text={config.text}
                                                />
                                                <InputField
                                                    
                                                    type={config.type === "password" &&  showPassword ? "password" : "text"}
                                                    id={config.id}
                                                    name={config.id}
                                                    onChange={handleChange}
                                                    placeholder={config.placeholder}
                                                />
                                                 <span 
                                        onClick={passwordToggle} 
                                        className={`password-faEye fa fa-fw field-icon ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
                                    ></span>
                                                {errors[config.id] && (
                                                    <FeedBack className="error" error={errors[config.id]} />
                                                )}
                                            </Col>
                                        ))}
                                        <Col className="mb-10 mt-20">
                                            <CustomButton text={TextButton} className="custom-btn-primary" onClick={FormRequestdemo} />
                                        </Col>
                                    </Row>
                                </Row>
                            </Form>
                        
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Register;
