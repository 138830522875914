import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { getAll } from '../../store/slices/emailTemplateTypeSlice';
import { Label } from '../form';

const EmailTemplateTypes = ({ templateTypeId, Changehandle}) => {
    const dispatch = useDispatch();
    const EmailTemplateTypes = useSelector(state => state.emailTemplateType?.emailTemplateTypes);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    if (!EmailTemplateTypes || !Array.isArray(EmailTemplateTypes)) {
        return(<>
        
            <Label text="Template Type" HtmlFor="email_template_type_id" />
            <Form.Select name='sms_template_type_id' id='sms_template_type_id' onChange={Changehandle}>
            <option value="">Some thing wrong check internect connection</option>
                
                </Form.Select>
            </>)
    }
    return (
        <>
            <Label text="Template Type" HtmlFor="email_template_type_id" />
            <Form.Select name='email_template_type_id' id='email_template_type_id' onChange={Changehandle}>
                <option value="">Select template Type</option>
                {EmailTemplateTypes &&
                    EmailTemplateTypes.map(option => (
                        <option 
                            key={option.email_template_type_id} 
                            value={option.email_template_type_id}
                            selected={(templateTypeId && templateTypeId == option.email_template_type_id)?"selected":""}
                        >{option.email_template_type_name}</option>
                    ))}
            </Form.Select >
        </>
    );
};

export default EmailTemplateTypes;