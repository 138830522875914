import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Video from "twilio-video";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Modal } from "react-bootstrap";
import { adduser, leaveMeeting } from "../../helper/Icons/Icon";
import { InputField, Label, CustomButton } from "../form";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { addParticipant, CallLliveInterview } from "../../store/actions/InterviewproceedAction";

const sweetAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
}

const LiveInterview = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [roomName, setRoomName] = useState("recruitbpm");
    const [formData, setFormData] = useState({
        invite_message: `Hi,\nPlease visit the link to join live video call\n[link]\nRegards,`,
        email: '',
    });
    const [errors, setErrors] = useState({
        invite_message: '',
        email: '',
    });
    const [meetingLeft, setMeetingLeft] = useState(false);
    const [error, setError] = useState('');
    const [audioTrack, setAudioTrack] = useState(null);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [videoTrack, setVideoTrack] = useState(null);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [room, setRoom] = useState(null);
    const [screenTrack, setScreenTrack] = useState(null);
    const [isScreenSharing, setIsScreenSharing] = useState(false);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const payload = { roomName };
        dispatch(CallLliveInterview(payload))
            .then((res) => {
                if (res.payload.error) {
                    sweetAlert("error", "Error!", `${res.payload.error}!`);
                } else {
                    const token = res.payload.token;
                    connectToRoom(token);
                    sweetAlert("success", "Success!", res.payload.message);
                }
            })
            .catch(error => {
                sweetAlert("error", "Error!", "Something went wrong!");
            });

        return () => {
            disconnectFromRoom();
        };
    }, [dispatch, roomName]);

    const connectToRoom = (token) => {
        const { connect, createLocalVideoTrack, createLocalAudioTrack } = Video;
        const connectOptions = { name: roomName };

        connect(token, connectOptions).then(room => {
            setRoom(room);
            const videoChatWindow = document.getElementById('video-chat-window');
            const windowParticipants = document.getElementById('video-chat-window-participants');

            if (videoChatWindow && windowParticipants) {
                createLocalVideoTrack().then(track => {
                    videoChatWindow.appendChild(track.attach());
                    setVideoTrack(track);
                });

                createLocalAudioTrack().then(track => {
                    videoChatWindow.appendChild(track.attach());
                    setAudioTrack(track);
                });

                room.on('participantConnected', participant => {
                    setParticipants(prevParticipants => [...prevParticipants, participant]);

                    participant.tracks.forEach(publication => {
                        if (publication.isSubscribed) {
                            const track = publication.track;
                            windowParticipants.appendChild(track.attach());
                        }
                    });

                    participant.on('trackSubscribed', track => {
                        windowParticipants.appendChild(track.attach());
                    });
                });

                room.on('participantDisconnected', participant => {
                    setParticipants(prevParticipants =>
                        prevParticipants.filter(p => p !== participant)
                    );
                });
            } else {
                setError('Video chat window or participant list window not found.');
            }
        }).catch(error => {
            alert(error.message);
            console.error(`Unable to connect to Room: ${error.message}`);
        });
    };

    const disconnectFromRoom = () => {
        if (room) {
            room.disconnect();
        }
    };

    const toggleAudioTrack = () => {
        if (audioTrack) {
            audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
            setIsAudioMuted(!isAudioMuted);
        }
    };

    const toggleVideoTrack = () => {
        if (videoTrack) {
            videoTrack.isEnabled ? videoTrack.disable() : videoTrack.enable();
            setIsVideoMuted(!isVideoMuted);
        }
    };

    const AVControl = ({ type }) => {
        const icon = type === 'audio'
            ? (isAudioMuted ? faMicrophoneSlash : faMicrophone)
            : (isVideoMuted ? faVideoSlash : faVideo);

        const toggleTrack = type === 'audio' ? toggleAudioTrack : toggleVideoTrack;

        return (
            <div className="avControl">
                <FontAwesomeIcon icon={icon} onClick={toggleTrack} />
            </div>
        );
    };

    const leaveMeetingHandle = () => {
        if (room) {
            room.disconnect();
            setRoom(null);

            if (videoTrack) {
                videoTrack.stop();
                setVideoTrack(null);
            }

            if (audioTrack) {
                audioTrack.stop();
                setAudioTrack(null);
            }

            if (screenTrack) {
                screenTrack.stop();
                setScreenTrack(null);

                const screenVideo = document.getElementById('screen-video');
                if (screenVideo) {
                    screenVideo.remove();
                }
            }

            setMeetingLeft(true);
        }
    };

    const toggleScreenShare = async () => {
        if (isScreenSharing) {
            if (screenTrack) {
                await screenTrack.stop();
                room.localParticipant.unpublishTrack(screenTrack);
                setScreenTrack(null);

                const screenVideo = document.getElementById('screen-video');
                if (screenVideo) {
                    screenVideo.remove();
                }
            }
        } else {
            try {
                const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
                const newScreenTrack = new Video.LocalVideoTrack(stream.getTracks()[0]);

                if (screenTrack) {
                    await screenTrack.stop();
                    room.localParticipant.unpublishTrack(screenTrack);
                }

                room.localParticipant.publishTrack(newScreenTrack);
                setIsScreenSharing(true);
                const videoChatWindow = document.getElementById('video-chat-window');
                if (videoChatWindow) {
                    const screenVideo = newScreenTrack.attach();
                    screenVideo.id = 'screen-video';
                    videoChatWindow.appendChild(screenVideo);
                    setScreenTrack(newScreenTrack);
                } else {
                    setError('Video chat window not found.');
                }
            } catch (error) {
                console.error('Error sharing screen:', error);
                setError(`Unable to share screen: ${error.message}`);
            }
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalShow = () => {
        setShowModal(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.trim() ? '' : 'Field is required'
        }));
    };

    const Addparticipant = () => {
        const { email } = formData;

        if (!email) {
            setErrors(prevErrors => ({
                ...prevErrors,
                email: 'Email is required'
            }));
        } else {
            const payload = {
                "token": params.token,
                "email": formData.email,
                "content": formData.invite_message
            };

            dispatch(addParticipant(payload))
                .then((res) => {
                    if (res.payload.error) {
                        sweetAlert("error", "Error!", `${res.payload.error}!`);
                    } else {
                        sweetAlert("success", "Success!", res.payload.message);
                        setShowModal(false);
                    }
                })
                .catch(error => {
                    sweetAlert("error", "Error!", "Something went wrong!");
                });
        }
    };

    return (
        <>
            <div className="container">
                {meetingLeft ? (
                    <div className="meeting-left">
                        <center style={{ color: "white", position: "relative", top: "50vh" }}>You have left Video Call</center>
                    </div>
                ) : (
                    <>
                        {error && (
                            <div className="mb-5 mt-5">
                                <div className="alert alert-danger mt-3">{error}</div>
                            </div>
                        )}
                        <Row className="row-pad-0">
                            <div className="main-video-container">
                             
                                    {isScreenSharing && screenTrack ? (
                                      
                                            <div id="screen-video" className="screen-video"></div>
                                     
                                    ) : (
                                        <div id="video-chat-window" className="video-chat-window">
                                            {/* Local video track will be attached here */}
                                        </div>
                                    )}
                               
                            </div>
                            <div className="manage-participants" id="window-participants">
                                <h4>Participants</h4>
                                <div id="video-chat-window-participants" className="video-chat-window">
                                {participants.length === 0 ? (
                                    <p>No participants yet</p>
                                ) : (
                                    <ul>
                                        {participants.map(participant => (
                                            <li key={participant.sid}>{participant.identity}</li>
                                        ))}
                                    </ul>
                                )}
                                     
                                     </div>
                        
                              
                            </div>
                            <Col md={12} className="handel-setting">
                                <div className="live-mic">
                                    <AVControl type="audio" />
                                </div>
                                <div className="live-video">
                                    <AVControl type="video" />
                                </div>
                                <div className="screenShare-video">
                                    <span className="span-svg" onClick={toggleScreenShare}>
                                        {isScreenSharing ? <i className="fa fa-desktop-disabled" aria-hidden="true"></i> : <i className="fa fa-desktop" aria-hidden="true"></i>}
                                    </span>
                                </div>
                                <div className="screenShare-video">
                                    <span className="span-svg" onClick={handleModalShow}>
                                        {adduser}
                                    </span>
                                </div>
                                <div className="leaveMeeting">
                                    <span className="span-svg" onClick={leaveMeetingHandle}>
                                        {leaveMeeting}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite User</Modal.Title>
                </Modal.Header>
                <form id="inviteVideoUserFrm">
                    <Modal.Body>
                        <div className="participants-wrap">
                            <Col md={12} className="mt-20 mb-20">
                                <Label text="Email Address" className="labelinput mb-1 d-flex" />
                                <InputField type="text" id="email" name="email" onChange={handleChange} placeholder="Enter email address" />
                                {errors.email && <span className="text-danger">{errors.email}</span>}
                            </Col>
                            <Col md={12} className="mt-20 mb-20">
                                <textarea
                                    className="form-control mb-15"
                                    name="invite_message"
                                    id="invite_message"
                                    placeholder="Message goes here"
                                    rows="5"
                                    value={formData.invite_message}
                                    onChange={handleChange}
                                ></textarea>
                            </Col>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton text="Send Invite" onClick={Addparticipant} className="custom-btn-primary" />
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export default LiveInterview;
