
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Checkbox, CustomButton, InputField, Label, SelectField, Switch, Textarea } from "../form/index"
import { JobActive, crossicon, faplusicon, faminusicon, copyicon } from "../../helper/Icons/Icon"
import { Container, Row, Col, Offcanvas, Form } from "react-bootstrap"
import { postjobfiled } from "./fields";
import Spinner from "../common/Spinner";
import TextEditor from "../common/TextEditor";
import { Link, useNavigate } from "react-router-dom"
import InviteCandidates from "./InviteCandidtes";
import { getAll } from '../../store/actions/interviewTemplateActions';
import { update, getById } from '../../store/actions/jobActions'
import { useDispatch, useSelector } from 'react-redux';

import Industries from '../common/Industries';
import JobTypes from '../common/JobTypes';
import PayTypes from '../common/PayTypes';
import Countries from '../common/Countries';
import States from '../common/States';
import WorkspaceTypes from '../common/WorkspaceTypes';
import WorkAuthorization from '../common/WorkAuthorization';
import DesignatedUsers from '../common/DesignatedUsers';
import InterviewTemplates from '../common/InterviewTemplates';
import Companies from '../common/Companies';
import * as moment from 'moment';

import Swal from 'sweetalert2';

const Editjob = ({ jobId, handleClose }) => {
    const today = moment().format('YYYY-MM-DD');
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const { jobDetail, loading, error } = useSelector((state) => state.job);
    const [formSate, setFormSate] = useState(null);
    const [contentEditor, setContentEditor] = useState('');
    const { interviewTemplates } = useSelector((state) => state.interviewTemplate);
    useEffect(() => {
        if (jobId) {
            dispatch(getById(jobId));
            dispatch(getAll());
        }

    }, [dispatch]);

    useLayoutEffect(() => {

        formData["job_title"] = jobDetail?.job_title;
        formData["job_type_id"] = jobDetail?.job_type_id;
        formData["company_id"] = jobDetail?.company_id;
        formData["interview_template_id"] = jobDetail?.interview_template_id;
        formData["industry_id"] = jobDetail?.industry_id;
        formData["city"] = jobDetail?.city;
        formData["state_name"] = jobDetail?.state_name;
        formData["zip_code"] = jobDetail?.zip_code;
        formData["country_name"] = jobDetail?.country_name;
        formData["job_expiry_date"] = jobDetail?.job_expiry_date;
        formData["workspace_type_id"] = jobDetail?.workspace_type_id;
        formData["pay_type_id"] = jobDetail?.pay_type_id;
        formData["pay_salary"] = jobDetail?.pay_salary;
        formData["work_authorization_id"] = jobDetail?.work_authorization_id
        formData["job_description"] = jobDetail?.job_description;
        formData["is_build_template"] = jobDetail?.is_build_template;
        formData["is_private"] = jobDetail?.is_private;
        formData['followup_user'] = jobDetail?.followup_user;

        formData['fellowup_user_id'] = jobDetail?.fellowup_user_id;
        setContentEditor(jobDetail?.job_description)
        setFormSate(jobDetail?.state_name)

    }, [jobDetail]);
    const formattedJobExpiryDate = moment(formData?.job_expiry_date).format('YYYY-MM-DD');
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [showInviteCandidates, setShowInviteCandidates] = useState(false);

    const inputRef = useRef(null);
    const handleFocus = () => {

        if (inputRef.current) {
            inputRef.current.type = 'date';
        }
    };
    const PublishInviteCandidates = () => {
        setShowInviteCandidates(true);
    };
    const CloseInviteCandidates = () => {
        setShowInviteCandidates(false);
    }
    const jobprivateSwitch = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {

        if (contentEditor) {
            const updatedData = { ...formData, job_description: contentEditor };
            setFormData(updatedData);




        }


    }, [contentEditor])

    const Invite = () => {
        navigate("/jobtemplate");
    };

    const sweetAlert = (type, title, text) => {
        Swal.fire({
            icon: type,
            title: title,
            text: text,
            showConfirmButton: false,
            timer: 3000
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        if (Object.keys(validationErrors).length === 0) {

            var payload = formData;
            dispatch(update({ jobId, payload }))
                .then((res) => {
                    if (res.payload.status == 500) {
                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.detail

                        )


                    }
                    if (res.payload.error) {

                        return sweetAlert(
                            "error",
                            "Error!",
                            res.payload.error

                        )

                    }
                    else {

                        handleClose()
                        return sweetAlert(
                            "success",
                            "Success!",
                            res.payload.message

                        )

                    }
                })
                .catch(error => {

                    return sweetAlert(
                        "error",
                        "Error!",
                        "Something went wrong!"
                    )
                });
        }
        else {
            setErrors(validationErrors);
        }


    };
    const handleSearchChange = (name, value) => {



        setFormData({ ...formData, [name]: value });
        setFormSate(null)

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: value.toString().trim() ? '' : ' is required'
        }));




    };

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        let newValue = value;
        if (e.target.type === 'checkbox' || e.target.type === 'switch') {
            newValue = checked;
        }
        setFormData({
            ...formData,
            [name]: newValue
        });
        if (name === 'pay_salary' && isNaN(newValue)) {

            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: 'Only numbers are allowed'
            }));

            return;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: newValue ? ' ' : ' is required'
        }));
    }
    const validateForm = (formData) => {
        const errors = {};


        if (!formData.job_title || formData.job_title.trim() === '') {
            errors.job_title = 'Job title is required';
        }


        if (!formData.job_type_id) {
            errors.job_type_id = 'Job type is required';
        }


        if (!formData.company_id) {
            errors.company_id = 'Company is required';
        }


        if (!formData.interview_template_id) {
            errors.interview_template_id = 'Interview template is required';
        }


        if (!formData.followup_user) {
            errors.followup_user = 'Designated user is required';
        }




        if (!formData.city) {
            errors.city = 'City is required';
        }

        if (!formData.state_name) {
            errors.state_name = 'State is required';
        }


        if (!formData.country_name) {
            errors.country_name = 'Country is required';
        }


        if (!formData.zip_code) {
            errors.zip_code = 'Zip code is required';
        }


        if (!formData.job_expiry_date) {
            errors.job_expiry_date = 'Job expiration date is required';
        }


        if (!formData.pay_type_id) {
            errors.pay_type_id = 'Pay type is required';
        }


        if (!formData.pay_salary) {
            errors.pay_salary = 'Pay salary is required';
        }


        if (!formData.job_description) {
            errors.job_description = 'Job description is required';
        }

        return errors;
    };
    return (
        <>
            {!showInviteCandidates ? (
                <Offcanvas
                    show={true}
                    onHide={handleClose}
                    placement="end"
                    className="bg-ligte-gray custom-offcanvas"
                    style={{ width: "60%" }}
                >
                    <Container
                        fluid
                        className="row-pad-0 pad-0 bg-ligte-gray pad-20 wrap-postjob"
                    >

                        {loading &&
                            <Spinner />
                        }

                        <Offcanvas.Header className="model-header bg-white ">
                            <Offcanvas.Title>
                                <span className="model-header-svg">{JobActive}Edit a Job</span>

                            </Offcanvas.Title>
                            <div className="text-end">
                                <CustomButton
                                    onClick={handleClose}
                                    className="text-end close-btn "
                                    text={crossicon}
                                />
                            </div>
                        </Offcanvas.Header>

                        <Offcanvas.Body className="mt-10 wrap postjob-body">
                            <Form>
                                <Row className="row-pad-0 bg-white mt-20 postjob ">

                                    <Col md={12} className="pad-margin mb-20">
                                        <Label text="Job Title" HtmlFor="JobTitle" />
                                        <InputField
                                            type="text"
                                            placeholder="Enter title of the first job you are hiring for"
                                            name="job_title"
                                            id="job_title"
                                            onChange={handleChange}
                                            Value={jobDetail?.job_title}
                                        />
                                        {errors.job_title && <><span className="text-danger">{errors.job_title}</span></>}
                                    </Col>


                                    <Col md={6} className="pad-margin mb-20">
                                        <JobTypes typeId={jobDetail?.job_type_id} onChange={handleChange} />
                                        {errors.job_type_id && <><span className="text-danger">{errors.job_type_id}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin mb-20">
                                        <Companies companyId={formData?.company_id} onChange={handleSearchChange} />
                                        {errors.company_id && <><span className="text-danger">{errors.company_id}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">

                                        {interviewTemplates && <InterviewTemplates interviewTemplateId={formData?.interview_template_id} interviewTemplates={interviewTemplates} onChange={handleSearchChange} />}
                                        {errors.interview_template_id && <><span className="text-danger">{errors.interview_template_id}</span></>}
                                    </Col>

                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <DesignatedUsers designatedUserId={formData?.fellowup_user_id} onChange={handleSearchChange} />
                                        {errors.followup_user && <><span className="text-danger">{errors.followup_user}</span></>}
                                    </Col>

                                    <Col md={6} className="pad-margin mb-20">
                                        <Industries industryId={formData?.industry_id} onChange={handleSearchChange} />
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <WorkAuthorization workAuthorizationId={formData?.work_authorization_id} onChange={handleChange} />
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Label text="City" HtmlFor="" />
                                        <InputField
                                            type="text"
                                            name="city"
                                            placeholder="city"
                                            onChange={handleChange}
                                            defaultValue={formData?.city} />
                                        {errors.city && <><span className="text-danger">{errors.city}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <States stateName={formData?.country_name} state={formSate} onChange={handleSearchChange} />
                                        {errors.state_name && <><span className="text-danger">{errors.state_name}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Countries countryName={formData?.country_name} onChange={handleSearchChange} />
                                        {errors.country_name && <><span className="text-danger">{errors.country_name}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Label text="Zip Code " HtmlFor="" />
                                        <InputField
                                            type="text"
                                            name="zip_code"
                                            placeholder="Enter ZIP code"
                                            onChange={handleChange}
                                            defaultValue={formData?.zip_code} />
                                        {errors.zip_code && <><span className="text-danger">{errors.zip_code}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <Label text="Job Expiration Date " HtmlFor="" />
                                        <Form.Control

                                            name='job_expiry_date'
                                            min={formattedJobExpiryDate}

                                            type="date"
                                            onChange={handleChange}
                                            value={formattedJobExpiryDate}
                                        />
                                        {errors.job_expiry_date && <><span className="text-danger">{errors.job_expiry_date}</span></>}
                                    </Col>
                                    <Col md={6} className="pad-margin pad-margin mb-20">
                                        <WorkspaceTypes workSpaceTypeId={formData?.workspace_type_id} onChange={handleChange} />
                                    </Col>
                                    <Col md={6} className="pad-margin mb-20">
                                        <PayTypes payTypeId={formData?.pay_type_id} onChange={handleChange} />
                                        {errors.pay_type_id && <><span className="text-danger">{errors.pay_type_id}</span></>}
                                    </Col>

                                    <Col md={6} className="pad-margin">
                                        <Label text="Pay/Salary" HtmlFor="paySalary" />
                                        <InputField type="text" name="pay_salary" placeholder="Pay/Salary" defaultValue={formData?.pay_salary} onChange={handleChange} />
                                        {errors.pay_salary && <><span className="text-danger">{errors.pay_salary}</span></>}
                                    </Col>

                                    <Col md={12} className="mt-10 mb-10">
                                        <Label HtmlFor="" text="Job Description" />
                                        <Textarea
                                            onChange={handleChange}
                                            as="textarea"
                                            row={35}
                                            defaultValue={contentEditor}
                                            name="job_description"
                                            id="job_description"
                                            placeholder="Enter Description"
                                        />
                                        {/* <TextEditor
                                    textValue={contentEditor} 
                                     setTextValue={setContentEditor}
                                     placeholderValue={"Enter job description"}
                                     /> */}

                                        {errors.job_description && <><span className="text-danger">{errors.job_description}</span></>}
                                    </Col>
                                    <Col md={6} className="label-checkbox">
                                        <Checkbox name="is_build_template" checked={formData.is_build_template} label="Save this job as a template" onChange={handleChange} />
                                    </Col>
                                    <Col md={6} className="jobprivate-switch ">
                                        <Switch name="is_private" checked={formData.is_private} onChange={handleChange} />
                                        <span className="span">Make job private</span>
                                    </Col>
                                </Row>

                                <Col md={12} className="text-end mt-20 mb-20 pad-0">
                                    <CustomButton
                                        className="mr-10 mb-10 custom-btn-outline"
                                        text="Publish Job"
                                        onClick={handleSubmit}
                                    />
                                    <CustomButton
                                        className="custom-btn-primary mb-10"
                                        onClick={PublishInviteCandidates}
                                        text="Publish Job & Invite Candidates"
                                    />
                                </Col>
                            </Form>
                        </Offcanvas.Body>
                    </Container>
                </Offcanvas >
            ) : (
                <InviteCandidates onClick={Invite} handleClose={CloseInviteCandidates} />
            )}
        </>
    );
};


export default Editjob;
