import { share, Video, view, checkbox, adduser, eye, pencil, deletclrred, JobActive, exporticon, deletegray } from "../../helper/Icons/Icon";
import Statusbtn from "../common/Statusbtn";
import { deleteCandidateInvitation } from "../../store/actions/candidateInvitationActions";
import { Table, Form } from "react-bootstrap";
import { deleteJob } from '../../store/actions/jobActions'

import { CustomRating } from "../form";
import { CustomButton } from "../form/index";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import Interview from "../RecordedInterviewDetails/Interview";
import ShareCandidate from "../common/ShareCandidate";
import { useDispatch, useSelector } from 'react-redux'
import { useState } from "react";
import EditCandidate from "./EditCandidate";
const JobApplied = ({ invitations, invitationsLoading, setDeletej, deletej, handleFieldSearch, searchField }) => {
    const dispatch = useDispatch();
    const [showShareCandidate, setShareCandidate] = useState(false);
    const [jobId, setjobId] = useState();
    const [candidateId, setcandidateId] = useState();
    const [showInterview, setshowInterview] = useState(false)
    const handleDelete = (jobid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Are you sure to delete the selected item(s).',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteJob(jobid))
                    .then(() => {

                        setDeletej(!deletej)
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted',
                            text: 'Item(s) has been deleted.',
                            showConfirmButton: false,
                            timer: 1000
                        })

                    });
            }
        });

    };

    const handleModel = (action, id, candidateId) => {
        if (action === "sharecandidate") {
            setShareCandidate(!showShareCandidate)
            setjobId(id);
        }
        if (action == "Interview") {
            setjobId(id)
            setcandidateId(candidateId)
            setshowInterview(!showInterview);
        }

    }

    return (
        <>

            <Table className="table" style={{ width: "1900px" }}>
                <thead>
                    <tr>
                        <th style={{ minWidth: "120px", width: "120px" }}><span>Quick Action</span></th>


                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="job_title"
                                    value={searchField.job_title || ''}
                                    placeholder="Job Title"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="job_stage_name"
                                    value={searchField.job_stage_name || ''}
                                    placeholder="Job Status"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="stage_name"
                                    value={searchField.stage_name || ''}
                                    placeholder="Candidate Status"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="company"
                                    value={searchField.pay_type_name || ''}
                                    placeholder="Company"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="job_type_name"
                                    value={searchField.job_type_name || ''}
                                    placeholder="Job Type"
                                />
                            </span>
                        </th>
                        {/* <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="pay_type_name"
                                    value={searchField.pay_type_name || ''}
                                    placeholder="Pay Type"
                                />
                            </span>
                        </th> */}
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="pay_salary"
                                    value={searchField.pay_salary || ''}
                                    placeholder="Pay Type && Salary"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="industry_name"
                                    value={searchField.industry_name || ''}
                                    placeholder="Industry"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="workspace_type_name"
                                    value={searchField.workspace_type_name || ''}
                                    placeholder="Workspace Type"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="work_authorization_name"
                                    value={searchField.work_authorization_name || ''}
                                    placeholder="Work Authorization"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="job_city"
                                    value={searchField.job_city || ''}
                                    placeholder="City"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="job_state"
                                    value={searchField.job_state || ''}
                                    placeholder="State"
                                />
                            </span>
                        </th>
                        <th style={{ minWidth: "100px", width: "200px" }}>
                            <span>
                                <Form.Control
                                    type="text"
                                    onChange={handleFieldSearch}
                                    name="job_country"
                                    value={searchField.job_country || ''}
                                    placeholder="Country"
                                />
                            </span>
                        </th>
                    </tr>
                </thead>


                <tbody className="tabel-body">
                    {invitations &&
                        (invitations.length === 0 ? (

                            <tr>
                                <td colSpan="13" className="text-center">     {invitationsLoading ? <div className=""><span><Spinner /></span></div> : <h6>   No record found</h6>}</td>




                            </tr>) : (
                            invitations.map(invitation =>
                            (
                                <tr>


                                    <td>
                                        <div className="quick-action">
                                            {invitation?.candidate_id && <span className="cursor" onClick={() => handleModel("Interview", invitation?.job_id, invitation?.candidate_id)}>{Video}</span>}

                                            <span className="cursor" onClick={() => handleDelete(invitation?.job_id)}>{deletclrred}</span>
                                            <span className="cursor" onClick={() => handleModel("sharecandidate", invitation.job_id)}>{share}</span>
                                            {invitation.candidate?.resume ? <span className="cursor" >

                                                {view}</span> : null}


                                        </div>
                                    </td>
                                    <td><a href="#">{invitation.job.jobTitle}</a></td>
                                    <td><Statusbtn className={`status status-${invitation.job?.stage.stageName}`} text={invitation.job?.stage.stageName} /></td>
                                    <td><Statusbtn className={`status status-${invitation.stage?.stageName}`} text={invitation.stage?.stageName} /></td>
                                    <td>{invitation.job.company.company_name}</td>

                                    <td>{invitation.job.paySalary} ({invitation.job.jobType.jobTypeName})</td>
                                    <td>{invitation.job.payType.payTypeName}</td>
                                    <td>{invitation.job.industry.industryName}</td>
                                    <td>{invitation.job.workspaceType.workspaceTypeName}</td>
                                    <td>{invitation.job.workAuthorization.workAuthorizationName}</td>
                                    <td>{invitation.job.city}</td>
                                    <td>{invitation.job.stateName}</td>
                                    <td>{invitation.job.countryName}</td>

                                </tr>
                            )

                            ))
                        )
                    }


                </tbody>
            </Table>
            {showShareCandidate && <ShareCandidate handleClose={() => handleModel("sharecandidate")} jjobId={jobId} />}
            {showInterview && <Interview handleClose={() => handleModel("Interview")} jobid={jobId} candidateId={candidateId} />}
        </>)
}

export default JobApplied;