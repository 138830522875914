import { Offcanvas, Container, Row, Col } from "react-bootstrap";
import { CustomRating } from "../form/index";

import CandidateRespones from "../common/CandidateRespones";
import { ResponesFileds } from "./field"
import { crossicon } from "../../helper/Icons/Icon";
import { CustomButton } from "../form/index";
import { getFinalRemarks } from "../../store/actions/interviewActions";
import { useEffect } from "react";
import { star } from "../../helper/Icons/Icon";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../common/Spinner";
var comments = "The emphasis on mentoring and leadrship reflects a strong commitment to team development";
const IndividualEvaluation = ({ handleClose, evaluatorId }) => {

  const { finalRemarks, loading, error } = useSelector((state) => state.interview);
  const dispatch = useDispatch();

  useEffect(() => {
    if (evaluatorId) {
      dispatch(getFinalRemarks(evaluatorId)).then((res) => {



      })
    }
  }, [dispatch]);
  const responses = finalRemarks?.question_answer;
  return (
    <>
      <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray recordedinterview-wrap custom-offcanvas" style={{ width: "65%" }}>


        {loading && <Spinner />}
        <Container fluid className="detailpage-wrap pad-0">


          <Offcanvas.Header className="model-header bg-white ">
            <Offcanvas.Title>
              <span className="model-header-svg">{star}Individual Evaluation</span></Offcanvas.Title>
            <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
          </Offcanvas.Header>

          <Col md={12} className="recordedinterview-body pad-10">



            <Row className="row-pad-0  interviewer-responses bg-white mt-20 ">
              {responses && <CandidateRespones responses={responses} comment="show" />}
            </Row>

            <Row className="row-pad-0  bg-white FinalRatingView mt-20 mb-20 ">
              <h6 className="mt-10 sub-heading"><lable>Final Rating & Remarks</lable></h6>
              <h5 className="mt-20 bold"><b>Rating</b></h5>
              <p><CustomRating value={finalRemarks?.rating} /></p>

              <h5 className="bold mt-10"><b>Final Remarks</b></h5>
              <Col md={12} className="">
                <div>
                  <p>{finalRemarks?.rating_remarks}</p>
                </div>
              </Col>
            </Row>


          </Col>
        </Container>
      </Offcanvas>



    </>



  )
}

export default IndividualEvaluation;