import { Col, Row, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import * as moment from 'moment'
import Analytics from "../common/Analytics";

import { JobActive, filter, faappointment, facontact } from "../../helper/Icons/Icon";





const JobAnalytics = ({dashboardAnalytics,handleOpenModal, formData}) => {




    




    return (<>
{/* {filter} */}
        <Col md={12} className="d-flex justify-content-between align-items-baseline border-b " style={{ padding: "5px 10px" }}><h4 className="analytics-heading widget-h">Job Analytics</h4><span className="filter-svg" onClick={handleOpenModal}>{filter}</span>

        </Col>



        <div className="d-flex justify-content-around mt-10" style={{ padding: "5px 10px 5px 10px" }}>
           
            {dashboardAnalytics && <>
            <Analytics className="bg-orange dashboard-analytics" icon={JobActive} link="" activeNumber={dashboardAnalytics?.active_job_count} text="Active Jobs" />


            <Analytics className="bg-light-blue dashboard-analytics" icon={facontact} link="" activeNumber={dashboardAnalytics?.candidates_count} text="Candidates" />

            <Analytics className="bg-purple dashboard-analytics" icon={faappointment} link="" activeNumber={dashboardAnalytics?.recorded_interview_count} text="Recorded Interviews" />
            <Analytics className="bg-purple dashboard-analytics" icon={faappointment} link="" activeNumber={dashboardAnalytics?.live_interview_count} text="Live Interviews" />

            <Analytics className="bg-bright-green dashboard-analytics" icon={JobActive} link="" activeNumber={dashboardAnalytics?.hired_candidates_count} text="Hired" />
            </> }
        </div>
        <div className="d-flex justify-content-center align-items-center clr-filter-date  mt-10 mb-10">
            <b>Yesterday</b> <i class="fa fa-calendar"> </i><small>{moment(formData.startDate).format('YYYY/MM/DD')}-</small><i class="fa fa-calendar"></i><small>{moment(formData.endDate).format('YYYY/MM/DD') }</small>
        </div>
   
    </>
 
)
}

export default JobAnalytics;