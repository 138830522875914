import React, { useState } from 'react';
import {  ringcopy, videobox, attachment, frame,word } from '../../helper/Icons/Icon';
import FileUploadView from '../common/FileUploadView'; 
import  signatureimg from   "../../assets/images/signatureimg.png";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

const InterviewMedia = () => {
  const [activeKey, setActiveKey] = useState('ringcopy'); // Set the default active tab

  const handleTabSelect = (key) => {
    setActiveKey(key);
  };

  return (
    <>
 
    

        <Tab.Container id="left-tabs-example" className="" activeKey={activeKey} onSelect={handleTabSelect}>
          <Row className=''>
            <Col md={12} className="box-wrap ">
              <Nav variant="pills" className=" d-flex justify-content-between ">
                <Nav.Item>
                  <Nav.Link eventKey="ringcopy" className={`box cursor ${activeKey === 'ringcopy' ? 'active' : ''}`}>
                    {ringcopy}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="videobox" className={`box cursor ${activeKey === 'videobox' ? 'active' : ''}`}>
                    {videobox}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="frame" className={`box cursor ${activeKey === 'frame' ? 'active' : ''}`}>
                    {frame}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="attachment" className={`box cursor ${activeKey === 'attachment' ? 'active' : ''}`}>
                    {attachment}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            
            <Col sm={12}
             className='media-box-content'
            >
              <Tab.Content>
                <Tab.Pane eventKey="ringcopy" >
                <div className=' mt-10 video-wrap'>
                <video width="100%" height="100%" controls>
       
     
      </video>
      </div>

                </Tab.Pane>
                <Tab.Pane eventKey="videobox">
                    
                <div className=' mt-10 video-wrap'>
                <video width="100%" height="100%" controls>
       
     
      </video>
      </div>
                </Tab.Pane>
                <Tab.Pane eventKey="frame">
                  <div className='signature-image'>
                 <img src=   { signatureimg} alt='signatuee'/>
                 </div>
                </Tab.Pane>
                <Tab.Pane eventKey="attachment">  <FileUploadView icon={word} name="file name.file format" sizefile="0.3MB" /></Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

    </>
  );
};

export default InterviewMedia;
