
import { crossicon } from "../../helper/Icons/Icon";
import { Checkbox, CustomButton, InputField, Label, Switch } from "../form/index";
import { Container, Row, Col, Offcanvas } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react"; 
import { useDispatch, useSelector } from 'react-redux';
import { getJobStatus,updateJobStage } from "../../store/actions/jobActions";
import Spinner from "../common/Spinner";
import Swal from "sweetalert2";
const sweetAlert = (type, title, text) => {
    Swal.fire({
      icon: type,
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 2000,
    });
  };
const ChangeStatus = ({ handleClose ,jobId,statusActive}) => {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatus] = useState(statusActive);
    const [selectedStatusID, setSelectedStatusID] = useState(statusActive);
    
    
    const handleStatusChange = (status,id) => {
        setSelectedStatusID(id)
        setSelectedStatus(status); 
    }
    const { status, loading, error } = useSelector((state) => state.job);
    const statusData=status?.data;

    const handleSave = () => {
        let payload ={
                "job_ids":jobId, 
                "stage_id": selectedStatusID, 
            }
        
      
        
          dispatch(updateJobStage(payload))
            .then((res) => {
        
                handleClose()
              return sweetAlert("success", "Success!", `${res.payload.message}!`);
        
            })
            .catch((error) => {
              return sweetAlert("error", "Error!", "Something went wrong!");
            });
     
    };
    useEffect(() => {
      
        dispatch(getJobStatus());

    }, [dispatch]);

if(!statusData  ||  !Array.isArray(statusData)){
    return <div>{error}</div>
}

  
    return (
        <>
            <Offcanvas
                show={true}
                onHide={handleClose}
                placement="end"
                className="bg-ligte-gray custom-offcanvas"
                style={{ width: "30%" }}
            >
                <Container fluid className="row-pad-0 pad-0 bg-ligte-gray pad-20">
                <Offcanvas.Header className="model-header bg-white ">
                            <Offcanvas.Title>
                            <span className="model-header-svg">Change Status</span></Offcanvas.Title>
                        <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn " text={crossicon} /></div>
                    </Offcanvas.Header>
                   {loading && < Spinner />} 
                    <Col md={12} className="pad-10">
                        <Row className="row-pad-0 bg-white  radius-7">
                            {statusData.map((label,index)=>(

<div className="d-flex align-content-center pad-10 mb-10 ml-10" keys={index}>
<div className="mr-20 " keys={index}>
    <Checkbox 
        name={label.stageName}
        id={label.stageName}
        type="radio"
        className="radio-width"
        checked={selectedStatus === label.stageName}
        onChange={() => handleStatusChange(label.stageName,label.stageId)}
    />
</div>
<div>
    <CustomButton text={label.stageName} className={`status-${label.stageName} status ${selectedStatus === label.stageName ? label.stageName : ''}`} />
</div>
</div>
                            ))}
                            
                         
                        </Row>

                        <Col md={12} className="text-end mt-10 ">
                        <CustomButton className="mr-10 custom-btn-outline" text="Cancel" onClick={handleClose} />
                            <CustomButton text="Save" className="custom-btn-primary " onClick={handleSave} />
                        </Col>
                    </Col>

                </Container>
            </Offcanvas>
        </>
    )
}

export default ChangeStatus;
