import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, Container, Col, Row } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton } from "../form";
import ActionList from "../common/ActionList";
import { crossicon, actionlist, adduser, attachment, Jobs } from "../../helper/Icons/Icon";

import { navdetailpages, detailjob, headertimeLine } from "./fields"
import FeedTimeLine from "../common/FeedTimeLine";
import Interview from "../RecordedInterviewDetails/Interview";
import ShareCandidate from "../common/ShareCandidate";
import Spinner from "../common/Spinner";
import * as moment from 'moment'
import { getById } from '../../store/actions/jobActions'

import Detail from './Detail';
import InviteCandidates from "./InviteCandidtes";
import GenerateLink from "./GenerateLink";
import ScheduleInterviews from "../common/ScheduleInterview";

const JobDetail = ({ handleClose, toggleCandidates, handleModelActionList, jobId }) => {
    const dispatch = useDispatch();
    const [updateList, setUpdateList] = useState(true)
    const [jobPid, setJobId] = useState(jobId);

    const [showInterview, setInterview] = useState(false);
    const { jobDetail, loading, error } = useSelector((state) => state.job);
    const [showTooltip, setShowTooltip] = useState({});

    useEffect(() => {
        if (jobPid) {
            dispatch(getById(jobId));
        }

    }, [dispatch, updateList]);

    const refreshPage = () => {
        setUpdateList(!updateList)

    }

    const JobDetailModel = (props, id) => {
        setJobId(id)


        if (props === "Interview") {
            setInterview(true)
        }


    };
    const JobDetailModelClose = (props) => {
        setJobId(null)

        if (props === "Interview") {
            setInterview(false)
        }

    }

    const handleMouseEnter = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: true }));
    };

    const handleMouseLeave = (key) => {
        setShowTooltip(prev => ({ ...prev, [key]: false }));
    };

    const renderTooltip = (text) => (
        <Tooltip id="tooltip">
            {text}
        </Tooltip>
    );


    return (
        <>


            {jobDetail &&
                <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "82%" }}>
                    <Container fluid className="detailpage-wrap pad-0">
                        {loading &&
                            <Spinner />
                        }
                        <Row className="row-pad-0">
                            <div className="header">
                                <div className="header-left">
                                    {/* <span>{Jobs}</span> */}
                                    <h4>Job Orders Detail</h4>
                                    <div className="date">
                                        <p className="created">
                                            <span>CREATED BY: {jobDetail.created_by}</span>
                                            <small> {moment(jobDetail.created_date).format('MM-DD-YYYY h:mm A')}</small>
                                        </p>
                                        <p className="updated">
                                            <span>UPDATED BY: {jobDetail.updated_by}</span>
                                            <small>{moment(jobDetail.updated_date).format('MM-DD-YYYY h:mm A')}</small>
                                        </p>
                                    </div>

                                    <CustomButton className="actionlist-btn" onClick={() => toggleCandidates("actionList", jobDetail.id)} icon={<i class="fa fa-ellipsis-v" ></i>} />
                                </div>
                                <div className="header-right">
                                    <ul className="right-ul">
                                        <li>
                                            <OverlayTrigger
                                                placement="bottom"
                                                show={showTooltip['InviteCandidates']}
                                                overlay={renderTooltip("Invite Candidates")}
                                            >
                                                <span
                                                    onMouseEnter={() => handleMouseEnter('InviteCandidates')}
                                                    onMouseLeave={() => handleMouseLeave('InviteCandidates')}
                                                >

                                                    <CustomButton
                                                        className="circle-btn ml-2"
                                                        onClick={() => handleModelActionList("BulkInviteCandidates", jobDetail.id)}
                                                        icon={adduser}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <OverlayTrigger
                                                placement="bottom"
                                                show={showTooltip['generateLink']}
                                                overlay={renderTooltip("Generate Link")}
                                            >
                                                <span
                                                    onMouseEnter={() => handleMouseEnter('generateLink')}
                                                    onMouseLeave={() => handleMouseLeave('generateLink')}

                                                >
                                                    <CustomButton
                                                        className="circle-btn ml-10"
                                                        onClick={() => handleModelActionList("GenerateRecordedLink", jobDetail.id)}
                                                        icon={attachment}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>


                                    <ul className="right-ul">
                                        <li>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={renderTooltip("Refresh")}
                                            >
                                                <span>
                                                    <CustomButton className="btn-default" onClick={refreshPage} icon={<i class="fa fa-refresh"></i>} />

                                                </span>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Row>
                        <Col md={12} className="body-wrap pad-0">
                            <CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />
                            <div className="body-left-side ">
                                <Detail jobDetail={jobDetail} update={updateList} onclick={() => JobDetailModel("Interview")} />
                            </div>
                            <div className="body-right-side">
                                <FeedTimeLine module={"jobs"} module_pk_id={jobPid} update={updateList} />
                            </div>
                        </Col>
                    </Container>
                </Offcanvas>
            }

            {showInterview && <Interview headertimeLine={headertimeLine} handleClose={() => JobDetailModelClose("Interview")} />}

        </>


    )
}

export default JobDetail;