import { createSlice } from '@reduxjs/toolkit'
import { callsSchedule, getAllPhotos, companyPreviewtoken, CallLliveInterview, scheduleMetting, addParticipant, UplodedFileToken, registerUser, requestomDemo, verifyToken, companytoken, jobtoken, preInterviewQuestionToken, getCandidateToken, updateCandidateToken, interviewtemplatetokent, interviewtemplatetokentpost, UpdateInterviewByToken } from '../actions/InterviewproceedAction';

const initialState = {
    loading: false,
    companyDetail: [],
    companyPreviewDetail: [],
    jobDetail: [],
    Photos: null,
    preInterviewQuestionDetail: [],
    interviewtemplatetDetail: [],
    verifytoken: [],
    liveInterview: [],
    UplodedFile: [],
    getCandidateDetail: null,
    meetingData: [],
    error: null,
}

const InterviewproceedSlice = createSlice({
    name: 'Interviewproceed',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(companytoken.pending, (state, action) => {
            state.loading = true

        })
            .addCase(companytoken.fulfilled, (state, action) => {
                state.loading = false
                state.companyDetail = action.payload
            })
            .addCase(companytoken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(companyPreviewtoken.pending, (state, action) => {
                state.loading = true

            }).addCase(getAllPhotos.fulfilled, (state, action) => {
                state.loading = false
                state.Photos = action.payload
            })
            .addCase(getAllPhotos.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getAllPhotos.pending, (state, action) => {
                state.loading = true

            })
            .addCase(companyPreviewtoken.fulfilled, (state, action) => {
                state.loading = false
                state.companyPreviewDetail = action.payload
            })
            .addCase(companyPreviewtoken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(jobtoken.pending, (state, action) => {
                state.loading = true

            })
            .addCase(jobtoken.fulfilled, (state, action) => {
                state.loading = false
                state.jobDetail = action.payload
            })
            .addCase(jobtoken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(preInterviewQuestionToken.pending, (state, action) => {
                state.loading = true

            })
            .addCase(preInterviewQuestionToken.fulfilled, (state, action) => {
                state.loading = false
                state.preInterviewQuestionDetail = action.payload
            })
            .addCase(preInterviewQuestionToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(getCandidateToken.pending, (state, action) => {
                state.loading = true

            })
            .addCase(getCandidateToken.fulfilled, (state, action) => {
                state.loading = false
                state.getCandidateDetail = action.payload
            })
            .addCase(getCandidateToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })

            .addCase(updateCandidateToken.pending, (state, action) => {
                state.loading = true

            })
            .addCase(updateCandidateToken.fulfilled, (state, action) => {
                state.loading = false
                // state.candidatestokenPost = action.payload
            })
            .addCase(updateCandidateToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(interviewtemplatetokent.pending, (state, action) => {
                state.loading = true

            })
            .addCase(interviewtemplatetokent.fulfilled, (state, action) => {
                state.loading = false
                state.interviewtemplatetDetail = action.payload
            })
            .addCase(interviewtemplatetokent.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(interviewtemplatetokentpost.pending, (state, action) => {
                state.loading = true

            })
            .addCase(interviewtemplatetokentpost.fulfilled, (state, action) => {
                state.loading = false
                // state.interviewtemplatetDetail=action.payload
            })
            .addCase(interviewtemplatetokentpost.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(UpdateInterviewByToken.pending, (state, action) => {
                state.loading = true

            })
            .addCase(UpdateInterviewByToken.fulfilled, (state, action) => {
                state.loading = false
                // state.interviewtemplatetDetail=action.payload
            })
            .addCase(UpdateInterviewByToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(callsSchedule.pending, (state, action) => {
                state.loading = true

            })
            .addCase(callsSchedule.fulfilled, (state, action) => {
                state.loading = false
                // state.interviewtemplatetDetail=action.payload
            })
            .addCase(callsSchedule.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(requestomDemo.pending, (state, action) => {
                state.loading = true
            })
            .addCase(requestomDemo.fulfilled, (state, action) => {
                state.loading = false
                // state.interviewtemplatetDetail=action.payload
            })
            .addCase(requestomDemo.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(verifyToken.pending, (state, action) => {
                state.loading = true
            })
            .addCase(verifyToken.fulfilled, (state, action) => {
                state.loading = false
                state.verifytoken = action.payload
            })
            .addCase(verifyToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(registerUser.pending, (state, action) => {
                state.loading = true
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false
                state.verifytoken = action.payload
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(UplodedFileToken.pending, (state, action) => {
                state.loading = true
            })
            .addCase(UplodedFileToken.fulfilled, (state, action) => {
                state.loading = false
                state.UplodedFile = action.payload
            })
            .addCase(UplodedFileToken.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })
            .addCase(addParticipant.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addParticipant.fulfilled, (state, action) => {
                state.loading = false
                // state.UplodedFile=action.payload
            })
            .addCase(addParticipant.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(CallLliveInterview.pending, (state, action) => {
                state.loading = true
            })
            .addCase(CallLliveInterview.fulfilled, (state, action) => {
                state.loading = false
                state.liveInterview = action.payload
            })
            .addCase(CallLliveInterview.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            }).addCase(scheduleMetting.pending, (state, action) => {
                state.loading = true
            })
            .addCase(scheduleMetting.fulfilled, (state, action) => {
                state.loading = false
                state.meetingData = action.payload
            })
            .addCase(scheduleMetting.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
            })


    },
})


export default InterviewproceedSlice.reducer;


