import { Offcanvas, Row, Container, Col } from "react-bootstrap";
import { Interviews, crossicon, ringcopy, videobox, mcqQuestion, textquestion, uploadfile, shareleftarrow, thingtime, retake, responsestime, word } from "../../helper/Icons/Icon";
import { CustomButton } from "../form/index";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../store/actions/recorderInterviewAction";
import * as moment from 'moment';
import Spinner from "../common/Spinner";
import RecordedInterview from "../interview/RecordedInterview";
import Liveterview from "./Liveterview";
const icons = { 'Video Question': videobox, 'Audio': ringcopy, 'Multiple Choice': mcqQuestion, 'Written': textquestion, 'File Upload': uploadfile }
const Interview = (props) => {
  const { handleClose, jobid, candidateId, headertext, icon, headertimeLine } = props;

  const dispatch = useDispatch();
  const [showRecordedInterview, setRecordedInterview] = useState(false);
  const [showLiveterview, setLiveterview] = useState(false);
  const [interviewId, setInterviewID] = useState()
  const handleheaderModel = (type, id) => {
    if (type === "RecordedInterview") {
      setInterviewID(id)
      setRecordedInterview(!showRecordedInterview);
    } else if (type === "Liveterview") {
      setLiveterview(!showLiveterview);
    }
  };

  const handleCloseModel = (type) => {
    setInterviewID()
    if (type === "RecordedInterview") {
      setRecordedInterview(false);

    } else if (type === "Liveterview") {
      setLiveterview(false);
    }
  };

  const { recorderInterview, loading, error } = useSelector((state) => state.recorderInterview);

  useEffect(() => {
    dispatch(getAll({ jobid, candidateId }));
  }, [dispatch]);

  const interviewData = recorderInterview?.data;


  return (
    <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "650px" }}>
      <Container fluid className="row-pad-0 pad-0 interview-model-wrap pad-20">
        {loading && <Spinner />}
        {interviewData && <>
          <Offcanvas.Header className="model-header bg-white actionlist-header">


            <Offcanvas.Title>{icon ? <span className="svg">{icon}</span> : ""} {recorderInterview.job_name && recorderInterview.job_name}  Interviews of {recorderInterview.candidate_name && recorderInterview.candidate_name}</Offcanvas.Title>
            <div className="text-end"><CustomButton onClick={handleClose} className="text-end close-btn" text={crossicon} /></div>
          </Offcanvas.Header>


          <Row className="row-pad-0 bg-white m-2 pad-20 border-radius-5">
            {interviewData && interviewData.map((label, index) => (
              label?.interview_type === 1 ? (
                <Col md={12} className="mt-20 border-radius-5" key={index} onClick={() => handleheaderModel("RecordedInterview", label.interview_id)}>
                  <div className="interview-box">
                    <div>
                      {Interviews}
                    </div>
                    <div className="interview-box-detail ml-10">
                      <div className="x-space-between">
                        <div><label>Interview Type:</label><span>Recorded</span></div>
                        <div className="date"><label>Submitted On:</label><span>{moment(label?.end_time).format('DD-MM-YYYY h:mm A')}</span></div>
                      </div>
                      <div className="d-flex mt-10 flex-wrap ">
                        {label.questions.map((question, qIndex) => (
                          <div className="step mb-1" key={qIndex}>
                            {icons[question?.question_type_name]} {/* Displaying icon dynamically */}
                            <span>{question.count}</span><span className="ml">{question.question_type_name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              ) : (
                <Col md={12} className="mt-20 border-radius-5" key={index} onClick={() => handleheaderModel("Liveterview")}>
                  <div className="interview-box">
                    <div>
                      {Interviews}
                    </div>
                    <div className="interview-box-detail ml-10">
                      <div className="x-space-between">
                        <div><label>Interview Type:</label><span>Live</span></div>
                        <div className="date"><label>Submitted On:</label><span>{moment(label?.end_time).format('DD-MM-YYYY h:mm A')}</span></div>

                      </div>
                      <div className="d-flex mt-10">
                        <div className="step"><label>Participants:</label><span className="ml">{label?.interview_candidate_name}</span></div>
                      </div>
                    </div>
                  </div>

                </Col>
              )
            ))}
          </Row>
        </>}
      </Container>
      {showRecordedInterview && <RecordedInterview id={interviewId} handleClose={() => handleCloseModel("RecordedInterview")} />}
      {showLiveterview && <Liveterview timeLineheader={headertimeLine} handleClose={() => handleCloseModel("Liveterview")} />}
    </Offcanvas>
  );
}

export default Interview;
