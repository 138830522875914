import { Offcanvas, Container, Col, Row } from "react-bootstrap";
import InviteLiveInterview from "../../components/common/InviteLiveInterview";
import Interview from "../../components/RecordedInterviewDetails/Interview";
import InviteAnotherJob from "./InviteAnotherJob";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CustomButton } from "../../components/form/index";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { crossicon, actionlist, adduser, attachment, time, timer } from "../../helper/Icons/Icon";
import * as moment from 'moment'

import { candidatejob, navcandidatepages } from "./fieldCandidates";
import Detail from "../../components/candidate/Detail";
import FeedTimeLine from "../../components/common/FeedTimeLine";
import { getById } from '../../store/actions/candidateActions'

import { useState } from "react";
import Spinner from "../common/Spinner";
const CandidatesDetail = ({ handleClose, candidateId }) => {
  const dispatch = useDispatch();
  const [showInviteAnotherJob, setInviteAnotherJob] = useState(false);
  const [showInterview, setInterview] = useState(false);
  const [showInviteLiveInterview, setInviteLiveInterview] = useState(false);
  const [showShareCandidate, setShareCandidate] = useState(false);
  const { candidateDetail, loading, error } = useSelector((state) => state.candidate);
  const [showTooltip, setShowTooltip] = useState({});
  useEffect(() => {
    if (candidateId) {
      dispatch(getById(candidateId));
    }

  }, [dispatch]);

  const handleHeaderModel = (props) => {
    if (props == "GenerateLink") {

    }
    if (props == "InviteLiveInterview") {
      setInviteLiveInterview(true)
    }
    if (props == "InviteAnotherJob") {
      setInviteAnotherJob(true)
    }
    if (props == "Interview") {
      setInterview(true)

    }
    if (props == "refreshPage") {
      if (candidateId) {
        dispatch(getById(candidateId));
      }

    }

  };

  const handleCloseModel = (props) => {
    if (props == "InviteAnotherJob") {
      setInviteAnotherJob(false)

    }
    if (props == "InviteLiveInterview") {
      setInviteLiveInterview(false)
    }



    if (props == "Interview") {
      setInterview(false)
    }
  }
  const renderTooltip = ({ text }) => (
    <Tooltip
      id={`tooltip-top`}
      trigger={['hover', 'focus']}
      key="bottom"
      placement="bottom"
    >
      {text}
    </Tooltip>
  );

  const handleMouseEnter = (key) => {
    setShowTooltip(prev => ({ ...prev, [key]: true }));
  };

  const handleMouseLeave = (key) => {
    setShowTooltip(prev => ({ ...prev, [key]: false }));
  };

  return (
    <>

      <Offcanvas show={true} onHide={handleClose} placement="end" className="bg-ligte-gray custom-offcanvas" style={{ width: "82%" }}>
        <Container fluid className="detailpage-wrap pad-0">
          {loading && <Spinner />}
          {candidateDetail && <>
            <Row className="row-pad-0">
              <div className="header">
                <div className="header-left">
                  <h4>Candidate Detail</h4>
                  <div className="date">
                    <p className="created">
                      <span>CREATED BY: {candidateDetail?.created_by}</span>
                      <small> {moment(candidateDetail?.created_date).format('MM-DD-YYYY h:mm A')}</small>
                    </p>
                    <p className="updated">
                      <span>UPDATED BY: {candidateDetail?.updated_by}</span>
                      <small>{moment(candidateDetail?.updated_date).format('MM-DD-YYYY h:mm A')}</small>
                    </p>
                  </div>
                  <CustomButton className="actionlist-btn" icon={<i class="fa fa-ellipsis-v" ></i>} />
                </div>


                <div className="header-right">


                  <ul className="right-ul">
                    <li>
                      <OverlayTrigger
                        show={showTooltip['InviteAnotherJob']}
                        placement="bottom"
                        overlay={renderTooltip({ text: "Invite for Another Job" })}
                      >
                        <span
                          onMouseEnter={() => handleMouseEnter('InviteAnotherJob')}
                          onMouseLeave={() => handleMouseLeave('InviteAnotherJob')}
                        >
                          <CustomButton className="circle-btn ml-2" onClick={() => handleHeaderModel("InviteAnotherJob")} icon={adduser} /></span>
                      </OverlayTrigger>
                    </li>

                    <li>
                      <OverlayTrigger
                        show={showTooltip['LiveInterview']}
                        placement="bottom"
                        overlay={renderTooltip({ text: "Invite for Live Interview" })}

                      >
                        <span
                          onMouseEnter={() => handleMouseEnter('LiveInterview')}
                          onMouseLeave={() => handleMouseLeave('LiveInterview')}
                        ><CustomButton className="circle-btn ml-10 " onClick={() => handleHeaderModel("InviteLiveInterview")} icon={time} /></span>

                      </OverlayTrigger>
                    </li>

                  </ul>
                  <ul className="right-ul">
                    <li>

                      <OverlayTrigger

                        placement="bottom"
                        overlay={renderTooltip({ text: "Refresh" })}

                      >
                        <span><CustomButton className="btn-default" onClick={() => handleHeaderModel("refreshPage")} icon={<i class="fa fa-refresh"></i>} /></span>

                      </OverlayTrigger>
                    </li>
                  </ul>



                </div>



              </div>
            </Row>
            <Col md={12} className="body-wrap pad-0">
              <CustomButton className="closeIconouter" onClick={handleClose} icon={crossicon} />
              <div className="body-left-side">
                <Detail navlink={navcandidatepages} detaildata={candidateDetail} onclick={() => handleHeaderModel("Interview")} />
              </div>
              <div className="body-right-side">
                <FeedTimeLine module={"candidates"} module_pk_id={candidateId} />
              </div>
            </Col>
          </>}
        </Container>
      </Offcanvas>


      {showInviteAnotherJob && <InviteAnotherJob candidateId={candidateId} handleClose={() => handleCloseModel("InviteAnotherJob")} />}
      {showInviteLiveInterview && <InviteLiveInterview handleClose={() => handleCloseModel("InviteLiveInterview")} text="Invite for Live Interview  " modelopnetext="scheduleInterview" />}
      {/* {showShareCandidate && <RecordedInterview  handleClose={()=>handleCloseModel("ShareCandidate")}/>} */}
      {showInterview && <Interview handleClose={() => handleCloseModel("Interview")} />}


    </>


  )
}

export default CandidatesDetail;