import { exchange, filter } from "../../helper/Icons/Icon";
import { Row, Col } from "react-bootstrap";
import DoughnutChart from "../graph/DoughnutChart";
import React, { useState } from 'react';
import moment from 'moment';

function Card({ name, dashboard, labelData, handleOpenModal, formData }) {
  const [expanded, setExpanded] = useState(false);

  const expandCard = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className={`${4} ${expanded ? 'col-md-6' : 'col-md-4'}`}>
        <div className="card-wrap bg-white">
          <Row className="card-heading row-pad-0">
            <h4 className="widget-h">
              <span>{name}</span>
              {/* <span className="expand-card" onClick={() => expandCard()}>{exchange}</span> */}
              <div className="d-flex">
                <span className="filter" onClick={handleOpenModal}> {filter}</span>
              </div>
            </h4>
          </Row>
          <div className="card-body">
            <DoughnutChart dashboard={dashboard} labelData={labelData} />
          </div>
          <div className="card-footer">
            <div className="d-flex justify-content-center align-items-center clr-filter-date mt-10 mb-10">
              <b>Yesterday</b>
              <i className="fa fa-calendar"> </i>
              <small>{moment(formData.startDate).format('YYYY/MM/DD')}-</small>
              <i className="fa fa-calendar"></i>
              <small>{moment(formData.endDate).format('YYYY/MM/DD')}</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
